import React from "react";

import SimpleHeader from "../../../layouts/headers/simple/simple-header";

const TreeItemEditPageHeader = ({element, configs}) => {
    return (
        <SimpleHeader configs={configs} text={element.name}>
        
        </SimpleHeader>
    )
}

export default TreeItemEditPageHeader