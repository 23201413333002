// system imports
import axios from "axios";
import * as shelper from './helper' 
import * as gservice from './_generic-service'

const API_URL = shelper.serverDomain() + '/statements';
    
const recoveryCreditCard = async (params, dispatch) => {
 
    try {
        const url = `${API_URL}/cc/${params.item.id}/recovery`
        const options = shelper.axiosBearerConfig(params)
        options.headers['Content-Type']= 'multipart/form-data'
       
        const formData = new FormData();
        formData.append('statement', params.payload);
      
        const xparams = formData
  
        const response = await axios.post(url, xparams, options);
  
        return gservice.getResponse(response)
 
    }
    catch(e) {
        return gservice.getError(e)
    }
    
} 

const createTransacctionsCreditCard = async (params, dispatch) => {
 
    try {
        const url = `${API_URL}/cc/${params.item.id}/insert`
        const options = shelper.axiosBearerConfig(params) 
        
        const xparams = params.payload
  
        const response = await axios.post(url, xparams, options);
  
        return gservice.getResponse(response)
 
    }
    catch(e) {
        return gservice.getError(e)
    }
    
}

const recoveryBank = async (params, dispatch) => {
 
    try {
        const url = `${API_URL}/b/${params.item.id}/recovery`
        const options = shelper.axiosBearerConfig(params)
        options.headers['Content-Type']= 'multipart/form-data'
       
        const formData = new FormData();
        formData.append('statement', params.payload);
      
        const xparams = formData
  
        const response = await axios.post(url, xparams, options);
  
        return gservice.getResponse(response)
 
    }
    catch(e) {
        return gservice.getError(e)
    }
    
} 

const createTransacctionsBank = async (params, dispatch) => {
 
    try {
        const url = `${API_URL}/b/${params.item.id}/insert`
        const options = shelper.axiosBearerConfig(params) 
        
        const xparams = params.payload
  
        const response = await axios.post(url, xparams, options);
  
        return gservice.getResponse(response)
 
    }
    catch(e) {
        return gservice.getError(e)
    }
    
} 
  
export { recoveryBank, createTransacctionsBank, recoveryCreditCard, createTransacctionsCreditCard }