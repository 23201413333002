import React from "react";
import { useSelector } from 'react-redux'

import ReportTableViewerContainer from "../../report-view/report-table-view/report-table-viewer-container";
import * as c from '../../../../../common/configurations/constants'

const ReportTableEditorViewer = ({report, user}) => {
     
    const columns = useSelector(state => state.report.columns)
    const filters = useSelector(state => state.report.filters)
    const filtersLogic = useSelector(state => state.report.filtersLogic)
    const filtersLogicExpression = useSelector(state => state.report.filtersLogicExpression)
    const sortColumn = useSelector(state => state.report.sortColumn)
    const sortColumnDirection = useSelector(state => state.report.sortColumnDirection)
   
    return (
        <ReportTableViewerContainer 
            reportid={report.id} 
            columns={columns} 
            filters={filters} 
            filtersLogic={filtersLogic} 
            filtersLogicExpression={filtersLogicExpression}
            sortColumn={sortColumn}
            sortColumnDirection={sortColumnDirection}
            user={user} 
            size={c.REPORT_TYPE_SIZE_500}/>
    )
}

export default ReportTableEditorViewer