import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
 
import * as s from '../../utilities/services/account-income-service'
import TreeItemDetailPage from '../../common/tree-layouts/pages/tree-item-detail-page'
import configs from './configs'
import * as saccount from '../../utilities/services/account-service'


const AccountIncomeDetailPage = () => {
  
    return (
        <TreeItemDetailPage service={s} configs={configs}/>
    )
}

export default AccountIncomeDetailPage