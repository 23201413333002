import { Route } from "react-router-dom";

import PassivePaymentPage from "../../transactions/transaction-liability-payment";
import PassivePaymentNewPage from '../../transactions/transaction-liability-payment/transaction-liability-payment-new-page';
import PassivePaymentDetailPage from '../../transactions/transaction-liability-payment/transaction-liability-payment-detail-page';
import PassivePaymentEditPage from '../../transactions/transaction-liability-payment/transaction-liability-payment-edit-page';
import PassivePaymentClonePage from '../../transactions/transaction-liability-payment/transaction-liability-payment-clone-page';
   
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/liability-payments">
 
            <Route index element={<PassivePaymentPage />} />

            <Route path="new" element={<PassivePaymentNewPage />} />
            <Route path="new/:accountid" element={<PassivePaymentNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<PassivePaymentDetailPage />} />
                <Route path="edit"  element={<PassivePaymentEditPage />} />
                <Route path="clone"  element={<PassivePaymentClonePage />} />
            </Route>

        </Route> 
 
export default routes
