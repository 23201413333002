import { Route } from "react-router-dom";

import ExpenseBudgetPage from "../../expense-supporters/expense-budget";
import ExpenseBudgetNewPage from '../../expense-supporters/expense-budget/expense-budget-new-page';
import ExpenseBudgetDetailPage from '../../expense-supporters/expense-budget/expense-budget-detail-page';
import ExpenseBudgetEditPage from '../../expense-supporters/expense-budget/expense-budget-edit-page';
import ExpenseBudgetClonePage from '../../expense-supporters/expense-budget/expense-budget-clone-page';
   
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/expense-budgets">
 
            <Route index element={<ExpenseBudgetPage />} />

            <Route path="new" element={<ExpenseBudgetNewPage />} />
            <Route path="new/:accountid" element={<ExpenseBudgetNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<ExpenseBudgetDetailPage />} />
                <Route path="edit"  element={<ExpenseBudgetEditPage />} />
                <Route path="clone"  element={<ExpenseBudgetClonePage />} />
            </Route>

        </Route> 
 
export default routes
