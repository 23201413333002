import React from "react"; 
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
 
import Routes from "../../../navigates/routes/_no-authenticated-routes";
import './anonymous-container.css'
import LanguageToggleComponent from '../../language';

import logo from '../../../logo.png'
import './anonymous-form-container.css'

const AnonymousFormContainer = ({children}) => {
 
    const { t, i18n } = useTranslation();

    return (
        <section className="anonymous_container_section anonymous-container">
            <nav className="navbar navbar-expand-lg custom-landing-nav">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={`/landing`}>
                        <div className="hstack gap-2 img-container">
                            <img src={logo} height="25" />
                        </div>
                    </Link> 
                  
                </div>
            </nav>


            <div className="row justify-content-center mt-5 px-3">
                <div className="col-12 anonymous_form_container_section">
                    {children}
                </div>
            </div>
        </section>
    )
}

export default AnonymousFormContainer