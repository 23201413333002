// system imports
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container';

import * as uservice from '../../utilities/services/user-service';
import validator from '../user-validator';

// css imports
import './signup-page.css';

const SignupFormPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [error, setError] = useState('');
    const [validations, setValidations] = useState({});
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');

    const handleSubmit = async (e) => {
        try {
            // validation
            if (!validator.validation({ type: validator.NEW_USER, parameters: { email, password, repassword }, setInputs: setValidations }))
                return;

            const payload = {
                username: email,
                password: password,
                time_zone: new Date().getTimezoneOffset(),
                time_zone_text: DateTime.local().zoneName,
                initial_language_text: i18n.language
            };
            const response = await uservice.add({ payload }, dispatch);

            if (response.success)
                navigate(`/signup-scc`);
            else
                setError(response.message);
        } catch (e) {
            // Handle errors
        }
    };

    const updateHandler = (e, set) => {
        // clean error
        if (error) setError();

        set(e.target.value);
    };

    return (
        <AnonymousFormContainer>
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5">
                    <h2 className="fs-5 fw-normal mb-4">{t('signupFormPage.title')}</h2>

                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">{t('signupFormPage.email')}</label>
                                    <input
                                        type="email"
                                        className={`form-control ${validations.email ? "is-invalid" : ""}`}
                                        name="email"
                                        id="email"
                                        placeholder={t('signupFormPage.emailPlaceholder')}
                                        value={email}
                                        required
                                        onChange={(e) => updateHandler(e, setEmail)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">{t('signupFormPage.password')}</label>
                                    <input
                                        type="password"
                                        className={`form-control ${validations.password ? "is-invalid" : ""}`}
                                        name="password"
                                        id="password"
                                        placeholder={t('signupFormPage.passwordPlaceholder')}
                                        value={password}
                                        required
                                        onChange={(e) => updateHandler(e, setPassword)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="repassword" className="form-label">{t('signupFormPage.repassword')}</label>
                                    <input
                                        type="password"
                                        className={`form-control ${validations.repassword ? "is-invalid" : ""}`}
                                        name="repassword"
                                        id="repassword"
                                        placeholder={t('signupFormPage.repasswordPlaceholder')}
                                        value={repassword}
                                        required
                                        onChange={(e) => updateHandler(e, setRepassword)}
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="d-grid my-3">
                                    <button
                                        className="btn btn-custom-primary btn-custom-green"
                                        type="button"
                                        onClick={handleSubmit}
                                    >
                                        {t('signupFormPage.submit')}
                                    </button>
                                </div>
                            </div>

                            <p className="m-0 text-secondary text-center">
                                {t('signupFormPage.alreadyHaveAccount')}{' '}
                                <Link to="/login">{t('signupFormPage.login')}</Link>
                            </p>
                        </div>
                    </form>

                    {error && <p className="m-0 text-danger">{error}</p>}
                </div>
            </div>
        </AnonymousFormContainer>
    );
};

export default SignupFormPage;