import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import { getFieldLayout, getField, validateInputs } from "../../common/layouts/fields/utility-field";
import InputField from "../../common/layouts/fields/input-field";
import UserContext from '../../user/user-context';
import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
  
const TransactionIncomePayableModal = ({texts, convert, setShowModal}) => {
   
    const elementTemplate = configTemplates.getObjectElement()

    const modalConfigs = {

        layout: {
 
            sections: [
                {
                    type: 'data',
                    ncols: 2,
                    elements: [
                        {
                            ...elementTemplate,
                            name: 'source_id',
                            type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                            label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PAYMENT_PASSIVE,
                            context: c.SELECTOR_CONTEXT_PASSIVE_LEAF_ACCOUNT,
                            isRequired: true
                        }, 
                        {
                            ...elementTemplate,
                            isVisible: false
                        }
                    ]
                },
                
            ]
        }
    }

    const { t } = useTranslation();
    
    const user = useContext(UserContext)
  
    const [elementCopy, setElementCopy] = useState({})
    const [invalidFields, setInvalidFields] = useState([])
   
    const changeHandler = (value, econfig) => {  
        elementCopy[econfig.name] = value
        setElementCopy({...elementCopy}) 
    }

    const saveHandler = async () => {
        const noValidInputs = validateInputs(elementCopy, modalConfigs)
        if(noValidInputs.length > 0) {
            setInvalidFields(noValidInputs)
            return
        }
  
        convert(elementCopy)
    }

    const getColumn = (e, ncols, index) => {
        return getField({ isValid: invalidFields.indexOf(e.name) ===-1, element: elementCopy, user, configs: e, ncolumns: ncols, index, changeHandler, Field: InputField, action:'create'})
    }
 
    return (
        <Modal show={true} onHide={()=> setShowModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title as="div">{texts.header}</Modal.Title>
            </Modal.Header>        
            <Modal.Body>
                <p>{texts.body}</p>
                <form className="needsValidation" noValidate>
                    { getFieldLayout(modalConfigs, getColumn) }
                </form>     
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-custom-secondary btn-sm" onClick={()=> setShowModal(false)}>{t(tc.BUTTON_CANCEL_LABEL)}</button>
                <button type="button" className="btn btn-custom-primary btn-sm" variant="primary" onClick={saveHandler} >{t(tc.BUTTON_CONVERT_LABEL)}</button>
            </Modal.Footer>
        </Modal>
    )
}
export default TransactionIncomePayableModal