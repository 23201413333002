import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
  
import * as reportService from '../../../../utilities/services/report-service'
import UserContext from '../../../../user/user-context';
 

const ReportTypeEditorItem = ({pfield, index, fieldChangeHandler}) => {

    //const { id } = useParams() 
    const [field, setField] = useState({...pfield})
      
    const onChange = (fieldname, value) => {  
 
        // Create a new object that includes the updated field value
        const updatedField = { ...field, [fieldname]: value };

        // Update the local component state
        setField(updatedField);

        // Pass the updated field up to the parent component
        fieldChangeHandler(updatedField, index);
    }

    let _date = new Date(field.modified_date)
    var options = { dateStyle: 'short', timeStyle: 'medium' };
    var _resultDate = new Intl.DateTimeFormat('en-US', options).format(_date);
  
    return (
        <tr>
            <td>
                {field.name}
            </td>
            <td>
                <input className="form-control" type="text" value={field.label? field.label: ''} onChange={(e)=>onChange('label', e.target.value)}></input>
            </td>
            <td> 
                <select className="form-select" value={field.data_type} onChange={(e)=>onChange('data_type', e.target.value)}>
                    <option value="text">text</option>
                    <option value="number">number</option>
                    <option value="boolean">boolean</option>
                    <option value="currency">currency</option>
                    <option value="date">date</option>
                    <option value="aggregated_date">aggregated_date</option>
                    <option value="datetime">datetime</option>
                    <option value="percent">percent</option>
                </select>
            </td>
            <td>  
                <input className="form-check-input" type="checkbox" checked={field.actived? field.actived: false} onChange={(e)=>onChange('actived', e.target.checked)}></input>
            </td>
            <td>
                <input className="form-check-input" type="checkbox" checked={field.sdefault? field.sdefault: false} onChange={(e)=>onChange('sdefault', e.target.checked)}></input>
            </td>
            <td>
                <input className="form-control" type="number" value={field.sdefault_order? field.sdefault_order: ''} onChange={(e)=>onChange('sdefault_order', e.target.value)}></input>
            </td>
            <td>
                <input className="form-check-input" type="checkbox" checked={field.show_total? field.show_total: false} onChange={(e)=>onChange('show_total', e.target.checked)}></input>
            </td>
            <td>
                {_resultDate}
            </td>
        </tr>
    )
}
 
export default ReportTypeEditorItem