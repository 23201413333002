import React, { useEffect, useState } from "react";

import FloatSuccessMessage from "./float-success-message";
import FloatErrorMessage from "./float-error-message";

const FloatMessage = ({message}) => {
  
    const [show, setShow] = useState(false)
 
    // type, info, links
    const alert = () => {

        switch (message.type) {
            case 'success':
                return <FloatSuccessMessage text={message.text} closeFn={()=>setShow(false)}/>

            case 'error':
                return <FloatErrorMessage text={message.text} closeFn={()=>setShow(false)}/>
        
            default:
                break;
        }
    }

    useEffect(()=> {
        
        if(!message || !message.text)
            return;

        let closetime = 10000
        if(message.type === 'success')
            closetime = 5000
          
        setShow(true)

        setTimeout( function(){
            setShow(false)
        }, closetime);

    }, [message])

    return (
        <div>
            {
                show && 
                alert()
            }
        </div>
    )
}

export default FloatMessage;