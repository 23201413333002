import React from "react";

import SimpleHeader from "../../headers/simple/simple-header";
import * as tc from "../../../configurations/text-constants";

const EditPageHeader = ({element, configs}) => {
 
    return (
        <SimpleHeader configs={configs} text={element.name}>
             
        </SimpleHeader>
    )
}

export default EditPageHeader