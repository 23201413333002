const getTreeConfigurations = () => {

    return {

        actions: {
            edit: {
                enable: false,
                handler: function() { console.log('edit')}
            },
            add: {
                enable: false,
                handler: function() { console.log('add')}
            },
            addNewChild: {
                enable: false,
                handler: function() { console.log('addNewChild')}
            },
            remove: {
                enable: false,
                handler: function() { console.log('remove')}
            },
            search: {
                enable: false,
                handler: function() { console.log('search')}
            },
            newAccountTransaction: {
                enable: false,
                handler: function() { console.log('account transaction')}
            },
            newBudget: {
                enable: false,
                handler: function() { console.log('newBudget')}
            }
        },
    
        layout: {
    
            sections: [
    
            ],
    
            boostrapIcon: 'house',
            color: '#555555'
        },
    
        object: {
            name: 'object',
            label: 'object',
            pluralLabel: 'objects',
            basePath: 'objects'
        },
    
        list: {
            items: []
        } 
    }
}

const configs = { getTreeConfigurations }
export default configs