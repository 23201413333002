import { Route } from "react-router-dom";

import TemplatePage from "../../templates/liability-payment-template";
import TemplateDetailPage from '../../templates/liability-payment-template/liability-payment-template-detail-page';
import TemplateEditPage from '../../templates/liability-payment-template/liability-payment-template-edit-page';
import TemplateClonePage from '../../templates/liability-payment-template/liability-payment-template-clone-page';
import TemplateNewPage from '../../templates/liability-payment-template/liability-payment-template-new-page';
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/liability-payment-templates">
 
            <Route index element={<TemplatePage />} />
 
            <Route path="new" element={<TemplateNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<TemplateDetailPage />} />
                <Route path="edit"  element={<TemplateEditPage />} />
                <Route path="clone"  element={<TemplateClonePage />} />
            </Route>

        </Route> 
 
export default routes
