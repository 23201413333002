import React, { Fragment, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InputField from "../../fields/input-field";
import FloatMessage from "../../../float-messages";
import { getField, getFieldLayout, validateInputs } from "../../fields/utility-field";
import AutoHeightContainer from "../../../auto-height-container/auto-height-container";
import * as tc from "../../../configurations/text-constants";

import '../page.css'

const NewPageBody = ({user, configs, message, defaultItem}) => {
     
    const [elementCopy, setElementCopy] = useState(defaultItem? {...defaultItem}: {})   
    const [invalidFields, setInvalidFields] = useState([])
    const [saveApplied, setSaveApplied] = useState(false)
    
    const navigate = useNavigate()

    const { t } = useTranslation();

    const changeHandler = (value, configElement) => { 
        elementCopy[configElement.name] = value
        setElementCopy({...elementCopy}) 
    }

    const cancelHandler = (e) => {
        navigate(-1)
    }

    const saveHandler = (e) => {

        setSaveApplied(true)

        const noValidInputs = validateInputs(elementCopy, configs)
      
        if(noValidInputs.length > 0) {
            setInvalidFields(noValidInputs)
            return
        }

        // save changes and go to the list
        configs.actions.add.handler({element: elementCopy})
    }
 
    const getColumn = (e, ncols, index) => {
        return getField({ isValid: !saveApplied || invalidFields.indexOf(e.name) ===-1, element: elementCopy, user, configs: {...e, isVisible: e.isVisible && e.isCreatable}, ncolumns: ncols, index, changeHandler, Field: InputField, action:'create'})
    }
  
    return (
        <Fragment>
            <div className="card page-edit-container"> 
                <div className="card-body">
                    <AutoHeightContainer bottomMargin={80}>
                        <form className="needsValidation" noValidate>
                            <FloatMessage message={message}/>

                            { getFieldLayout(configs, getColumn) }
                        </form>
                    </AutoHeightContainer>
                </div>
                <div className="card-footer text-center page-action-bottoms"> 
                    <button type="button" className="btn btn-custom-secondary btn-sm me-2" onClick={cancelHandler}>
                        { t(tc.BUTTON_CANCEL_LABEL) }
                    </button>
                    <button type="button" className="btn btn-custom-primary btn-sm" onClick={saveHandler}>
                        { t(tc.BUTTON_SAVE_LABEL) }
                    </button> 
                </div>
            </div>
           
        </Fragment>
        
        
    )
}

export default NewPageBody