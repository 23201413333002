import React, { useState, useRef, useEffect } from 'react';
import { Icon } from '@mdi/react';
import { mdiSend } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import './chat.css'

const Chat = ({
    messages = [],
    onSendMessage = () => {},
    isLoading = false,
    inputPlaceholder = 'chatbot.placeholder',
    className = '',
    style = {},
    maxLength = 500,
    historyLength = 4  // Número de mensajes a mantener en el historial
}) => {
    const { t } = useTranslation();
    const [inputMessage, setInputMessage] = useState('');
    const chatEndRef = useRef(null);
    const textareaRef = useRef(null);

    // Scroll to the bottom when new messages arrive
    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // Adjust the height of the textarea based on its content
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [inputMessage]);

    const handleSendMessage = () => {
        const trimmedMessage = inputMessage.trim();
        if (trimmedMessage) {
            // Obtener los últimos N mensajes como historial
            const conversationHistory = messages.slice(-historyLength);
            
            // Enviar el mensaje actual junto con el historial
            onSendMessage(trimmedMessage, conversationHistory);
            setInputMessage('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const remainingChars = maxLength - inputMessage.length;
    const isNearLimit = remainingChars <= 50;

    return (
        <div className={`chat-container ${className}`} style={{ ...style, height: '60vh' }}>
            <div className="chat-messages p-3 overflow-auto h-100">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`d-flex ${
                            message.type === 'user' ? 'justify-content-end' : 'justify-content-start'
                        } mb-3`}
                    >
                        <div
                            className={`message-bubble px-3 py-2 rounded-3 ${
                                message.type === 'user'
                                    ? 'user-message'
                                    : 'bot-message'
                            } ${message.isError ? 'error-message' : ''}`}
                            style={{
                                maxWidth: '80%',
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                            }}
                        >
                            {message.content}
                        </div>
                    </div>
                ))}
                {isLoading && (
                    <div className="d-flex justify-content-start mb-3">
                        <div className="message-bubble bot-message p-3 rounded-3">
                            <div className="typing-indicator">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                )}
                <div ref={chatEndRef} />
            </div>

            <div className="chat-input-container p-3 bg-white border-top">
                <div className="d-flex gap-2 align-items-center">
                    <textarea
                        ref={textareaRef}
                        className="form-control"
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        maxLength={maxLength}
                        placeholder={t(inputPlaceholder)}
                        style={{
                            resize: 'none',
                            minHeight: '40px',
                            maxHeight: '120px',
                        }}
                        rows="1"
                        aria-label={t('chatbot.input_aria_label')}
                    />
                    <button
                        className="btn btn-primary d-flex align-items-center justify-content-center"
                        onClick={handleSendMessage}
                        disabled={!inputMessage.trim() || isLoading}
                        style={{ minWidth: '46px' }}
                        aria-label={t('chatbot.send_aria_label')}
                    >
                        <Icon path={mdiSend} size={1} />
                    </button>
                </div>
                <div className={`text-end text-muted small ${isNearLimit ? 'text-danger' : ''}`}>
                    {t('chatbot.remaining_chars', { count: remainingChars })}
                </div>
            </div>
        </div>
    );
};

export default Chat;