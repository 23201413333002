// system imports
import * as gservice from './_generic-service'

import * as actions from '../redux/actions/ticket-slice';

const SERVICE_PATH = 'support-tickets';
  
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
 
export const { getAll, get, add, remove } = fns