import React, { useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
 
import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/user-automated-email-service' 
import * as tc from '../../common/configurations/text-constants'
import UserAutomatedVerification from "./user-automated-verification";

const UserAutomatedDetailPage = () => {
 
    const dispatch = useDispatch()
    const { id } = useParams()
    
    const [showModal, setShowModal] = useState(false)
   
    const user = useContext(UserContext)

    configs.c_actions = [
        {
            label: tc.BUTTON_USER_EMAIL_VERIFY_LABEL,
            visibilityFn: (item) => { return !item.is_verified},
            handler: () => {

                // generate code
                s.generateCode({payload: {id}, user}, dispatch)

                setShowModal(true)
            }
        }
    ]

    return (
        <DetailPageV2 service={s} configs={configs}>
            {
                showModal &&
                <UserAutomatedVerification closeModal={()=> {setShowModal(false)}} user={user}/>
            } 
        </DetailPageV2>
    )
}

export default UserAutomatedDetailPage