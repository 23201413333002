// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/listview-slice';
 
const SERVICE_PATH = 'listviews';
 
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { getAll, get, add, update, remove } = fns
 
const getDefault = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/default/${params.payload.lvobject}`,
        action: actions.getAll,
        actionResponse: actions.getAllResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
  
export { getDefault }