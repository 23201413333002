import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as cc from "../../common/configurations/color-constants"
 
import liabilityAccountConfig from "../../accounts/account-liability/configs" 
import assetAccountConfig from "../../accounts/account-asset/configs" 

const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        } 
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'date',
                        type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                        label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'amount',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                        isRequired: true
                    },
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'liability_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PASSIVE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_PASSIVE_LEAF_ACCOUNT,
                        isSelectorLinked: true, //liabilityAccountConfig.object.basePath,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'income_receiver_id',
                        label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_ACTIVE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_INCOME_RECEIVER,
                        isSelectorLinked: true, //assetAccountConfig.object.basePath,
                        isRequired: true
                    },
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [ 
                    {
                        ...elementTemplate,
                        name: 'tags',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXTAREA,
                        label: tc.OBJECT_FIELD_TRANSACTION_TAGS,
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-credit-card-2-front transaction',
        color: cc.LIABILITY_TRANSACTION
    },

    object: {
        name: 'transaction_liability',
        label: tc.OBJECT_TRANSACTION_LIABILITY_NAME_LABEL,
        pluralLabel: tc.OBJECT_TRANSACTION_LIABILITY_PLURAL_LABEL,
        basePath: 'liabilities',
        sliceName: 'liability',
        menuLabel: tc.NAV_MENU_TRANSACTION_LIABILITY_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label:  tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            }, 
            {
                name: 'date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                compactViewLeftDetail: true,
                isLinked: false
            },
            {
                name: 'amount',
                type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                compactViewRightDetail: true,
                isLinked: false
            },
            {
                name: 'liability',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_PASSIVE,
                isLinked: false
            },
            {
                name: 'income_receiver',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_TRANSACTION_LIABILITY_ACTIVE,
                isLinked: false
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    } 
}
 
export default configs