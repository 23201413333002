import React from "react";
  
import ClonePageV2 from "../../common/layouts/pages/clone-page/clone-page-v2";

import configs from './configs'

import * as s from '../../utilities/services/transaction-liability-payment-service'

const PassivePaymentClonePage = () => {

    return (
        <ClonePageV2 service={s} configs={configs}>

        </ClonePageV2>
    )
}

export default PassivePaymentClonePage