import React, { useEffect, useState } from "react"
import Select, { components } from 'react-select';
import { useDispatch } from 'react-redux'

import { getSelectorValue } from './helper'

const GenericSelector = ({service, user, value, onChange, isDisabled}) => {
      
    const [internalValue, setInternalValue] = useState()
    const [valueOptions, setValueOptions] = useState()
 
    const dispatch = useDispatch()
 
    const customStyles = { 
        control: (defaultStyles) => ({
            ...defaultStyles,
        })
      };

    // customization of Select component options
    const Option = ({ ...props }) => {                
        return <components.Option {...props}>
            <div>{props.label}</div>
        </components.Option>
    }

    const handleChange = (newValue) => {
        setInternalValue(newValue);
        onChange(newValue); // Esto pasará null cuando se borre la selección
      };
  
    useEffect(() => { 

        const load = async () => {

            const response = await service({user}, dispatch)
            if(!response.success)
                return

            const list = response.data
            const voptions = list.map((e)=> {  
                return { 
                    value: e.id, 
                    label: e.name
                }
            })

            setValueOptions(voptions) 
   
            // set value and trigger onChange
            const initialValue = getSelectorValue(value, list)
            setInternalValue(initialValue)
            if (initialValue !== null && initialValue !== undefined) {
                onChange(initialValue) // Trigger onChange only when there's an initial value
            }
        }

        load()
        
    }, [value])
  
    return <Select 
                styles={customStyles} 
                isClearable={true} 
                options={valueOptions} 
                components={{ 
                    Option
                }}
                isSearchable ={true} 
                value={internalValue} 
                onChange={handleChange} 
                maxOptions={5}
                isDisabled={isDisabled}
                placeholder="Selecciona..."
            />
}

export default GenericSelector