
import accountAssetConfigs from '../accounts/account-asset/configs'
import accountLiabilityConfigs from '../accounts/account-liability/configs'
import accountIncomeConfigs from '../accounts/account-income/configs'
import accountExpenseConfigs from '../accounts/account-expense/configs'

import incomeConfigs from '../transactions/transaction-income/configs'
import expenseConfigs from '../transactions/transaction-expense/configs'
import assetConfigs from '../transactions/transaction-asset/configs'
import liabilityConfigs from '../transactions/transaction-liability/configs'
import liabilityPaymentConfigs from '../transactions/transaction-liability-payment/configs'

import expenseTemplateConfigs from '../templates/expense-template/configs'
import incomeTemplateConfigs from '../templates/income-template/configs'
import assetTemplateConfigs from '../templates/asset-template/configs'
import liabilityPaymentTemplateConfigs from '../templates/liability-payment-template/configs'

import expenseEmailConfigs from '../automations/expense-automated-email/configs'
import incomeEmailConfigs from '../automations/income-automated-email/configs'
import assetEmailConfigs from '../automations/asset-automated-email/configs'
import liabilityPaymentEmailConfigs from '../automations/liability-payment-automated-email/configs'
import transactionEmailConfigs from '../automations/transaction-automated-email/configs'
import transactionSMSConfigs from '../automations/transaction-automated-sms/configs'

import userEmailConfigs from '../automations/user-automated-email/configs'
 
import expenseBudgetConfigs from '../expense-supporters/expense-budget/configs'

import paymentMethodBankConfigs from '../expense-supporters/payment-method/payment-method-bank/configs'
import paymentMethodCardConfigs from '../expense-supporters/payment-method/payment-method-card/configs'
import paymentMethodCashConfigs from '../expense-supporters/payment-method/payment-method-cash/configs'
import paymentMethodWalletConfigs from '../expense-supporters/payment-method/payment-method-wallet/configs'
 
import vendorConfigs from '../third-parties/vendor/configs'
import buyerConfigs from '../third-parties/buyer/configs'
 
import dashboardConfigs from '../data/dashboard/configs'
import chartConfigs from '../data/chart/configs'
import reportConfigs from '../data/report/configs'

import dashboardStandardConfigs from '../data/dashboard-standard/configs'
import chartStandardConfigs from '../data/chart-standard/configs'
import reportStandardConfigs from '../data/report-standard/configs'
import reportTypeConfigs from '../data/report-type/configs'

import ticketConfigs from '../supports/ticket/configs'
 
import profileConfigs from '../user/profile/configs'

export default {
    accountAsset: accountAssetConfigs,
    accountLiability: accountLiabilityConfigs,
    accountIncome: accountIncomeConfigs,
    accountExpense: accountExpenseConfigs,
    income: incomeConfigs,
    expense: expenseConfigs,
    asset: assetConfigs,
    liability: liabilityConfigs,
    liabilityPayment: liabilityPaymentConfigs,
    expenseTemplate: expenseTemplateConfigs,
    incomeTemplate: incomeTemplateConfigs,
    assetTemplate: assetTemplateConfigs,
    liabilityPaymentTemplate: liabilityPaymentTemplateConfigs,
    expenseEmail: expenseEmailConfigs,
    incomeEmail: incomeEmailConfigs,
    assetEmail: assetEmailConfigs,
    transactionEmail: transactionEmailConfigs,
    transactionSMS: transactionSMSConfigs,
    userEmail: userEmailConfigs,
    liabilityPaymentEmail: liabilityPaymentEmailConfigs,
    expenseBudget: expenseBudgetConfigs,
    paymentMethodBank: paymentMethodBankConfigs,
    paymentMethodCard: paymentMethodCardConfigs,
    paymentMethodCash: paymentMethodCashConfigs,
    paymentMethodWallet: paymentMethodWalletConfigs,
    vendor: vendorConfigs,
    buyer: buyerConfigs,
    dashboard: dashboardConfigs,
    chart: chartConfigs,
    report: reportConfigs,
    dashboardStandard: dashboardStandardConfigs,
    chartStandard: chartStandardConfigs,
    reportStandard: reportStandardConfigs,
    reportType: reportTypeConfigs,
    profile: profileConfigs,
    ticket: ticketConfigs,
  };


