import React, { Fragment } from "react";

const LanguageToggleComponent = ({ t, i18n }) => {
  
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Fragment>
            <div
                style={{
                    borderBottom: i18n.language === 'es' ? '2px solid #287233' : 'none',
                    paddingBottom: '2px',
                    marginRight: '5px',
                    display: 'inline-block'
                }}
            >
                <button
                    onClick={() => changeLanguage('es')}
                    className="btn btn-link"
                    title="Cambiar a Español"
                    style={{ padding: 0, border: 'none', background: 'none' }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 750 500">
                        <rect width="750" height="500" fill="#c60b1e"/>
                        <rect width="750" height="250" fill="#ffc400" y="125"/>
                    </svg>
                </button>
            </div>

            <div
                style={{
                    borderBottom: i18n.language === 'en-US' ? '2px solid #287233' : 'none',
                    paddingBottom: '2px',
                    display: 'inline-block'
                }}
            >
                <button
                    onClick={() => changeLanguage('en-US')}
                    className="btn btn-link"
                    title="Switch to English"
                    style={{ padding: 0, border: 'none', background: 'none' }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 750 500">
                        <rect width="750" height="500" fill="#bf0a30"/>
                        <rect width="750" height="50" fill="#ffffff" y="50"/>
                        <rect width="750" height="50" fill="#ffffff" y="150"/>
                        <rect width="750" height="50" fill="#ffffff" y="250"/>
                        <rect width="750" height="50" fill="#ffffff" y="350"/>
                        <rect width="750" height="50" fill="#ffffff" y="450"/>
                        <rect width="300" height="200" fill="#002868"/>
                        <g fill="#ffffff">
                            {[...Array(50)].map((_, i) => (
                                <path key={i} d="M16,13l-3,9h-10l8,6l-3,9l8-6l8,6l-3-9l8-6h-10l-3-9z" transform={`translate(${(i % 10) * 30 + 15}, ${Math.floor(i / 10) * 20 + 15}) scale(0.8)`}/>
                            ))}
                        </g>
                    </svg>
                </button>
            </div>
        </Fragment>
    );
};

export default LanguageToggleComponent;