import React, { useEffect, useState, useContext, Fragment } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
 
import UserContext from '../../user/user-context';
import AutoHeightContainer from "../../common/auto-height-container/auto-height-container";
import * as s from '../../utilities/services/chart-data-standard-service'
import ChartElement from "./chart-setup-page/chart-element";
import TitleComponent from "../common/title"

import LoadingComponent from "../../common/layouts/statuses/loading-component";
// import EmptyData from "../common/empty-data/empty-data";

const ChartShowPage = ({internalid}) => {

    const { id } = useParams()
 
    const user = useContext(UserContext)
 
    const [chartdata, setChartdata] = useState()
    const [loading, setLoading] = useState(true)
    
    const dispatch = useDispatch()
 
    useEffect(() => {

        const load = async () => {
            const response = await s.get({payload: {id: internalid? internalid: id}, user}, dispatch)
            if(response.success) setChartdata(response.data)

            setLoading(false)
        }

        load() 
        
    }, [])
  
    if(loading)
        return <LoadingComponent isText={true}/>

    if(!chartdata )
        return <div/>
 
    return ( 
        <div className="">
            
            <TitleComponent title={chartdata.name}/>

            <div style={{maxHeight: "700px"}}>
                <ChartElement typeid={chartdata.chart_type_id} options={chartdata.data.options} formats={chartdata.data.formats} data={chartdata.data.data} />
            </div>
        </div> 
    )
   
}

export default ChartShowPage