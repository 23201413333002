import React, { Fragment } from "react";
  
import Routes from "../../../navigates/routes/_authenticated-routes"; 
 
import UserContext from "../../user-context";
  
import './authenticated-container.css'

const AuthenticatedContainerBody = ({user}) => {
    
    return (
        <Fragment>
            <div className="fixed-authenticated-body">

                <div className="authenticated-container-margin-top"></div>

                <UserContext.Provider value={user}>
                    <Routes />
                </UserContext.Provider>
            </div>
        </Fragment>
    )
}

export default AuthenticatedContainerBody