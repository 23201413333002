import React from "react";
  
import NewPageV2 from "../../common/layouts/pages/new-page/new-page-v2";
import configs from './configs' 

import * as s from '../../utilities/services/automated-liability-payment-email-service'

const AutomatedNewPage = () => {
 
    return <NewPageV2 service={s} configs={configs} />
}

export default AutomatedNewPage