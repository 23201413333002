// system imports
import * as gservice from './_generic-service'

import * as actions from '../redux/actions/buyer-slice';
 
const SERVICE_PATH = 'buyers';

const getNoLimitAll = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getNoLimitAll(sparams, params, dispatch)
    
}

const getAll = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getAll(sparams, params, dispatch)
    
}

const getMore = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getMore(sparams, params, dispatch)
    
}

const get = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.get(sparams, params, dispatch)
    
}

const add = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.add(sparams, params, dispatch)
    
}
 
const update = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.update(sparams, params, dispatch)
    
}

const remove = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.remove(sparams, params, dispatch)
    
}
  

export { getNoLimitAll, get, getAll, getMore, add, update, remove }