import React from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListHeader from "../../headers/list";
import * as tc from "../../../configurations/text-constants";

const SimplePageHeader = ({configs}) => {

    const { t } = useTranslation();


    const navigate = useNavigate()

    const newHandler = () => {
        navigate(`/${configs.object.basePath}/new`)
    }

    return (
        <ListHeader configs={configs} text={t(configs.object.pluralLabel)} >
            <div className="hstack">
                {
                    configs.actions.add.enable &&
                    <button type="button" className="btn btn-custom-primary btn-sm" onClick={newHandler}>{ t(tc.BUTTON_ADD_LABEL) }</button>
                }
            </div>
        </ListHeader>
        
    )
}

export default SimplePageHeader