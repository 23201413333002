// system imports
import * as gservice from './_generic-service'

import * as actions from '../redux/actions/chart-type-slice';
 
const SERVICE_PATH = 'chart-types';
 
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { getAll, get } = fns