import { Route } from "react-router-dom";

import IncomePage from "../../transactions/transaction-income";
import IncomeNewPage from '../../transactions/transaction-income/transaction-income-new-page';
import IncomeDetailPage from '../../transactions/transaction-income/transaction-income-detail-page';
import IncomeEditPage from '../../transactions/transaction-income/transaction-income-edit-page';
import IncomeClonePage from '../../transactions/transaction-income/transaction-income-clone-page';
   
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/incomes">
 
            <Route index element={<IncomePage />} />

            <Route path="new" element={<IncomeNewPage />} />
            <Route path="new/:accountid" element={<IncomeNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<IncomeDetailPage />} />
                <Route path="edit"  element={<IncomeEditPage />} />
                <Route path="clone"  element={<IncomeClonePage />} />
            </Route>

        </Route> 
 
export default routes
