import React from "react"
import { useNavigate } from 'react-router-dom';

import OutputField from "./output-field" 
 
const FormOutputField = ({value, vConfig, user, fieldRecordMetadata}) => {
     
    const navigate = useNavigate()
    
    const onClick = () => {

        if(!fieldRecordMetadata)
            return
        
        navigate(`/${fieldRecordMetadata.configs.object.basePath}/${fieldRecordMetadata.element.id}/edit`)
    }

    return (
        <div className="border-bottom hstack">

            <OutputField value={value} vConfig={vConfig} user={user} />

            {
                vConfig.isEditable && 
                fieldRecordMetadata.configs.actions.edit.enable &&
                (
                    !fieldRecordMetadata.configs.actions.edit.visibilityFn ||
                    fieldRecordMetadata.configs.actions.edit.visibilityFn(fieldRecordMetadata.element)
                ) &&
                <a href="#" className="link-dark text-decoration-none ms-auto">
                    <i className="bi bi-pencil" onClick={onClick}></i>
                </a>
            }

        </div>
        
    )
}
 
export default FormOutputField