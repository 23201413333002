// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/expense-template-slice';
 
const SERVICE_PATH = 'expense-templates';
  
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { get, getMore, getAll, add, clone, update, remove } = fns

const addTransaction = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/${params.item.id}/newtransaction`,
        actionError: actions.error
    }

    return sservice.post(sparams, params, dispatch)
}

export { addTransaction }