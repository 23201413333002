// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/account-slice';
 
const SERVICE_PATH = 'accounts';
  
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
 
export const { update, add, remove, get } = fns

const getRealLeaves = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/reals`,
        action: actions.getReals,
        actionResponse: actions.getRealsResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getChildren = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/${params.payload.id}/children`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
 
 
export { getRealLeaves, getChildren }