import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux'

import DashboardShowPage from "../../data/dashboard/dashboard-show-page";
import HomeDefaultPage from './home-default-page'

import UserContext from '../user-context';
import * as s from '../../utilities/services/user-profile-service'

import './home-page.css';
 
const Home = ({}) => {
   
    const user = useContext(UserContext)
    const dispatch = useDispatch()

    const uprofile = useSelector(state => state.userProfile.item)

    useEffect(() => {
        s.get({ payload: {id: user.profile_id}, user }, dispatch)
    }, [])

    if(!uprofile)
        return <div/>

    return (
        <div  >
            {
                uprofile.dashboard_home_id && 
                <DashboardShowPage internalid={uprofile.dashboard_home_id} />
            }
            {
                !uprofile.dashboard_home_id &&
                <HomeDefaultPage user={user} />
            }
        </div>
    )
}

export default Home;