import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as cc from "../../common/configurations/color-constants"
import * as pickc from "../../common/configurations/picklist-constants"
 
const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,
 
        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        listviews: {
            enable: false
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'email',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_USER_AUTOMATED_EMAIL,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        name: 'is_verified',
                        type: c.OBJECT_FIELD_DATA_TYPE_BOOLEAN,
                        label: tc.OBJECT_FIELD_USER_AUTOMATED_VERIFIED,
                        isEditable: false,
                        isCreatable: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
         
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    }, 
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-envelope',
        color: cc.EXPENSE_GENERATOR
    },

    object: {
        name: 'user_automated_email',
        label: tc.OBJECT_USER_AUTOMATED_EMAIL_LABEL,
        pluralLabel: tc.OBJECT_USER_AUTOMATED_EMAIL_PLURAL_LABEL,
        basePath: 'user-automated-emails',
        sliceName: 'userAutomatedEmail',
        menuLabel: tc.NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_EXPENSE_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'email',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_USER_AUTOMATED_EMAIL,
                isLinked: false
            },
            {
                name: 'is_verified',
                type: c.OBJECT_FIELD_DATA_TYPE_BOOLEAN,
                label: tc.OBJECT_FIELD_USER_AUTOMATED_VERIFIED,
                isLinked: false
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    } 
}
 
export default configs