// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/account-liability-slice';
import * as aservice from './account-service'

const SERVICE_PATH = 'accounts';

const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
 
export const { get } = fns
 
export const { remove, update, add } = aservice

const getTree = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/tree/liabilities/${params.period}`,
        action: actions.getTree,
        actionResponse: actions.getTreeResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
  
const getLeaves = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/liabilities`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
 
const getPayableLeaves = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/liabilities/payable`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getMoveTargets = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/move-targets/liabilities`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
  
export { getTree, getLeaves, getPayableLeaves, getMoveTargets }