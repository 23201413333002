// system imports
import * as actions from '../redux/actions/transaction-automated-slice';

import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

const SERVICE_PATH = 'transaction-automated-emails';
 
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})

const refurbish = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/refurbish/${params.payload.id}`,
        action: actions.refurbish,
        actionResponse: actions.refurbishResponse,
        actionError: actions.error
    }

    return sservice.put(sparams, params, dispatch)
}

const noapplied = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/noapplied/${params.payload.id}`,
        action: actions.noapplied,
        actionResponse: actions.noappliedResponse,
        actionError: actions.error
    }

    return sservice.put(sparams, params, dispatch)
}
 
 
export const { getAll, getMore, get } = fns
export { refurbish, noapplied }