import React, { useContext } from "react";
import { useDispatch, useSelector } from 'react-redux'

import DashboardTemplate from "../../dashboard-templates/dashboard-template";
import UserContext from '../../../../user/user-context';

const DashboardEditor = () => {
    
    const item = useSelector((state) => state.dashboard.item)
 
    const user = useContext(UserContext)

    return (
        <div>
            <DashboardTemplate user={user} element={item}/>
        </div>
    )
}

export default DashboardEditor