import { Route } from "react-router-dom";

import VendorPage from "../../third-parties/vendor";
import VendorNewPage from '../../third-parties/vendor/vendor-new-page';
import VendorDetailPage from '../../third-parties/vendor/vendor-detail-page';
import VendorEditPage from '../../third-parties/vendor/vendor-edit-page';
import VendorClonePage from '../../third-parties/vendor/vendor-clone-page';
   
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/vendors">
 
            <Route index element={<VendorPage />} />

            <Route path="new" element={<VendorNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<VendorDetailPage />} />
                <Route path="edit"  element={<VendorEditPage />} />
                <Route path="clone"  element={<VendorClonePage />} />
            </Route>

        </Route> 
 
export default routes
