import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import UserContext from '../../user/user-context';
import DashboardTemplate from "./dashboard-templates/dashboard-template";
import TitleComponent from "../common/title/title";

import * as s from '../../utilities/services/dashboard-service'
 
const DashboardShowPage = ({internalid, element}) => {
  
    const user = useContext(UserContext)

    const {id} = useParams()
 
    const dispatch = useDispatch()
 
    const [loading, setLoading] = useState(true)
    const [item, setItem] = useState()

    useEffect(() => {

        const load = async () => {
            const response = await s.get({payload: {id: internalid? internalid: id}, user}, dispatch)
            if(response.success) setItem(response.data)

            setLoading(false)
        }

        if(element) {
            setItem(element)
            setLoading(false)
        } 
        else
            load()

    }, [])
 
    if(!item || loading)
        return <div/>

    return (
        <div >
            <TitleComponent title={item.name} />
            <DashboardTemplate element={item} user={user} readonly={true}/>
        </div>
        
    )  
}

export default DashboardShowPage