import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux' 

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import UserContext from '../user-context';

import * as tc from '../../common/configurations/text-constants'
import * as s from '../../utilities/services/user-profile-service' 
import './profile.css';
import ChangePassword from "./change-password";
import EnableMultiCurrency from "./enable-multicurrency";
 
const ProfileDetailPage = (props) => {
  
    const user = useContext(UserContext)
    const item = useSelector((state)=> state[configs.object.sliceName].item) 

    const [showModal, setShowModal] = useState() 
    const [showCurrencyModal, setShowCurrencyModal] = useState() 
   
    const changePasswordHandler = () => {
        setShowModal(true)
    }

    const changeCurrencyHandler = () => {
        setShowCurrencyModal(true)
    }


    configs.c_actions = [
        {
            label: tc.BUTTON_CHANGE_PASSWORD_LABEL,
            handler: changePasswordHandler
        }
    ]
  
    if(!user.is_multicurrency && item && item.currency_id && user.is_premium) {
        configs.c_actions.push(
            {
                label: tc.BUTTON_CHANGE_ENABLE_MULTICURRENCY_LABEL,
                handler: changeCurrencyHandler
            }
        )
    }
      
    return (
        <DetailPageV2 service={s} configs={configs} >
            <ChangePassword showModal={showModal} setShowModal={setShowModal} user={user}/>
            <EnableMultiCurrency showModal={showCurrencyModal} setShowModal={setShowCurrencyModal} user={user} service={s}/>
        </DetailPageV2>
    )
}

export default ProfileDetailPage;