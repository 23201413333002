import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';  // Importar ReactGA

import userRoutes from './user-no-authenticated-routes'

const NRoutes = (props) => {
 
    // const location = useLocation();  // Hook para obtener la ubicación actual
    // // Inicializar Google Analytics y rastrear cambios de rutas
    // useEffect(() => {
    //     ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS); // Reemplaza con tu ID de seguimiento
    //     ReactGA.pageview(location.pathname + location.search);  // Registrar la página actual
    // }, [location]);  // Ejecutar cada vez que la ubicación cambie

    return (
        <Routes> 
            
            { userRoutes }

            <Route path="*"   element={<Navigate to='/login' />}/>
              
        </Routes>
    )
}

export default NRoutes