import { createSlice } from '@reduxjs/toolkit'

const incomeSlice = createSlice({
  name: 'income',
  initialState: { loading: false, list: [], targetList: [], categoryList: [], categoryAccountList: [], itemIndex: -1, item: undefined, income: undefined, incomeId: undefined, message: undefined, error: undefined },
  reducers: {
     
    getAll(state, action) {
      state.loading = true
    },

    getAllResponse(state, action) {
      state.loading = false
      state.list = action.payload
    },

    getMore(state, action) {
        state.loading = true
    },
    
    getMoreResponse(state, action) {
        state.loading = false
        if(action.payload.length > 0)
            state.list = [...state.list, ...action.payload]
    },

    getTargets(state, action) {
      state.loading = true
    },

    getTargetsResponse(state, action) {
        state.loading = false
        state.targetList = action.payload.list
    },
  
    get(state, action) {
      state.loading = true
      state.itemIndex = action.payload.index
    },

    getResponse(state, action) {
      state.loading = false
      state.item = action.payload
    },

    getCategories(state, action) {
      state.loading = true
    },

    getCategoriesResponse(state, action) {
      state.loading = false
      state.categoryList = action.payload
    },

    getCategoryAccounts(state, action) {
      state.loading = true
    },

    getCategoryAccountsResponse(state, action) {
      state.loading = false
      state.categoryAccountList = action.payload
    },

    add(state, action) {
      state.loading = true
    },

    addResponse(state, action) {
      state.loading = false
      state.income = action.payload
      state.list = [action.payload, ...state.list]
    },

    convertPayable(state, action) {
        state.loading = true
    },
  
    convertPayableResponse(state, action) {
        state.loading = false
    },

    update(state, action) {
        state.loading = true
    },

    updateResponse(state, action) {
        state.loading = false
        state.item = action.payload
    },
 
    remove(state, action) {
        state.loading = true
    },

    removeResponse(state, action) {

        state.loading = false
        state.incomeId = action.payload
    },
      
    error(state, action) {
        state.loading = false
        state.error = action.payload

        console.log(action.payload)
    }
  }
})

export const { 
    getAll, getAllResponse,
    getMore, getMoreResponse,
    getTargets, getTargetsResponse,
    get, getResponse,
    getCategories, getCategoriesResponse,
    getCategoryAccounts, getCategoryAccountsResponse,
    add, addResponse,
    convertPayable, convertPayableResponse,
    remove, removeResponse,
    update, updateResponse,
    error
} = incomeSlice.actions;

export default incomeSlice.reducer