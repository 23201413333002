import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux'

import ReportTableEditorProperties from "./report-table-editor-properties";
import ReportTableEditorViewer from "./report-table-editor-viewer";

const ReportTableEditor = ({report, user}) => {
        
    return (
        <div className="row">
            <div  id="report-editor-properties">
                <ReportTableEditorProperties report={report} user={user}/>
            </div>

            <div className="col">
                <ReportTableEditorViewer report={report} user={user}/>
            </div>
        </div>
    )
}

export default ReportTableEditor