import React, { useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';
import liabilityConfigs from '../transaction-liability/configs.js'
import incomeTemplateConfigs from '../../templates/income-template/configs.js'

import * as s from '../../utilities/services/transaction-income-service'
import * as sTemplate from '../../utilities/services/template-income-service' 
import TransactionIncomePayableModal from "./transaction-income-payable-modal";

import * as tc from "../../common/configurations/text-constants";
import IncomeDeductionsComponent from "./transaction-income-deductions-component";

const IncomeDetailPage = () => {
 
    const { t } = useTranslation();
    const { id } = useParams()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useContext(UserContext)
 
    const [showPayableModal, setShowPayableModal] = useState(false)
  
    configs.c_actions = [
        {
            label: tc.BUTTON_ADD_TEMPLATE_LABEL,
            handler: async (e, element) => {
                const response = await sTemplate.add({payload: element, user: user }, dispatch)
                if(response.success)
                    navigate(`/${incomeTemplateConfigs.object.basePath}/${response.data.id}/view`)
            }
        },
        {
            
            label: tc.BUTTON_REAL_ACCOUNT_PAYABLE_LABEL,
            handler: async () => setShowPayableModal(true)
        }
    ]

    const convertHandler = async (element) => {
        
        if(!element.liability_id) {
            setShowPayableModal(false)
        }
          
        const response = await s.convertPayable({payload: { ...element }, item: {id}, user: user }, dispatch)
        if(response.success)
            navigate(`/${response.data.id}`)
    }
 
    return (
        <DetailPageV2 service={s} configs={configs} >
            {
                showPayableModal &&
                <TransactionIncomePayableModal 
                    texts={{header: t(tc.MODAL_PAYABLE_HEADER), body: t(tc.MODAL_PAYABLE_BODY_MESSAGE)}} 
                    setShowModal={setShowPayableModal}
                    convert={convertHandler}
                />
            }

            <IncomeDeductionsComponent user={user}/>
            
        </DetailPageV2>
    )
}

export default IncomeDetailPage