import React, { useState, useEffect } from "react"
import { useDispatch } from 'react-redux'

import DashboardSectionItem from "./dashboard-section-item"
import DashboardSectionItemSelector from "./dashboard-section-item-selector"

import { refreshSections } from '../../../utilities/redux/actions/dashboard-slice'

const TemplateTwo = ({user, section1, section2, readonly}) => { 
      
    const [list1, setList1] = useState([])
    const [list2, setList2] = useState([])

    const dispatch = useDispatch()

    const remove1 = (index) => { 
        const nlist = [...list1]
        nlist.splice(index, 1)
        setList1(nlist)
         
        // refresh sections
         refresh(nlist, list2) 
    }

    const remove2 = (index) => {
        const nlist = [...list2]
        nlist.splice(index, 1)
        setList2(nlist)

        // refresh sections
        refresh(list1, nlist) 
    }

    const addNew1 = (id) => { 
        const nlist = [...list1, id]

        setList1(nlist)

        // refresh sections
        refresh(nlist, list2)
    }

    const addNew2 = (id) => {
        const nlist = [...list2, id]

        setList2(nlist)

        // refresh sections
        refresh(list1, nlist)
    }

    const refresh = (newlist1, newlist2) => {
        // refresh sections
        dispatch(refreshSections([[...newlist1], [...newlist2]]))
    }

    useEffect(() => {
        setList1(section1? section1: [])
        setList2(section2? section2: [])
    }, [section1, section2])

    return (
        <div className="row">

            <div className="col-sm-12 col-md-6">
                {
                    list1 && list1.map((c, index) => {
                        return (
                            <DashboardSectionItem user={user} readonly={readonly} id={c} index={index} remove={() => remove1(index)} key={index}/>
                        )
                    })
                }

                {
                    !readonly &&
                    <DashboardSectionItemSelector user={user} add={addNew1} />
                }
                
            </div>
            <div className="col-sm-12 col-md-6">
                {
                    list2 &&  list2.map((c, index) => {
                        return (
                            <DashboardSectionItem user={user} readonly={readonly} id={c} index={index} remove={() => remove2(index)} key={index}/>
                        )
                    })
                }

                {
                    !readonly &&
                    <DashboardSectionItemSelector user={user} add={addNew2} />
                }
                
            </div>
        </div>
    )
}

export default TemplateTwo