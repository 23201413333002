import { Route } from "react-router-dom";

import ExpensePage from "../../transactions/transaction-expense";
import ExpenseNewPage from '../../transactions/transaction-expense/transaction-expense-new-page';
import ExpenseDetailPage from '../../transactions/transaction-expense/transaction-expense-detail-page';
import ExpenseEditPage from '../../transactions/transaction-expense/transaction-expense-edit-page';
import ExpenseClonePage from '../../transactions/transaction-expense/transaction-expense-clone-page';
   
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/expenses">
 
            <Route index element={<ExpensePage />} />

            <Route path="new" element={<ExpenseNewPage />} />
            <Route path="new/:accountid" element={<ExpenseNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<ExpenseDetailPage />} />
                <Route path="edit"  element={<ExpenseEditPage />} />
                <Route path="clone"  element={<ExpenseClonePage />} />
            </Route>

        </Route> 
 
export default routes
