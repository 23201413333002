import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import ReportTypeFieldSelector from "../../../../../common/selectors/report-type-field-selector";
import { refreshColumns } from '../../../../../utilities/redux/actions/report-slice';
import ColumnPreview from "./column-preview";

import * as tc from '../../../../../common/configurations/text-constants';

const ReportTableEditorPropertiesColumns = ({ report, user }) => {
       
    const [selectedColumns, setSelectedColumns] = useState([...report.columns]);
    const [selected, setSelected] = useState(undefined);
    const [addNewColumn, setAddNewColumn] = useState(false);
    
    const dispatch = useDispatch();
    const { t } = useTranslation(); // Hook para traducciones
 
    const onAddColumn = () => {
        const tcolumns = [...selectedColumns, selected];
        setSelectedColumns(tcolumns);
        updateColumns(tcolumns);
        setAddNewColumn(false);
    };

    const onRemoveColumn = (col) => {
        let tcolumns = selectedColumns.slice();
        tcolumns.splice(tcolumns.findIndex(c => c.id === col.id), 1);
        updateColumns(tcolumns);
    };

    const upColumn = (index) => {
        if (index === 0) return;

        let workColumns = [...selectedColumns];
        let targetCol = workColumns[index - 1];
        workColumns[index - 1] = workColumns[index];
        workColumns[index] = targetCol;

        updateColumns(workColumns);
    };

    const downColumn = (index) => {
        if (index === selectedColumns.length - 1) return;

        let workColumns = [...selectedColumns];
        let targetCol = workColumns[index + 1];
        workColumns[index + 1] = workColumns[index];
        workColumns[index] = targetCol;

        updateColumns(workColumns);
    };

    const updateColumns = (cols) => {
        setSelectedColumns(cols);
        setSelected(undefined);
        dispatch(refreshColumns(cols)); 
    };

    const disableButton = () => {
        return selected === undefined;
    };
    
    return (
        <div className="vstack gap-3">
              
            <ColumnPreview 
                columns={selectedColumns} 
                user={user} 
                onRemoveColumn={onRemoveColumn} 
                upColumn={upColumn} 
                downColumn={downColumn}
            />
            
            <hr />
 
            <p>
                <a href="#" 
                   className={`${addNewColumn ? "link-secondary" : "link-primary"}`} 
                   onClick={() => setAddNewColumn(true)}>
                    {t('AddNewColumn')} {/* Traducción para 'Agregar Nueva Columna' */}
                </a>
            </p>
              
            <Modal show={addNewColumn} onHide={() => setAddNewColumn(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="div">{t('AddColumn')}</Modal.Title> {/* Traducción para 'Agregar Columna' */}
                </Modal.Header>        
                <Modal.Body>
                    <ReportTypeFieldSelector 
                        typeId={report.source_id} 
                        selectedFields={selectedColumns} 
                        onChange={(v) => { setSelected({ id: v.value, label: v.label, data_type: v.type }) }} 
                        value={selected} 
                        user={user} 
                    />
                </Modal.Body>
            
                <Modal.Footer>
                    <button className="btn btn-custom-secondary btn-sm" onClick={() => setAddNewColumn(false)}>
                        {t(tc.BUTTON_CANCEL_LABEL)} {/* Usar el label traducido */}
                    </button>
                    <button type="button" className="btn btn-custom-primary btn-sm" variant="primary" onClick={onAddColumn} disabled={disableButton()}>
                        {t('AddColumn')} {/* Traducción para 'Agregar Columna' */}
                    </button>
                </Modal.Footer>
            </Modal> 
           
        </div>
    );
};

export default ReportTableEditorPropertiesColumns;