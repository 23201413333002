// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'
 
import * as actions from '../redux/actions/user-automated-slice';
 
const SERVICE_PATH = 'user-automated-emails';

const getAll = async (params, dispatch) => {
  
    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getAll(sparams, params, dispatch)
    
}

const getMore = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getMore(sparams, params, dispatch)
    
}

const get = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.get(sparams, params, dispatch)
    
}

const add = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.add(sparams, params, dispatch)
    
}

const update = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.update(sparams, params, dispatch)
    
}
   
const remove = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.remove(sparams, params, dispatch)
    
}
   
export { getAll, get, getMore, add, update, remove }


const generateCode = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/generate-code/${params.payload.id}`,
        actionError: actions.error
    }

    return sservice.patch(sparams, params, dispatch)
}

const verifyCode = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/verify-code/${params.payload.id}/${params.payload.code}`,
        actionError: actions.error
    }

    return sservice.patch(sparams, params, dispatch)
}

export { generateCode, verifyCode }