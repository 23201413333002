import { Route } from "react-router-dom";

import PaymentMethodBankPage from "../../expense-supporters/payment-method/payment-method-bank";
import PaymentMethodBankNewPage from '../../expense-supporters/payment-method/payment-method-bank/payment-method-bank-new-page';
import PaymentMethodBankDetailPage from '../../expense-supporters/payment-method/payment-method-bank/payment-method-bank-detail-page';
import PaymentMethodBankEditPage from '../../expense-supporters/payment-method/payment-method-bank/payment-method-bank-edit-page';
import PaymentMethodBankClonePage from '../../expense-supporters/payment-method/payment-method-bank/payment-method-bank-clone-page';

import PaymentMethodCardPage from "../../expense-supporters/payment-method/payment-method-card";
import PaymentMethodCardNewPage from '../../expense-supporters/payment-method/payment-method-card/payment-method-card-new-page';
import PaymentMethodCardDetailPage from '../../expense-supporters/payment-method/payment-method-card/payment-method-card-detail-page';
import PaymentMethodCardEditPage from '../../expense-supporters/payment-method/payment-method-card/payment-method-card-edit-page';
import PaymentMethodCardClonePage from '../../expense-supporters/payment-method/payment-method-card/payment-method-card-clone-page';

import PaymentMethodCashPage from "../../expense-supporters/payment-method/payment-method-cash";
import PaymentMethodCashNewPage from '../../expense-supporters/payment-method/payment-method-cash/payment-method-cash-new-page';
import PaymentMethodCashDetailPage from '../../expense-supporters/payment-method/payment-method-cash/payment-method-cash-detail-page';
import PaymentMethodCashEditPage from '../../expense-supporters/payment-method/payment-method-cash/payment-method-cash-edit-page';
import PaymentMethodCashClonePage from '../../expense-supporters/payment-method/payment-method-cash/payment-method-cash-clone-page';
  
import PaymentMethodWalletPage from "../../expense-supporters/payment-method/payment-method-wallet";
import PaymentMethodWalletNewPage from '../../expense-supporters/payment-method/payment-method-wallet/payment-method-wallet-new-page';
import PaymentMethodWalletDetailPage from '../../expense-supporters/payment-method/payment-method-wallet/payment-method-wallet-detail-page';
import PaymentMethodWalletEditPage from '../../expense-supporters/payment-method/payment-method-wallet/payment-method-wallet-edit-page';
import PaymentMethodWalletClonePage from '../../expense-supporters/payment-method/payment-method-wallet/payment-method-wallet-clone-page';

const routes =  
 
    <Route path="/pmethod">
    
        <Route path="banks">

            <Route index element={<PaymentMethodBankPage />} />

            <Route path="new" element={<PaymentMethodBankNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<PaymentMethodBankDetailPage />} />
                <Route path="edit"  element={<PaymentMethodBankEditPage />} />
                <Route path="clone"  element={<PaymentMethodBankClonePage />} />
            </Route>
        </Route>
        <Route path="cards">

            <Route index element={<PaymentMethodCardPage />} />

            <Route path="new" element={<PaymentMethodCardNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<PaymentMethodCardDetailPage />} />
                <Route path="edit"  element={<PaymentMethodCardEditPage />} />
                <Route path="clone"  element={<PaymentMethodCardClonePage />} />
            </Route>
        </Route>
        <Route path="cashes">

            <Route index element={<PaymentMethodCashPage />} />

            <Route path="new" element={<PaymentMethodCashNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<PaymentMethodCashDetailPage />} />
                <Route path="edit"  element={<PaymentMethodCashEditPage />} />
                <Route path="clone"  element={<PaymentMethodCashClonePage />} />
            </Route>
        </Route>
        <Route path="wallets">

            <Route index element={<PaymentMethodWalletPage />} />

            <Route path="new" element={<PaymentMethodWalletNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<PaymentMethodWalletDetailPage />} />
                <Route path="edit"  element={<PaymentMethodWalletEditPage />} />
                <Route path="clone"  element={<PaymentMethodWalletClonePage />} />
            </Route>
        </Route>
       

    </Route> 
  
 
export default routes
