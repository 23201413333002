import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
 
import * as s from '../../utilities/services/account-expense-service'
import * as saccount from '../../utilities/services/account-service'

import TreeItemDetailPage from '../../common/tree-layouts/pages/tree-item-detail-page'
import configs from './configs'
import budgetConfigs from '../../expense-supporters/expense-budget/configs'

import * as tc from '../../common/configurations/text-constants'

const AccountExpenseDetailPage = () => {
    
    const navigate = useNavigate()
 
    const newBudgetHandler = async (e, element) => {
        if(element)
            navigate(`/${budgetConfigs.object.basePath}/new/${element.id}`)
        // 
        // go to new budget -- 
    }

    configs.c_actions = [
        {
            label: tc.BUTTON_NEW_EXPENSE_BUDGET_LABEL,
            visibilityFn: (item) => { return item.is_leaf},
            handler: newBudgetHandler
        }
    ]
      
    return (
        <TreeItemDetailPage service={s} configs={configs}/>
    )
}

export default AccountExpenseDetailPage