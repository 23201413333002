import React, { Fragment, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';


import NewPageHeader from "./new-page-header";
import NewPageBody from "./new-page-body";
import NotAvailableComponent from "../../statuses/not-available-component";
import LoadingComponent from "../../statuses/loading-component";
 
import * as saccount from '../../../../utilities/services/account-expense-service'

import UserContext from '../../../../user/user-context';

const NewPage = ({service, defaultItem, additionalField, children, configs, message}) => {
 
    const dispatch = useDispatch()
    const navigate = useNavigate()
 
    const { t } = useTranslation();

    const user = useContext(UserContext)
      
    document.title = `${t("new-page.new")}: ${t(configs.object.label)}`

    configs.actions.add.handler = async ({element}) => {
  
        const payload = {...element, ...additionalField}
        const response = await service.add({payload: payload, user: user}, dispatch)
        if(response.success)  
           navigate(`/${configs.object.basePath}/${response.data.id}/view`)
    }
  
    return (
        <Fragment>
            <NewPageHeader user={user} configs={configs} />
            <NewPageBody user={user} body={children} configs={configs} message={message} defaultItem={defaultItem}/>
        </Fragment>
    )
}

export default NewPage