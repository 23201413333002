import { Route } from "react-router-dom";

import UserAutomatedPage from "../../automations/user-automated-email/user-automated-page";
import UserAutomatedNewPage from '../../automations/user-automated-email/user-automated-new-page';
import UserAutomatedDetailPage from '../../automations/user-automated-email/user-automated-detail-page';
import UserAutomatedEditPage from '../../automations/user-automated-email/user-automated-edit-page';

// maybe change write "setup" instead "config"
const routes =
        <Route path="/user-automated-emails">

            <Route index element={<UserAutomatedPage />} />

            <Route path="new" element={<UserAutomatedNewPage />} />

            <Route path=":id" >
                <Route path="view" element={<UserAutomatedDetailPage />} />
                <Route path="edit" element={<UserAutomatedEditPage />} />
            </Route>

        </Route> 
 
export default routes
