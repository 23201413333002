import { createSlice, current } from '@reduxjs/toolkit'

const chartSlice = createSlice({
    name: 'chart',
    initialState: { loading: false, list: [], item: undefined, itemId: undefined, type: undefined, label: undefined, value: undefined, value2: undefined, message: undefined, error: undefined },
    reducers: {
        
        get(state, action) {
            state.loading = true
        },
        
        getResponse(state, action) {
            
            state.loading = false
            state.item = action.payload
            
            state.label = action.payload.label_column_id
            state.value = action.payload.value_column_id
            state.value2 = action.payload.value2_column_id
            state.type = action.payload.chart_type_id
            
        },
        
        getAll(state, action) {
            state.loading = true
        },
        
        getAllResponse(state, action) {
            state.loading = false
            state.list = action.payload
        },
        
        getMore(state, action) {
            state.loading = true
        },
        
        getMoreResponse(state, action) {
            
            state.loading = false
            
            if(action.payload.length > 0)
                state.list = [...state.list, ...action.payload]
        },
        
        add(state, action) {
            state.loading = true
        },
        
        addResponse(state, action) {
            
            state.loading = false
            state.list = [action.payload, ...state.list]
        },
        
        update(state, action) {
            state.loading = true
        },
        
        updateResponse(state, action) {
            state.loading = false 
            state.item = action.payload
        },
        
        remove(state, action) {
            state.loading = true
        },
        
        removeResponse(state, action) {
            
            state.loading = false
            state.itemId = action.payload
            
            const index = state.list.findIndex(x=> x.id == action.payload)
            
            if(index === -1)
            return 
            
            state.list.splice(index, 1)
        },
        
        refreshProperties(state, action) {
            
            state.label = action.payload.label
            state.value = action.payload.value
            state.value2 = action.payload.value2
            state.type = action.payload.type
        },
        
        error(state, action) {
            state.loading = false
            state.error = action.payload
            
            console.log(action.payload)
        }
    }
})

export const { 
    get, getResponse,
    getAll, getAllResponse,
    getMore, getMoreResponse,
    add, addResponse,
    remove, removeResponse,
    update, updateResponse,
    refreshProperties,
    error
} = chartSlice.actions;

export default chartSlice.reducer