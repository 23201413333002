import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import TemplateEntryModal from "../_entry-modal";
import ScheduleModal from "../_schedule-modal";
 
import configs from './configs'
import UserContext from '../../user/user-context';
import configTemplates from '../../common/configurations'
import IncomeDeductionsComponent from "./income-template-deductions-component";

import incomeConfig from '../../transactions/transaction-income/configs'
import * as s from '../../utilities/services/template-income-service'
import * as scheduleService from '../../utilities/services/template-income-schedule-service'
import * as tc from "../../common/configurations/text-constants"
import * as c from "../../common/configurations/constants"

const elementTemplate = configTemplates.getObjectElement()
const modalConfigs = {

    layout: {

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        name: 'date',
                        type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                        label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                        isRequired: true
                    }
                ]
            }
        ]
    },
    object: {
        ...incomeConfig.object
    }
}
  
const TemplateDetailPage = () => {
  
    const { id } = useParams()
    const user = useContext(UserContext)

    configs.c_actions = [
        {
            label: tc.BUTTON_ADD_TEMPLATE_ENTRY_LABEL,
        },
        {
            label: tc.BUTTON_SCHEDULE_LABEL,
        }
    ]

    return (
        <DetailPageV2 service={s} configs={configs} >
            <TemplateEntryModal configs={configs} modalConfigs={modalConfigs} service={s}/>
            <ScheduleModal configs={configs} templateid={id} service={scheduleService}/>

            <IncomeDeductionsComponent user={user}/>

        </DetailPageV2>
    )
}

export default TemplateDetailPage