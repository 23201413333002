export const  OBJECT_FIELD_DATA_TYPE_TEXT = 'text'
export const  OBJECT_FIELD_DATA_TYPE_TEXTAREA = 'textarea'
export const  OBJECT_FIELD_DATA_TYPE_TEXTAREA_HTML = 'textarea_html'
export const  OBJECT_FIELD_DATA_TYPE_NUMBER = 'number'
export const  OBJECT_FIELD_DATA_TYPE_INTEGER = 'integer'
export const  OBJECT_FIELD_DATA_TYPE_CURRENCY = 'currency'
export const  OBJECT_FIELD_DATA_TYPE_DATE = 'date'
export const  OBJECT_FIELD_DATA_TYPE_AGGREGATE_DATE = 'aggregated_date'
export const  OBJECT_FIELD_DATA_TYPE_DATETIME = 'datetime'
export const  OBJECT_FIELD_DATA_TYPE_SELECTOR = 'selector'
export const  OBJECT_FIELD_DATA_TYPE_PICKLIST = 'picklist'
export const  OBJECT_FIELD_DATA_TYPE_BOOLEAN = 'boolean'
export const  OBJECT_FIELD_DATA_TYPE_PERCENT = 'percent' 

export const  SELECTOR_CONTEXT_MOVE_TARGET_INCOME = 'move-target-income'
export const  SELECTOR_CONTEXT_MOVE_TARGET_EXPENSE = 'move-target-expense'
export const  SELECTOR_CONTEXT_MOVE_TARGET_LIABILITY = 'move-target-liability'
export const  SELECTOR_CONTEXT_MOVE_TARGET_ASSET = 'move-target-asset'
export const  SELECTOR_CONTEXT_INCOME_RECEIVER = 'income-receiver'
export const  SELECTOR_CONTEXT_ACTIVE_LEAF_ACCOUNT = 'active-leaf-account'
export const  SELECTOR_CONTEXT_ASSET_RECEIVABLE_LEAF_ACCOUNT = 'asset-receivable-leaf-account'
export const  SELECTOR_CONTEXT_PASSIVE_LEAF_ACCOUNT = 'passive-leaf-account'
export const  SELECTOR_CONTEXT_LIABILITY_PAYABLE_LEAF_ACCOUNT = 'liability-payable-leaf-account'
export const  SELECTOR_CONTEXT_INCOME_LEAF_ACCOUNT = 'income-leaf-account'
export const  SELECTOR_CONTEXT_EXPENSE_LEAF_ACCOUNT = 'expense-leaf-account'
export const  SELECTOR_CONTEXT_PAYMENT_METHOD = 'payment-method'
export const  SELECTOR_CONTEXT_VENDOR = 'vendor'
export const  SELECTOR_CONTEXT_BUYER = 'buyer'
export const  SELECTOR_CONTEXT_AUTOMATED_EMAIL = 'transaction-automated-email'
export const  SELECTOR_CONTEXT_REPORT_TYPE = 'report-type'
export const  SELECTOR_CONTEXT_REPORT = 'report'
export const  SELECTOR_CONTEXT_REPORT_STANDARD = 'report-standard'
export const  SELECTOR_CONTEXT_REPORT_TYPE_VIEW = 'report-type-view'    
export const  SELECTOR_CONTEXT_CHART = 'chart'
export const  SELECTOR_CONTEXT_CHART_STANDARD = 'chart-standard'
export const  SELECTOR_CONTEXT_CHART_TYPE = 'chart-type'
export const  SELECTOR_CONTEXT_CHART_POSITION = 'chart-position'
export const  SELECTOR_CONTEXT_DASHBOARD = 'dashboard'
export const  SELECTOR_CONTEXT_DASHBOARD_STANDARD = 'dashboard-standard'
export const  SELECTOR_CONTEXT_USER_CURRENCY = 'currency'
export const  SELECTOR_CONTEXT_USER_PROFILE_CURRENCY = 'prifile-currency'
export const  SELECTOR_CONTEXT_USER_PROFILE_TIMEZONE = 'timezone'
export const  SELECTOR_CONTEXT_USER_PROFILE_LANGUAGE = 'language'
export const  SELECTOR_CONTEXT_EXPENSE_TEMPLATE = 'expense-template'
export const  SELECTOR_CONTEXT_INCOME_TEMPLATE = 'income-template'
export const  SELECTOR_CONTEXT_ASSET_TEMPLATE = 'asset-template'
export const  SELECTOR_CONTEXT_LIABILITY_PAYMENT_TEMPLATE = 'liability-payment-template' 

export const  SELECTOR_CONTEXT_REAL_LEAF_ACCOUNT = 'real-leaf-account'

// account definitions
export const  ACCOUNT_DEFINITION_BANK = 'Banks' 
export const  ACCOUNT_DEFINITION_CARD = 'Cards' 
export const  ACCOUNT_DEFINITION_CASH = 'Cashes' 
export const  ACCOUNT_DEFINITION_WALLET = 'DigWallets' 

export const FILTER_OPERATION_EQUAL = 'FILTER_OPERATION_EQUAL';
export const FILTER_OPERATION_NOT_EQUAL = 'FILTER_OPERATION_NOT_EQUAL';
export const FILTER_OPERATION_GREATER_THAN = 'FILTER_OPERATION_GREATER_THAN';
export const FILTER_OPERATION_GREATER_THAN_OR_EQUAL = 'FILTER_OPERATION_GREATER_THAN_OR_EQUAL';
export const FILTER_OPERATION_LESS_THAN = 'FILTER_OPERATION_LESS_THAN';
export const FILTER_OPERATION_LESS_THAN_OR_EQUAL = 'FILTER_OPERATION_LESS_THAN_OR_EQUAL';
export const FILTER_OPERATION_CONTAINS = 'FILTER_OPERATION_CONTAINS';
export const FILTER_OPERATION_NOT_CONTAINS = 'FILTER_OPERATION_NOT_CONTAINS';


export const  REPORT_TYPE_SIZE_AUTO = 'auto'
export const  REPORT_TYPE_SIZE_700 = '700px'
export const  REPORT_TYPE_SIZE_500 = '500px'

export const DAYS_OF_WEEK_MONDAY = 'DAYS_OF_WEEK_MONDAY';
export const DAYS_OF_WEEK_TUESDAY = 'DAYS_OF_WEEK_TUESDAY';
export const DAYS_OF_WEEK_WEDNESDAY = 'DAYS_OF_WEEK_WEDNESDAY';
export const DAYS_OF_WEEK_THURSDAY = 'DAYS_OF_WEEK_THURSDAY';
export const DAYS_OF_WEEK_FRIDAY = 'DAYS_OF_WEEK_FRIDAY';
export const DAYS_OF_WEEK_SATURDAY = 'DAYS_OF_WEEK_SATURDAY';
export const DAYS_OF_WEEK_SUNDAY = 'DAYS_OF_WEEK_SUNDAY';

export const DAYS_OF_WEEK_MONDAY_FULLNAME =  'DAYS_OF_WEEK_MONDAY_FULLNAME';
export const DAYS_OF_WEEK_TUESDAY_FULLNAME = 'DAYS_OF_WEEK_TUESDAY_FULLNAME';
export const DAYS_OF_WEEK_WEDNESDAY_FULLNAME = 'DAYS_OF_WEEK_WEDNESDAY_FULLNAME';
export const DAYS_OF_WEEK_THURSDAY_FULLNAME = 'DAYS_OF_WEEK_THURSDAY_FULLNAME';
export const DAYS_OF_WEEK_FRIDAY_FULLNAME = 'DAYS_OF_WEEK_FRIDAY_FULLNAME';
export const DAYS_OF_WEEK_SATURDAY_FULLNAME = 'DAYS_OF_WEEK_SATURDAY_FULLNAME';
export const DAYS_OF_WEEK_SUNDAY_FULLNAME = 'DAYS_OF_WEEK_SUNDAY_FULLNAME';
  
export const REPORT_FILTERS_BUTTON_CANCEL_LABEL = 'REPORT_FILTERS_BUTTON_CANCEL_LABEL';
export const REPORT_FILTERS_BUTTON_ADD_FILTER_LABEL = 'REPORT_FILTERS_BUTTON_ADD_FILTER_LABEL';
export const REPORT_FILTERS_MODAL_ADD_FILTER_TITLE = 'REPORT_FILTERS_MODAL_ADD_FILTER_TITLE';
export const REPORT_FILTERS_LABEL_COLUMN = 'REPORT_FILTERS_LABEL_COLUMN';
export const REPORT_FILTERS_LABEL_OPERATOR = 'REPORT_FILTERS_LABEL_OPERATOR';
export const REPORT_FILTERS_LABEL_VALUE = 'REPORT_FILTERS_LABEL_VALUE';
export const REPORT_FILTERS_LABEL_FILTER_LOGIC = 'REPORT_FILTERS_LABEL_FILTER_LOGIC';
export const REPORT_FILTERS_LABEL_FILTER_LOGIC_EXPRESSION = 'REPORT_FILTERS_LABEL_FILTER_LOGIC_EXPRESSION';
export const REPORT_FILTERS_OPTION_AND = 'REPORT_FILTERS_OPTION_AND';
export const REPORT_FILTERS_OPTION_OR = 'REPORT_FILTERS_OPTION_OR';
export const REPORT_FILTERS_OPTION_CUSTOMIZED = 'REPORT_FILTERS_OPTION_CUSTOMIZED';
export const REPORT_FILTERS_LINK_ADD_NEW_FILTER = 'REPORT_FILTERS_LINK_ADD_NEW_FILTER';
 
export const DOCUMENT_TERMS_CONDITIONS = 'terms_conditions';
export const DOCUMENT_APP_DOCUMENTATION= 'app_documentation';
export const DOCUMENT_WEB_DOCUMENTATION = 'web_documentation';





