import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';


import Search from '../../../search'
import * as tc from "../../../configurations/text-constants";
 
const TableListSearch = ({configs, viewId}) => {
    
    const { t } = useTranslation();


    const [viewName, setViewName] = useState('')
    const viewlist = useSelector((state)=> state['listview'].list) 
 
    useEffect(()=> {

        if(!viewlist)
            return 
        const v = viewlist.find(v => v.id === viewId) 
        if(!v)
            return

        setViewName(v.name)
        
    }, [viewlist, viewId])

    return (
        <Search handler={configs.actions.search.handler} placeholder={`${t(tc.INPUT_PLACEHOLDER_SEARCH_WITH_PLACE).replace('[PLACE]', viewName? ' ' + viewName: '')}`}/>
    )
}

export default TableListSearch

