import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'

import ChartEditorProperties from "./chart-editor-properties";
import ChartEditorViewer from "./chart-editor-viewer";

const ChartEditor = ({chart, closeHandler, saveHandler, user}) => {
    
    return (
        <div className="row">
            <div  id="chart-editor-properties">
                <ChartEditorProperties user={user} chart={chart}/>
            </div>

            <div className="col">
                <ChartEditorViewer chart={chart} user={user}/>
            </div>
        </div>
    )
}

export default ChartEditor