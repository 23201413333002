import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
 
import * as chartService from '../../../../utilities/services/chart-service'
import UserContext from '../../../../user/user-context';
import ChartEditor from './chart-editor'

const ChartEditorContainer = () => {
 
    const item = useSelector((state) => state.chart.item)
 
    const user = useContext(UserContext)
   
    return (
        <ChartEditor chart={item} user={user}/>
    )
}
 
export default ChartEditorContainer