const CHAR_EDITOR_LABEL_DATATYPE = [
    'text', 'date', 'datetime', 'aggregated_date'
]
 
const CHAR_EDITOR_VALUE_DATATYPE = [
    'number', 'currency', 'percent'
]

export {
    CHAR_EDITOR_LABEL_DATATYPE,
    CHAR_EDITOR_VALUE_DATATYPE,
}