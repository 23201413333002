// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/income-template-slice';

const SERVICE_PATH = 'income-templates';

const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { get, getAll, getMore, add, update, remove, clone } = fns
  
const addTransaction = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/${params.item.id}/newtransaction`,
        actionError: actions.error
    }

    return sservice.post(sparams, params, dispatch)
}

export { addTransaction }