import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { useTranslation } from 'react-i18next';

import PayPalComponent from "./paypal2";
import MercadoPagoComponent from "./mercadopago";
import PayUComponent from "./payu";

import UserContext from '../user-context';
import * as s from '../../utilities/services/user-tool-service';

import './payment-container.css';

const PaymentContainer = ({}) => {
    const { t } = useTranslation();
    const user = useContext(UserContext);
    const [info, setInfo] = useState();

    document.title = `${t("payment.title")}`;

    const dispatch = useDispatch();
 
    useEffect(() => { 
        const load = async () => {
            const response = await s.info({ user }, dispatch);
            if(response.success)
                setInfo(response.data);
        };

        load();
    }, []);
    
    if(!info) return <div />;

    if(info.payment.is_trial) {
        return (
            <div className="container payment-container">
                <p>{t('payment.trialMessage1')}</p>
                <p>{t('payment.trialMessage2')}</p>
            </div>
        );
    }

    if(info.payment.was_paid) {
        return (
            <div className="container payment-container">
                <p>{t('payment.paidMessage1')}</p>
                <p>{t('payment.paidMessage2')} <strong>{info.payment.next_payment}</strong></p>
                <p>{t('payment.paidMessage3')}</p>
            </div>
        );
    }
        
    return (
        <div className="container payment-container">
            <div className="row justify-content-center"> 
                {
                    info && <PayUComponent user={user} dispatch={dispatch} info={info}/>
                }
            </div>
        </div>
    );
}

export default PaymentContainer;