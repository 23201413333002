import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import TreeItemEditPageHeader from "./tree-item-edit-page-header";
import TreeItemEditPageBody from "./tree-item-edit-page-body";

import NotAvailableComponent from "../../../layouts/statuses/not-available-component";
import LoadingComponent from "../../../layouts/statuses/loading-component";

import UserContext from '../../../../user/user-context';

const TreeItemEditPage = ({service, children, configs}) => {

    const { t } = useTranslation();
    const { id } = useParams()

    const user = useContext(UserContext)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const item = useSelector((state)=> state[configs.object.sliceName].item) 
    const loading = useSelector((state)=> state[configs.object.sliceName].loading)

    document.title = `${item? item.name: t(configs.object.label)}`
    
    configs.actions.edit.handler = async ({element}) => {
        const response = await service.update({payload: element, user: user }, dispatch)

        if(response.success) 
            navigate(`/${configs.object.basePath}/${element.id}/view`)
    }
    
    useEffect(()=> {
        service.get({payload: {id}, user}, dispatch)
    }, [])

    if(loading)
        return <LoadingComponent configs={configs} isText={true}/>

    if(!item)
        return <NotAvailableComponent configs={configs} />
        
    return (
        <Fragment>
            <TreeItemEditPageHeader user={user} configs={configs} element={item}/>
            <TreeItemEditPageBody user={user} element={item} body={children} configs={configs}/>
        </Fragment>
    )
}

export default TreeItemEditPage