import React from "react";
import { useSelector } from 'react-redux'
 
import SimplePage from "../../../common/layouts/pages/simple-page";
import TableContainer  from "../../../common/layouts/lists/table/table-container";
import configs from './configs' 

import * as s from '../../../utilities/services/payment-method-service'

const PaymentMethodCardPage = () => {
     
    const getAll = (params, dispatch) => {

        const newparams = {...params, payload: {...params.payload}}
        newparams.payload.type = 'Cards'
        return s.getAllByType(newparams, dispatch)
    }

    const getMore = () => {
        // do nothing
    }
 
    return (
        <SimplePage configs={configs}>
            <TableContainer service={{getAll, getMore}} configs={configs}  />
        </SimplePage>
    )
}
export default PaymentMethodCardPage