import { Route } from "react-router-dom";

import SupportTicketPage from "../../supports/ticket/ticket-page";
import SupportTicketDetailPage from "../../supports/ticket/ticket-detail-page";
import SupportTicketNewPage from "../../supports/ticket/ticket-new-page";

// maybe change write "setup" instead "config"
const routes =  
        <Route path="/support-tickets">
 
            <Route index element={<SupportTicketPage />} />
 
            <Route path="new" element={<SupportTicketNewPage />} />

            <Route path=":id" >
                <Route path="view" element={<SupportTicketDetailPage />} />
            </Route> 

        </Route> 
 
export default routes
