import React from "react"; 
 
import Routes from "../../../navigates/routes/_no-authenticated-routes";
import './anonymous-container.css'
 
const AnonymousContainer = (props) => {
 
    return (
        <section className="anonymous_container_section anonymous-container">
            <Routes />
        </section>
    )
}

export default AnonymousContainer