import React from "react";
 
import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'

import * as s from '../../utilities/services/automated-income-email-service' 

const AutomatedDetailPage = () => {

    return (
        <DetailPageV2 service={s} configs={configs}/>
    )
}

export default AutomatedDetailPage