// system imports
import * as gservice from './_generic-service'

import * as actions from '../redux/actions/template-schedule-slice';
 
const SERVICE_PATH = 'income-template-schedules';
 
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { get, add, remove } = fns
 