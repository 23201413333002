import { Route } from "react-router-dom";
 
import ExpenseAutomatedPage from "../../automations/expense-automated-email/expense-automated-page";
import ExpenseAutomatedNewPage from '../../automations/expense-automated-email/expense-automated-new-page';
import ExpenseAutomatedDetailPage from '../../automations/expense-automated-email/expense-automated-detail-page';
import ExpenseAutomatedEditPage from '../../automations/expense-automated-email/expense-automated-edit-page';
import ExpenseAutomatedClonePage from '../../automations/expense-automated-email/expense-automated-clone-page';

const routes =  
    <Route path="/expense-automated-emails">
    
        <Route index element={<ExpenseAutomatedPage />} />

        <Route path="new" element={<ExpenseAutomatedNewPage />} />

        <Route path=":id" >
            <Route path="view" element={<ExpenseAutomatedDetailPage />} />
            <Route path="edit" element={<ExpenseAutomatedEditPage />} />
            <Route path="clone" element={<ExpenseAutomatedClonePage />} />
        </Route>

    </Route> 

export default routes
