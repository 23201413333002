import { createSlice } from '@reduxjs/toolkit'

const reportSlice = createSlice({
    name: 'report',
    initialState: { loading: false, list: [], item: undefined, itemId: undefined, sortColumn: undefined, sortColumnDirection: undefined, columns:[], filters: [], filtersLogic: undefined, filtersLogicExpression: undefined, message: undefined, error: undefined },
    reducers: {
        
        get(state, action) {
            state.loading = true
        },
        
        getResponse(state, action) {
            
            state.loading = false
            state.item = action.payload
            
            state.columns = action.payload.columns
            state.filters = action.payload.filters
            state.filtersLogic = action.payload.filters_logic
            state.sortColumn = action.payload.sort_column_id
            state.sortColumnDirection = action.payload.sort_column_direction
        },
        
        getAll(state, action) {
            state.loading = true
        },
        
        getAllResponse(state, action) {
            state.loading = false
            state.list = action.payload
            
        },
        getMore(state, action) {
            state.loading = true
        },
        
        getMoreResponse(state, action) {
            
            state.loading = false
            
            if(action.payload.length > 0)
                state.list = [...state.list, ...action.payload]
        },
        
        add(state, action) {
            state.loading = true
        },
        
        addResponse(state, action) {
            state.loading = false
            state.list = [action.payload, ...state.list]
        },
        
        update(state, action) {
            state.loading = true
        },
        
        updateResponse(state, action) {
            
            state.loading = false
            state.item = action.payload
        },
        
        remove(state, action) {
            state.loading = true
        },
        
        removeResponse(state, action) {
            
            state.loading = false
            state.itemId = action.payload
            
            const index = state.list.findIndex(x=> x.id == action.payload)
            
            if(index === -1)
            return 
            
            state.list.splice(index, 1)
        },
        
        refresh(state, action) {
            state.columns = action.payload.columns
            state.filters = action.payload.filters
            state.filtersLogic = action.payload.filtersLogic
        },
        
        refreshColumns(state, action) {
            state.columns = action.payload
        },
        
        refreshSortColumn(state, action) {
            state.sortColumn = action.payload
        },
        
        refreshSortColumnDirection(state, action) {
            state.sortColumnDirection = action.payload
        },
        
        refreshFilters(state, action) {
            state.filters = action.payload
        },
        
        refreshFiltersLogic(state, action) {
            state.filtersLogic = action.payload
        },
        
        refreshFiltersLogicExpression(state, action) {
            state.filtersLogicExpression = action.payload
        },
        
        error(state, action) {
            state.loading = false
            state.error = action.payload
            
            console.log(action.payload)
        }
    }
})

export const { 
    get, getResponse,
    getAll, getAllResponse,
    getMore, getMoreResponse,
    add, addResponse,
    remove, removeResponse,
    update, updateResponse,
    refresh, refreshColumns, refreshFilters, refreshFiltersLogic, refreshFiltersLogicExpression,
    refreshSortColumn, refreshSortColumnDirection,
    error
} = reportSlice.actions;

export default reportSlice.reducer