import React, { useState } from "react";
import { useDispatch } from 'react-redux'

import ReportTypeFieldSelector from "../../../../../common/selectors/report-type-field-selector";
import { refreshSortColumn, refreshSortColumnDirection } from '../../../../../utilities/redux/actions/report-slice';

const ReportTableEditorPropertiesOrder = ({ report, user }) => {
     
    const [column, setColumn] = useState(report.sort_column_id)
    const [direction, setDirection] = useState(report.sort_column_direction? report.sort_column_direction: '1')
      
    const dispatch = useDispatch()
 
    const selectColumn = (column) => {
 
        setColumn(column.id)
 
        dispatch(refreshSortColumn(column.id))
    }

    const selectColumnDirection = (e) => {

        const direction = e.target.value

        setDirection(direction)

        if(column)
            dispatch(refreshSortColumnDirection(direction)) 
    }
    
    return (
        <div className="vstack gap-3">
              
            <ReportTypeFieldSelector typeId={report.source_id} selectedFields={[]} onChange={(v)=> { selectColumn({id: v.value, label: v.label})}} value={column} user={user} />

            <select className="form-control" value={direction} onChange={selectColumnDirection}>
                <option value="1">ASC</option>
                <option value="2">DESC</option>
            </select>
        </div>
    )
}

export default ReportTableEditorPropertiesOrder