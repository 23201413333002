import React from "react";

import './report-table-viewer-size'
import ReportTableViewerBody from "../report-table-viewer-body";

const ReportTableViewerSize = ({data, size, user}) => { 
     
    if(!data || !data.rows)
        return <div className="container-fluid"/>

        // 0px 40px 0px 25px 
        /**
         *  <AutoHeightContainer >
                <Body data={data} />
            </AutoHeightContainer>
         */
    return (
        <div className="" style={{"overflowX": "auto", "maxHeight": size}}> 
            <ReportTableViewerBody data={data} />
        </div>
    )
}

export default ReportTableViewerSize