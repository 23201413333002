import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importa tus archivos de traducción
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';

// Configura los recursos
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n
    .use(LanguageDetector) // Usa el detector de idioma
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;