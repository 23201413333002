import { Route } from "react-router-dom";

import DashboardPage from "../../data/dashboard-standard/dashboard-page";
import DashboardNewPage from '../../data/dashboard-standard/dashboard-new-page';
import DashboardDetailPage from '../../data/dashboard-standard/dashboard-detail-page';
import DashboardEditPage from '../../data/dashboard-standard/dashboard-edit-page';
import DashboardClonePage from '../../data/dashboard-standard/dashboard-clone-page';
import DashboardSetupPage from '../../data/dashboard-standard/dashboard-setup-page';
import DashboardShowPage from '../../data/dashboard-standard/dashboard-show-page';

// maybe change write "setup" instead "config"
const routes =  
    <Route path="standard-dashboards">

        <Route index element={<DashboardPage />} />

        <Route path="new" element={<DashboardNewPage />} />

        <Route path=":id" >
            <Route path="view"  element={<DashboardDetailPage />} />
            <Route path="edit"  element={<DashboardEditPage />} />
            <Route path="clone"  element={<DashboardClonePage />} />
            <Route path="setup"  element={<DashboardSetupPage />} />
            <Route path="print" element={<DashboardShowPage />} />
        </Route>
    </Route>
 
export default routes
