import { Route } from "react-router-dom";

import ReportPage from "../../data/report/report-page";
import ReportNewPage from '../../data/report/report-new-page';
import ReportDetailPage from '../../data/report/report-detail-page';
import ReportEditPage from '../../data/report/report-edit-page';
import ReportClonePage from '../../data/report/report-clone-page';
import ReportSetupPage from '../../data/report/report-setup-page';
import ReportShowPage from '../../data/report/report-show-page';


 
const routes =  
    <Route path="/reports">
    
        <Route index element={<ReportPage />} />

        <Route path="new" element={<ReportNewPage />} />

        <Route path=":id" >
            <Route path="view"  element={<ReportDetailPage />} />
            <Route path="edit"  element={<ReportEditPage />} />
            <Route path="clone"  element={<ReportClonePage />} />
            <Route path="setup"  element={<ReportSetupPage />} />
            <Route path="print"  element={<ReportShowPage />} />
        </Route>

    </Route> 
 
export default routes