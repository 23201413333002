import React  from "react"

import BasicListSelector from "../../../../common/selectors/basic-list-selector"; 

const ChartEditorColumnSelector = ({columns, onChange, leaf}) => {
     
    const convertColumnsToSelect = (list) => {

        if(!list)
            return []
        
        let clist = []
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            
            clist.push({id: element.id, name: element.label})
        }
 
        return clist
    }
 
    return (
        <BasicListSelector list={convertColumnsToSelect(columns)} onChange={(v) => onChange(columns.find(c => c.id === v?.value))} leaf={leaf}/>
    )
}
 
export default ChartEditorColumnSelector