// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'
  
import * as actions from '../redux/actions/user-profile-slice';
 
const SERVICE_PATH = 'user-profiles';

const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
 
export const { get, update } = fns
 
const getTimezones = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/timezones`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getCurrencies = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/currencies`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getLanguages = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/list/languages`,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
 
export { getTimezones, getCurrencies, getLanguages }