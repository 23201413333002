import { createSlice } from '@reduxjs/toolkit'

const scheduleSlice = createSlice({
  name: 'template-schedule',
  initialState: { loading: false, list: [], item: undefined, message: undefined, error: undefined },
  reducers: {
       
    get(state, action) {
      state.loading = true
      // state.itemIndex = action.payload.index 
    },

    getResponse(state, action) {
      state.loading = false
      state.item = action.payload
    },

    add(state, action) {
      state.loading = true
    },
  
    addResponse(state, action) {
      state.loading = false
      // state.expense = action.payload
      // state.list = [action.payload, ...state.list]
    },
  
    remove(state, action) {
        state.loading = true
    },

    removeResponse(state, action) {

        state.loading = false
        // state.expenseId = action.payload
    },
      
    error(state, action) {
        state.loading = false
        state.error = action.payload

        console.log(action.payload)
    }
  }
})

export const { 
    get, getResponse,
    add, addResponse,
    remove, removeResponse,
    error
} = scheduleSlice.actions;

export default scheduleSlice.reducer