import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux' 
import { useTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";

import FixedTableList from "../layouts/lists/fixed-table";
import * as c from "../configurations/constants"
import configs from "../configurations/configurations";

import * as tc from "../configurations/text-constants"

import * as s from '../../utilities/services/transaction-service'

import OutputField from "../layouts/fields/output-field";

const getCustomConfigs = () => {

    const configurations = configs.getConfigurations()

    const customConfigs = {

        ...configurations,

        actions: {
            ...configurations.actions,

            search: {
                enable: false
            }
        },
        list: {
            items: [
                {
                    name: 'name',
                    type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                    label: tc.OBJECT_FIELD_GENERIC_NAME,
                    isLinked: true,
                },
                {
                    name: 'account',
                    type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                    label: tc.OBJECT_RELATED_LIST_ACCOUNT_NAME,
                    isLinked: false,
                },
                {
                    name: 'amount',
                    type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                    label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                    isLinked: false,
                    compactViewRightDetail: true,

                }
            ]
        } 
    }

    return customConfigs
}
const CalendarRelatedList = ({econfig, account, user}) => {

    const { t } = useTranslation();

    const [value, setValue] = useState() 
    const [showModal, setShowModal] = useState(false) 
    const [transactionList, setTransactionList] = useState([]) 
 
    const headers = () => {
        return (
            <thead className="text-uppercase text-center ">
                <tr>
                    <th>{t(c.DAYS_OF_WEEK_SUNDAY)}</th>
                    <th>{t(c.DAYS_OF_WEEK_MONDAY)}</th>
                    <th>{t(c.DAYS_OF_WEEK_TUESDAY)}</th>
                    <th>{t(c.DAYS_OF_WEEK_WEDNESDAY)}</th>
                    <th>{t(c.DAYS_OF_WEEK_THURSDAY)}</th>
                    <th>{t(c.DAYS_OF_WEEK_FRIDAY)}</th>
                    <th>{t(c.DAYS_OF_WEEK_SATURDAY)}</th>
                </tr>
            </thead>
        )
    }

    const rows = () => {

        const now = new Date()
        const firstDay = new Date(now.getFullYear(), now.getMonth() - 1, 21);
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 7);
        
        let loop = new Date(firstDay);
        // find a sunday
        while (loop.getDay() !== 0) { 
            let newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate); 
        }

        let days = []
        let wdays = []
        let inCurrentMonth = false
  
        while (loop < lastDay) {
 
            // loop.getDay() === 0 -- sunday
            if(loop.getDay() === 0 && wdays.length > 0) { // first week of the curren month

                if(inCurrentMonth) { 
                    days.push(wdays)
                }
                
                wdays = [] 
            }
             
            wdays.push(new Date(loop)) 

            let newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate); 

            if(!inCurrentMonth)
                inCurrentMonth = loop.getMonth() === now.getMonth()
        }
   
        return (
            <tbody>
                { 
                    days.map((w)=> {

                        return (
                            <tr key={`${w}`}>
                                {
                                    w.map((d, i)=> {
                                        return (
                                            <td key={i} style={{height:"60px", minWidth:"110px"}} className={`text-end ${d.getMonth() !== now.getMonth()? 'opacity-25': ''}`}>
                                                {d.getMonth() === now.getMonth() && list[d.getDate()] && list[d.getDate()].length> 0? calendarcellWithTrans(d): calendarcell(d)}
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        )
        
    }
    
    const calendarcell = (d) => {  
        return (
            <div>{d.getDate()}</div>
        )
    }

    const calendarcellWithTrans = (d) => {
 
        
        const total = list[d.getDate()].reduce((a, b)=> {
            return a + b.amount
        }, 0)
 
        return (
            <Fragment>
                <div className="badge bg-secondary" style={{cursor: "pointer"}} onClick={() => {setTransactionList(list[d.getDate()]); setShowModal(true)}}>
                    {  
                        d.getDate() 
                    }                 
                </div>
                <div>
                    <b>{ OutputField({value: total, vConfig: {type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY}, user})}</b>
                </div>
            </Fragment>
            
        )
    }

    const [list, setList] = useState([]) 

    const dispatch = useDispatch()

    useEffect(() => {

        // look for transations
        const load = async () => {
            const response = await s.getAccountMonth({payload: account, user}, dispatch)

            if(!response.success) {
                setList([])
                return
            }
 
            const transactionByDays = []

            for (const i of response.data) {
                 
                const day = new Date(i.date).getUTCDate()
                if(transactionByDays[day])
                    transactionByDays[day].push(i)
                else 
                    transactionByDays[day] = [i]
            }

            setList(transactionByDays)
        }

        load()

    }, [account])
 
    return (
        <div  style={{overflow: 'auto'}}>
            <table className="table ">
                { headers() }
                { rows() }
 
                <Modal show={showModal} onHide={()=> setShowModal(false)} size="lg"> 
                    <Modal.Body>
                        <FixedTableList items={transactionList} configs={getCustomConfigs()} />   
                    </Modal.Body>
                
                    <Modal.Footer>
                        <button className="btn btn-custom-secondary btn-sm" onClick={()=> setShowModal(false)}>{t(tc.BUTTON_CLOSE_LABEL)}</button>
                    </Modal.Footer>
                </Modal> 
            </table>
        </div>
       
    )
}

export default CalendarRelatedList