import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import * as uservice from '../../utilities/services/user-service'
import * as tc from '../../common/configurations/text-constants'

import { ERROR_CODE_00000, ERROR_CODE_00001 } from "../../common/configurations/text-constants";

const ChangePassword = ({showModal, setShowModal, user}) => {
  
    const error = useSelector((state) => state.user.error)

    const [internalError, setInternalError] = useState('')
    const [oldpassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
 
    const { t } = useTranslation();

    const dispatch = useDispatch()
     
    const updateHandler = async e => { 

        try {

            // validate both are equal
            if(!password || password != repassword) {
                setInternalError(ERROR_CODE_00001)
                return
            }

            const response = await uservice.changePasswordInternal({ payload: {oldpassword, password}, user }, dispatch)
            if(response.success) {
                // go to login page
                setShowModal(false)
            }
        }
        catch(e) {
            // if you get some errors
            setInternalError(ERROR_CODE_00000)
        }

    }
  
    return (
        <Modal show={showModal} onHide={()=> setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title as="div">{t(tc.BUTTON_CHANGE_PASSWORD_LABEL)}</Modal.Title>
            </Modal.Header>        
            <Modal.Body>
                <section className='container'>
                    <div className="row justify-content-center">
                        <form action="#!">
                            <div className="row gy-2 overflow-hidden">
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">{t('change-password.current-pw')}</label>
                                        <input type="password" className="form-control" value={oldpassword} required onChange={(e)=>{setOldPassword(e.target.value)}}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">{t('change-password.new-pw')}</label>
                                        <input type="password" className="form-control" value={password} required onChange={(e)=>{setPassword(e.target.value)}}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">{t('change-password.re-new-pw')}</label>
                                        <input type="password" className="form-control" value={repassword} required onChange={(e)=>{setRepassword(e.target.value)}}/>
                                    </div>
                                </div>
                                    
                            </div>
                        </form>
                        {
                            error &&
                            <p className="m-0 text-danger">{error}</p>
                        }
                        {
                            internalError &&
                            <p className="m-0 text-danger">{internalError}</p>
                        }   
                    </div>
                </section>  
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-custom-secondary btn-sm" onClick={()=> setShowModal(false)}>{t(tc.BUTTON_CANCEL_LABEL)}</button>
                <button type="button" className="btn btn-custom-primary btn-sm" variant="primary" onClick={updateHandler} >{t(tc.BUTTON_SAVE_LABEL)}</button>
            </Modal.Footer>
        </Modal> 

        
    )
}

export default ChangePassword