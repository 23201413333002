import { createSlice } from '@reduxjs/toolkit'

const chartTypeSlice = createSlice({
    name: 'chartType',
    initialState: { loading: false, list: [], item: undefined, itemId: undefined, message: undefined, error: undefined },
    reducers: {
        
        get(state, action) {
            state.loading = true
        },
        
        getResponse(state, action) {
            
            state.loading = false
            state.item = action.payload
        },
        
        getAll(state, action) {
            state.loading = true
        },
        
        getAllResponse(state, action) {
            state.loading = false
            state.list = action.payload
            
        },
        
        getMore(state, action) {
            state.loading = true
        },
        
        getMoreResponse(state, action) {
            state.loading = false
            if(action.payload.length > 0)
                state.list = [...state.list, ...action.payload]
        },
        
        error(state, action) {
            state.loading = false
            state.error = action.payload
            
            console.log(action.payload)
        }
    }
})

export const { 
    get, getResponse,
    getAll, getAllResponse,
    getMore, getMoreResponse,
    error
} = chartTypeSlice.actions;

export default chartTypeSlice.reducer