import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next'; // Import i18n hook

import './list-header.css'
import MaterialIcon, { typePath } from "../../../icons/material-icon";
 
const ListHeader = ({configs, text, children}) => {
    
    const { t } = useTranslation();
 
    const [fixed, setFixed] = useState(false);
      
    const scrollChanged = () => {
        if((window.scrollY < 50) && fixed) {
            setFixed(false)
        }
        else if((window.scrollY >= 50) && !fixed) {
            setFixed(true)
        } 
        
    }

    useEffect(()=> {
        window.addEventListener("scroll", scrollChanged)
    })
  
    const typeInfo = typePath(configs.object.name)
     
    return (  
        <div className={`shadow mb-2 list-header bg-body rounded ${fixed?"fixed-list-header": "before-fixed-list-header"}`}> 
            {/* card for normal viewport */}
            <div className="card" style={{borderBottom: `5px solid ${typeInfo.tcolor}`, borderRadius: '5px'}}>
                <div className="card-body hstack gap-2">
                    <div className="hstack gap-2" >
                        <MaterialIcon obj={configs.object.name} isheader={true}/> 
                        <div className="fw-bold text-uppercase" >{t(configs.object.pluralLabel)}</div> 
                    </div>

                    <div className=" ms-auto">
                        {children}
                    </div>
                </div> 
            </div> 
        </div>
    )
}

export default ListHeader