import { createSlice } from '@reduxjs/toolkit'
 
const accountSlice = createSlice({
  name: 'account',
  initialState: { loading: false, reals: [], message: undefined, error: undefined },
  reducers: {
     
     
    getReals(state, action) {
      state.loading = true
    },
    getRealsResponse(state, action) {
      state.loading = false
      state.reals = action.payload
    },
    add(state, action) {
      state.loading = true
    },

    addResponse(state, action) {
  
      state.loading = false
      state.accounts = action.payload
    },

    update(state, action) {
        state.loading = true
    },

    updateResponse(state, action) {

        state.loading = false
    },

    move(state, action) {
      state.loading = true
    },

    moveResponse(state, action) {

        state.loading = false
    },

    remove(state, action) {
        state.loading = true
    },

    removeResponse(state, action) {

        state.loading = false
    },
      
    error(state, action) {
        state.loading = false
        state.error = action.payload

        console.log(action.payload)
    }
  }
})

export const { 
    getReals, getRealsResponse,
    add, addResponse,
    remove, removeResponse,
    update, updateResponse,
    move, moveResponse,
    error
} = accountSlice.actions;

export default accountSlice.reducer