import { Route } from "react-router-dom";

import ReceiptFilePage from "../../transactions/transaction-receipt-file";

// maybe change write "setup" instead "config"
const routes =  
        <Route path="/transaction-receipt-files/:id/view"  element={<ReceiptFilePage />}>
       </Route> 
 
export default routes
