import { createSlice } from '@reduxjs/toolkit'

const expenseSlice = createSlice({
    name: 'expense',
    initialState: { loading: false, list: [], searchText: '', itemIndex: -1, item: undefined, expense: undefined, expenseId: undefined, message: undefined, error: undefined },
    reducers: {
        
        getAll(state, action) {
            state.loading = true
            state.searchText = action.payload.text
        },
        
        getAllResponse(state, action) {
            state.loading = false
            state.list = action.payload
        },
        
        getMore(state, action) {
            state.loading = true
        },
        
        getMoreResponse(state, action) {
            
            state.loading = false
 
            if(action.payload.length > 0)
                state.list = [...state.list, ...action.payload]
        },
        
        get(state, action) {
            state.loading = true
            state.itemIndex = action.payload.index 
        },
        
        getResponse(state, action) {
            state.loading = false
            state.item = action.payload
        },
        
        add(state, action) {
            state.loading = true
        },
        
        addResponse(state, action) {
            state.loading = false
            state.expense = action.payload
            state.list = [action.payload, ...state.list]
        },
        
        convertReceivable(state, action) {
            state.loading = true
        },
        
        convertReceivableResponse(state, action) {
            state.loading = false
        },

        convertLiability(state, action) {
            state.loading = true
        },
        
        convertLiabilityResponse(state, action) {
            state.loading = false
        },
        
        update(state, action) {
            state.loading = true
        },
        
        updateResponse(state, action) {
            state.loading = false
        },
        
        remove(state, action) {
            state.loading = true
        },
        
        removeResponse(state, action) {
            
            state.loading = false
            state.expenseId = action.payload
        },
        
        error(state, action) {
            state.loading = false
            state.error = action.payload
            
            console.log(action.payload)
        }
    }
})

export const { 
    getAll, getAllResponse,
    getMore, getMoreResponse,
    get, getResponse,
    add, addResponse,
    convertReceivable, convertReceivableResponse,
    convertLiability, convertLiabilityResponse,
    remove, removeResponse,
    update, updateResponse,
    error
} = expenseSlice.actions;

export default expenseSlice.reducer