import React, { useEffect, useContext, useState, useRef } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux' 

import UserContext from '../../user/user-context';
import * as s from '../../utilities/services/transaction-receipt-service'

import configs from './configs'

const ReceiptFilePage = () => {

    const { id } = useParams()

    const user = useContext(UserContext)
    const [receipt, setReceipt] = useState()
 
    const dispatch = useDispatch()
    
    useEffect(() => {
        
        const load = async () => {
            const response = await s.get({payload: { id }, user}, dispatch)
            if(response.success)
                setReceipt(response.data)
        }

        load() 
    }, [])

    if(!receipt)
        return <div />
        
    return (
        <div>
            <h4 className="fw-bold fs-6 badge bg-secondary">{receipt.name}</h4>
            <object data={`${process.env.REACT_APP_SERVER_URL}/${configs.object.basePath}/${receipt.id}`} type={receipt.content_type} height={receipt.content_type.indexOf('pdf') != -1? "800px": "100%"} width="100%">
            </object>
        </div>
        
    )
}

export default ReceiptFilePage