import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import TemplateEntryModal from "../_entry-modal";
import ScheduleModal from "../_schedule-modal";
 
import configs from './configs'
import UserContext from '../../user/user-context';
import configTemplates from '../../common/configurations'
import expenseConfig from '../../transactions/transaction-expense/configs'
import * as s from '../../utilities/services/template-expense-service'
import * as scheduleService from '../../utilities/services/template-expense-schedule-service'
import * as tc from "../../common/configurations/text-constants"
import * as c from "../../common/configurations/constants"
 
const elementTemplate = configTemplates.getObjectElement()
const modalConfigs = {

    layout: {

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        name: 'date',
                        type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                        label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                        isRequired: true
                        
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
        ]
    },
    object: {
        ...expenseConfig.object
    }
}
  
const ExpenseTemplateDetailPage = () => {

    const { id } = useParams()
    
    configs.c_actions = [
        {
            label: tc.BUTTON_ADD_TEMPLATE_ENTRY_LABEL,
        },
        {
            label: tc.BUTTON_SCHEDULE_LABEL,
        }
    ]
 
    return (
        <DetailPageV2 service={s} configs={configs} >
            <TemplateEntryModal  configs={configs} modalConfigs={modalConfigs} service={s}/>
            <ScheduleModal configs={configs} templateid={id} service={scheduleService}/>
        </DetailPageV2>
    )
}

export default ExpenseTemplateDetailPage