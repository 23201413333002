import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import NewPage from "../../common/layouts/pages/new-page";
import NewPageV2 from "../../common/layouts/pages/new-page/new-page-v2";

import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/chart-service'

const ChartNewPage = () => {
 
    return <NewPageV2 service={s} configs={configs} />
}

export default ChartNewPage