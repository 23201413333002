// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/transaction-asset-slice';
 
const SERVICE_PATH = 'assets';
  
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})

export const { get, getAll, getMore, add, update, remove, clone } = fns