import React, { Fragment, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap'
import TableListItem from "./table-list-item";
 
import EmptyList from "../../statuses/empty-list";
import LoadingList from "../../statuses/loading-list";
 
const TableListBody = ({ items, configs, loadingMore, printElement }) => {
      
    if(loadingMore || items && items.length > 0)
        return (
            <Fragment>
                {/* normal list  */}
                <table className="table table-hover table-bordered"> 
                    <tbody>
                        {   
                            items.map((e, i) => { 
                                return (
                                    <TableListItem element={e} configs={configs} key={i} print={printElement}/>
                                )
                            })
                        }
                        {
                            loadingMore && <LoadingList configs={configs} isText={false}/>
                        }
                        
                    </tbody>
                </table>
                
                <div style={{paddingBottom: '25px'}}></div>

            </Fragment>
        )
         
    // empty status 
    if(!items || items.length === 0)
        return <EmptyList configs={configs}/>
}

TableListBody.propTypes = {
    configs: PropTypes.object.isRequired
}

export default TableListBody



