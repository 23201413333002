import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import * as dataService from "../../../../../utilities/services/report-data-standard-service"
import ReportTableViewer from "../../../../../common/viewers/report-table-viewer";
import ReportTableViewerSize from "../../../../../common/viewers/report-table-viewer-size";
import LoadingComponent from "../../../../../common/layouts/statuses/loading-component";

import AutoHeightContainer from "../../../../../common/auto-height-container/auto-height-container";

const ReportTableViewerContainer = ({reportid, columns, filters, filtersLogic, filtersLogicExpression, sortColumn, sortColumnDirection, user, size}) => {
      
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()
 
    useEffect(() => {
 
        setLoading(true)

        const load = async () => {
    
            if(!reportid || !columns)
                return

            const columnids = columns.map(x=>x.id) 
            const filtersids = filters.map(x=> `${x.column.id}:${x.operator.id}:${x.value}`)
 
            const response = await dataService.getLive({
                payload: {
                    id: reportid, 
                    columns: columnids, 
                    filters: filtersids, 
                    filtersLogic: filtersLogic,
                    filtersLogicExpression: filtersLogicExpression? filtersLogicExpression: '',
                    sortColumn: sortColumn, 
                    sortColumnDirection: sortColumnDirection
                }, user}, dispatch)

            if(response.success)
                setData(response.data)

            setLoading(false)
        }

        load()
        
    }, [columns, filters, filtersLogic, filtersLogicExpression, sortColumn, sortColumnDirection])
 
    if(loading) {
        return (
            <div className="text-center">
                <LoadingComponent isText={true}/>
            </div>
        )
    }
     
    return (
        <div>
            <AutoHeightContainer bottomMargin={80}>
                <ReportTableViewer data={data.data} bottomMargin={50} user={user}/> 
            </AutoHeightContainer>
        </div>
       
    )
}

export default ReportTableViewerContainer