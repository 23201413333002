import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';
import * as c from "../../common/configurations/constants";
import * as tc from '../../common/configurations/text-constants'
 
import * as s from '../../utilities/services/report-type-service'

const ReportTypeDetailPage = () => {
  
    return (
        <DetailPageV2 service={s} configs={configs} >
        </DetailPageV2>
    )
}

export default ReportTypeDetailPage