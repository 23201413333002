import React, { useEffect, useState } from "react";
import {  useSelector } from 'react-redux' 

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
 
import * as s from '../../utilities/services/dashboard-service'
import * as tc from '../../common/configurations/text-constants'
 
const DashboardDetailPage = () => {
   
    configs.c_actions = [
        {
            label: tc.BUTTON_PRINT_INFO_LABEL
        }
    ]
  
    return (
        <DetailPageV2 service={s} configs={configs} >
            
        </DetailPageV2>
    )
}

export default DashboardDetailPage