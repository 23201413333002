import React, { useState } from "react";
import { Modal, Table, Form, Button, Alert } from 'react-bootstrap';
import Icon from '@mdi/react';
import {mdiCheckCircleOutline} from '@mdi/js';

import LoadingComponent from "../../../common/layouts/statuses/loading-component";
import * as s from '../../../utilities/services/statement-service';

const UploadStatementModal = ({ item, close, user, configs }) => {
    const [transactions, setTransactions] = useState({ 
        transactions: {
            expenses: [],
            incomes: [],
            debtPayments: [],
            bankCharges: [],
            yields: []
        }, 
        totals: {}, 
        dateRange: {} 
    });
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [isProcessingTransactions, setIsProcessingTransactions] = useState(false);
    const [selectedTransactions, setSelectedTransactions] = useState([]);
    const [createdTransactions, setCreatedTransactions] = useState({
        expenses: [],
        incomes: [],
        payments: []
    });
    const [step, setStep] = useState('upload'); // 'upload', 'select', 'complete'

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const MAX_FILE_SIZE = 50 * 1024;
        
        setFileError("");
        
        if (selectedFile) {
            if (selectedFile.size > MAX_FILE_SIZE) {
                setFileError("El archivo excede el tamaño máximo permitido de 50KB");
                setFile(null);
                e.target.value = null;
            } else {
                setFile(selectedFile);
            }
        }
    };

    const addUniqueIds = (transactions) => {
        return transactions.map((transaction, index) => ({
            ...transaction,
            uniqueId: `${transaction.date}-${transaction.amount}-${transaction.title}-${index}`
        }));
    };

    const handleTransactionSelect = (transaction, isChecked) => {
        if (isChecked) {
            setSelectedTransactions([...selectedTransactions, transaction]);
        } else {
            setSelectedTransactions(selectedTransactions.filter(t => t.uniqueId !== transaction.uniqueId));
        }
    };

    const handleUpload = async () => {

        if (!file) return;
        
        setIsLoadingFile(true);
        
        const params = {
            payload: file,
            item: item,
            user: user
        };

        try {
            const response = await s.recoveryBank(params);
            if(response.success) {
                const processedData = {
                    ...response.data,
                    transactions: {
                        expenses: addUniqueIds(response.data.transactions.expenses || []),
                        incomes: addUniqueIds(response.data.transactions.incomes || []),
                        debtPayments: addUniqueIds(response.data.transactions.debtPayments || []),
                        bankCharges: addUniqueIds(response.data.transactions.bankCharges || []),
                        yields: addUniqueIds(response.data.transactions.yields || [])
                    }
                };
                setTransactions(processedData);
                setStep('select');
            } else {
                setFileError("Error al procesar el archivo. Por favor, revise que el archivo e intente nuevamente.");
            }
        } catch(e) {
            console.error(e);
            setFileError("Error al procesar el archivo. Por favor, revise que el archivo e intente nuevamente.");
        } finally {
            setIsLoadingFile(false);
        }
    };

    const handleProcessTransactions = async () => {
        if (selectedTransactions.length === 0) return;

        setIsProcessingTransactions(true);

        try {
            const transactionsToProcess = selectedTransactions.map(({ uniqueId, ...transaction }) => transaction);
            
            const response = await s.createTransacctionsBank({
                payload: transactionsToProcess,
                item: item,
                user: user
            });

            if (response.success) {
                setCreatedTransactions(response.data);
                setStep('complete');
            }
        } catch (e) {
            console.error(e);
            setFileError("Error al procesar las transacciones. Por favor, intente nuevamente.");
        } finally {
            setIsProcessingTransactions(false);
        }
    };

    const getTransactionTypeLabel = (type) => {
        const labels = {
            expenses: 'Gastos',
            incomes: 'Ingresos',
            payments: 'Pagos de Deuda',
            debtPayments: 'Pagos de Deuda',
            bankCharges: 'Cargos Bancarios',
            yields: 'Rendimientos'
        };
        return labels[type] || type;
    };

    const renderTransactionTable = (transactions, type) => {
        if (!transactions || transactions.length === 0) return null;

        return (
            <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="fw-bold m-0">{getTransactionTypeLabel(type)}</h5>
                </div>
                <Table bordered hover>
                    <thead>
                        <tr className="table-secondary">
                            <th width="10%">Seleccionar</th>
                            <th width="25%">Fecha</th>
                            <th width="25%">Monto</th>
                            <th width="40%">Título</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions
                            // .filter(transaction => !transaction.exists)
                            .map((transaction) => (
                            <tr key={transaction.uniqueId}>
                                <td className="text-center">
                                    {
                                        transaction.exists? 
                                        <Icon path={mdiCheckCircleOutline} color="green" size={1}/>:
                                        <Form.Check 
                                            type="checkbox"
                                            onChange={(e) => handleTransactionSelect(transaction, e.target.checked)}
                                            checked={selectedTransactions.some(t => t.uniqueId === transaction.uniqueId)}
                                        />
                                    }
                                    
                                </td>
                                <td>{transaction.date}</td>
                                <td className="text-end">
                                    ${Math.abs(transaction.amount).toFixed(2)}
                                </td>
                                <td>{transaction.title || "N/A"}</td>
                            </tr>
                        ))}
                        <tr className="table-">
                            <td colSpan="2" className="text-end fw-bold">Total:</td>
                            <td className="text-end fw-bold">
                                ${Math.abs(transactions.reduce((sum, t) => sum + parseFloat(t.amount), 0)).toFixed(2)}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    };

    const renderCreatedTransactionsTable = (transactions, type) => {
        if (!transactions || transactions.length === 0) return null;

        const total = transactions.reduce((sum, t) => sum + parseFloat(t.amount), 0);

        return (
            <div className="mb-4">
                <h5 className="fw-bold mb-3">{getTransactionTypeLabel(type)}</h5>
                <Table bordered hover>
                    <thead>
                        <tr className="table-secondary">
                            <th width="30%">Nombre</th>
                            <th width="20%">Fecha</th>
                            <th width="25%">Monto</th>
                            <th width="25%">Categoría</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.name}</td>
                                <td>{transaction.date}</td>
                                <td className="text-end">
                                    ${Math.abs(transaction.amount).toFixed(2)}
                                </td>
                                <td>{transaction.expense || transaction.income || transaction.liability || "N/A"}</td>
                            </tr>
                        ))}
                        <tr className="table-">
                            <td colSpan="2" className="text-end fw-bold">Total:</td>
                            <td className="text-end fw-bold">
                                ${Math.abs(total).toFixed(2)}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    };

    const renderUploadStep = () => (
        <div className="upload-section p-4">
            <Form.Group controlId="statement" className="mb-4">
                <Form.Label className="fw-bold">Subir estado de cuenta (TXT)</Form.Label>
                <Form.Control type="file" accept=".txt" onChange={handleFileChange} />
                <Form.Text className="text-muted">
                    Tamaño máximo permitido: 50KB
                </Form.Text>
            </Form.Group>
            {fileError && (
                <Alert variant="danger" className="mb-3">
                    {fileError}
                </Alert>
            )}
            <div className="d-flex justify-content-start">
                <Button 
                    className="btn-success" 
                    onClick={handleUpload} 
                    disabled={!file}
                >
                    Cargar y Obtener Transacciones
                </Button>
            </div>
        </div>
    );

    const renderSelectStep = () => {
        const { transactions: transactionGroups, totals } = transactions;
        const transactionTypes = ['expenses', 'incomes', 'debtPayments', 'bankCharges', 'yields'];
        
        return (
            <div className="p-4">
                <h5 className="mb-4 fw-bold">Seleccione las transacciones a procesar</h5>
                
                {transactionTypes.map(type => 
                    renderTransactionTable(transactionGroups[type], type)
                )}
 
                <div className="d-flex justify-content-start mt-4">
                    <Button 
                        className="btn-success" 
                        onClick={handleProcessTransactions}
                        disabled={selectedTransactions.length === 0}
                    >
                        Procesar Transacciones Seleccionadas
                    </Button>
                </div>
            </div>
        );
    };

    const renderCompleteStep = () => {
        const grandTotal = Object.values(createdTransactions).reduce(
            (sum, group) => sum + (group?.reduce((groupSum, t) => groupSum + parseFloat(t.amount), 0) || 0), 
            0
        );

        return (
            <div className="p-4">
                <div className="alert alert-success mb-4">
                    <i className="bi bi-check-circle me-2"></i>
                    Las transacciones han sido procesadas exitosamente
                </div>
                <h5 className="mb-4 fw-bold">Transacciones creadas:</h5>
                
                {Object.entries(createdTransactions).map(([type, transactions]) => 
                    renderCreatedTransactionsTable(transactions, type)
                )}

                <div className="alert alert-secondary">
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="fw-bold">Total General:</span>
                        <span className="fw-bold">
                            ${Math.abs(grandTotal).toFixed(2)}
                        </span>
                    </div>
                </div>

                <div className="d-flex justify-content-start">
                    <Button 
                        className="btn-secondary" 
                        onClick={close}
                    >
                        Cerrar
                    </Button>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (isLoadingFile || isProcessingTransactions) {
            return (
                <div className="p-4">
                    <LoadingComponent configs={configs} isText={true} />
                </div>
            );
        }

        switch (step) {
            case 'upload':
                return renderUploadStep();
            case 'select':
                return renderSelectStep();
            case 'complete':
                return renderCompleteStep();
            default:
                return null;
        }
    };

    return (
        <Modal show={true} onHide={close} fullscreen={true} className="custom-modal">
            <Modal.Header className="border-bottom">
                <button
                    type="button"
                    className="btn-close"
                    onClick={close}
                    style={{ marginLeft: 0 }}
                />
            </Modal.Header>
            <Modal.Body className="bg-light">
                {renderContent()}
            </Modal.Body>
        </Modal>
    );
};

export default UploadStatementModal;