import React from "react";
import { useSelector } from 'react-redux'


import SimplePage from "../../common/layouts/pages/simple-page";
import TableContainer  from "../../common/layouts/lists/table/table-container";
import configs from './configs'

import * as s from '../../utilities/services/report-type-service'

import './report-type.css'

const ReportTypePage = () => {
    
    return (
        <SimplePage configs={configs}>
            <TableContainer service={s} configs={configs} />
        </SimplePage>
    )
}
export default ReportTypePage