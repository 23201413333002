import { createSlice } from '@reduxjs/toolkit'

const vendorSlice = createSlice({
  name: 'vendor',
  initialState: { loading: false, list: [], item: undefined, itemId: undefined, message: undefined, error: undefined },
  reducers: {
     
    getAll(state, action) {
      state.loading = true
    },

    getAllResponse(state, action) {
      state.loading = false
      state.list = action.payload
    },

    get(state, action) {
      state.loading = true
    },

    getResponse(state, action) {
      state.loading = false
      state.item = action.payload
    },

    getMore(state, action) {
        state.loading = true
    },
    
    getMoreResponse(state, action) {
        state.loading = false
        if(action.payload.length > 0)
            state.list = [...state.list, ...action.payload]
    },

    add(state, action) {
      state.loading = true
    },

    addResponse(state, action) {
      state.loading = false
      state.item = action.payload
      state.list = [action.payload, ...state.list]
    },

    update(state, action) {
        state.loading = true
    },

    updateResponse(state, action) {

        state.loading = false
        state.item = action.payload
    },
 
    remove(state, action) {
        state.loading = true
    },

    removeResponse(state, action) {

        state.loading = false
        state.itemId = action.payload
    },
      
    error(state, action) {
        state.loading = false
        state.error = action.payload

        console.log(action.payload)
    }
  }
})

export const { 
    getAll, getAllResponse,
    getMore, getMoreResponse,
    get, getResponse,
    add, addResponse,
    remove, removeResponse,
    update, updateResponse,
    error
} = vendorSlice.actions;

export default vendorSlice.reducer