// system imports
import * as gservice from './_generic-service'

import * as actions from '../redux/actions/asset-automated-slice';
 
const SERVICE_PATH = 'automated-asset-emails';
  
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
 
export const { getAll, get, add, update, remove, clone } = fns