import React from "react"; 
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import i18n hook

import MaterialIcon from "../../../common/icons/material-icon";

import './authenticated-container.css'
   
const AuthenticatedContainerCompactSideHeader = ({user, configs, select}) => {
    
    const { t } = useTranslation();

    return (
        <div className="card mb-1">
            <div className="card-body d-flex flex-row justify-content-center py-2">
                {
                    configs.map((c, index) => {
                        return (
                            <div className="list-group-item-action text-center" onClick={select} key={index}>
                                <Link className="list-group-item" to={c.object.basePath}>
                                    <MaterialIcon obj={c.object.name} tooltip={t(c.object.pluralLabel)}/>
                                </Link>
                            </div>
                            
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AuthenticatedContainerCompactSideHeader