import React, { useEffect, useState } from "react";
import ChartShowPage from "../../chart-standard/chart-show-page";
import ReportShowPage from "../../report-standard/report-show-page";
import { Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux'

import * as c from "../../../common/configurations/constants";
import * as tc from "../../../common/configurations/text-constants";
 
import { refreshSections } from '../../../utilities/redux/actions/dashboard-slice'
import Selector from "../../../common/selectors/selector";

const DashboardSectionItem = ({id, index, remove, readonly}) => {
          
    const TYPE_COMPONENT_REPORT_PREFIX = 'u9s'
    const TYPE_COMPONENT_CHART_PREFIX = 'lqs'
  
    if(!id)
        return <div />

    return (
        <div className="card mb-3">
            <div className="card-body">
                {
                    !readonly &&
                    <div className="vstack gap-2 h-100">
                        <div className="ms-auto">
                            <i className="bi bi-x-lg" onClick={() => remove(index)}></i>
                        </div>
                    </div>
                }
                
                {
                    id.startsWith(TYPE_COMPONENT_CHART_PREFIX) &&
                    <ChartShowPage internalid={id} />
                }

                {
                    id.startsWith(TYPE_COMPONENT_REPORT_PREFIX) &&
                    <ReportShowPage internalid={id} size={c.REPORT_TYPE_SIZE_700}/>
                }
                
            </div> 
             
        </div>
        
    )
}

export default DashboardSectionItem