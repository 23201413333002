import { configureStore } from '@reduxjs/toolkit'

import userReducer from './actions/user-slice'

import accountReducer from './actions/account-slice'
import accountIncomeReducer from './actions/account-income-slice'
import accountExpenseReducer from './actions/account-expense-slice'
import accountAssetReducer from './actions/account-asset-slice'
import accountLiabilityReducer from './actions/account-liability-slice' 
 
import expenseReducer from './actions/transaction-expense-slice'
import incomeReducer from './actions/transaction-income-slice'
import liabilityReducer from './actions/transaction-liability-slice'
import liabilityPaymentReducer from './actions/transaction-liability-payment-slice'
import assetReducer from './actions/transaction-asset-slice'
 

import expenseTemplateReducer from './actions/expense-template-slice'
import incomeTemplateReducer from './actions/income-template-slice'
import assetTemplateReducer from './actions/asset-template-slice'
import liabilityPaymentTemplateReducer from './actions/liability-payment-template-slice'

import templateScheduleReducer from './actions/template-schedule-slice'

import expenseAutomatedEmailReducer from './actions/expense-automated-slice'
import incomeAutomatedEmailReducer from './actions/income-automated-slice'
import assetAutomatedEmailReducer from './actions/asset-automated-slice'
import liabilityPaymentAutomatedEmailReducer from './actions/liability-payment-automated-slice'
 
import vendorReducer from './actions/vendor-slice'
import buyerReducer from './actions/buyer-slice'

import paymentMethodReducer from './actions/payment-method-slice'

import reportReducer from './actions/report-slice'
import reportDataReducer from './actions/report-data-slice'
import reportTypeReducer from './actions/report-type-slice'
import chartReducer from './actions/chart-slice'
import chartDataReducer from './actions/chart-data-slice'
import dashboardReducer from './actions/dashboard-slice'
import dashboardSectionReducer from './actions/dashboard-section-slice'



import transactionAutomatedEmailReducer from './actions/transaction-automated-slice'
import transactionAutomatedSMSReducer from './actions/transaction-automated-sms-slice'
import expenseBudgetReducer from './actions/expense-budget-slice'
import userProfileReducer from './actions/user-profile-slice'
import userAutomatedEmailReducer from './actions/user-automated-slice'


import supportTicketReducer from './actions/ticket-slice'
import supportTicketMessageReducer from './actions/ticket-message-slice'

import chatbotReducer from './actions/chatbot-slice'


import listviewReducer from './actions/listview-slice'

export const store = configureStore({ 
  reducer: {
    user: userReducer,

    account: accountReducer,
    accountIncome: accountIncomeReducer,
    accountExpense: accountExpenseReducer,
    accountAsset: accountAssetReducer,
    accountLiability: accountLiabilityReducer,
 
    expense: expenseReducer,
    liability: liabilityReducer,
    liabilityPayment: liabilityPaymentReducer,
    asset: assetReducer,

    incomeTemplate: incomeTemplateReducer,
    expenseTemplate: expenseTemplateReducer,
    assetTemplate: assetTemplateReducer,
    liabilityPaymentTemplate: liabilityPaymentTemplateReducer,

    templateSchedule: templateScheduleReducer,
 
    userAutomatedEmail: userAutomatedEmailReducer,
    expenseAutomatedEmail: expenseAutomatedEmailReducer,
    incomeAutomatedEmail: incomeAutomatedEmailReducer,
    assetAutomatedEmail: assetAutomatedEmailReducer,
    liabilityPaymentAutomatedEmail: liabilityPaymentAutomatedEmailReducer,

    vendor: vendorReducer,
    buyer: buyerReducer,
    
    paymentMethod: paymentMethodReducer,
    income: incomeReducer,
    report: reportReducer,
    reportData: reportDataReducer,
    reportType: reportTypeReducer,
    chart: chartReducer,
    chartData: chartDataReducer,
    dashboard: dashboardReducer,
    dashboardSection: dashboardSectionReducer,
 
    expenseBudget: expenseBudgetReducer,
    userProfile: userProfileReducer,
   
    
    transactionAutomatedEmail: transactionAutomatedEmailReducer,
    transactionAutomatedSMS: transactionAutomatedSMSReducer,
    
    supportTicket: supportTicketReducer,
    supportTicketMessage: supportTicketMessageReducer,
    listview: listviewReducer,

    chatbot: chatbotReducer
  }
})