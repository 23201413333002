import React, { Fragment, useLayoutEffect, useState, useRef } from "react";

import './report-table-viewer'

import ReportTableViewerBody from "../report-table-viewer-body";

const ReportTableViewer = ({data, info, bottomHandler, bottomMargin, disableTracking, user}) => {
  
    const [style, setStyle] = useState({height: 0});
    const scrollContainer = useRef(null);
      
    let lastPosition;
    const trackScrollMovingDown = (e) => {

        if(!lastPosition) {
            lastPosition = e.target.scrollTop
            return true
        }            

        let isMovingDown = lastPosition < e.target.scrollTop
        lastPosition = e.target.scrollTop

        return isMovingDown
    }

    const trackScrolling = (e) => {

        if(disableTracking)
            return

        if(!bottomHandler)
            return
        
        // direction of scroll
        if(!trackScrollMovingDown(e))    
            return
        
        const scrollContainerBottomPosition = Math.round(e.target.scrollTop + e.target.clientHeight);
        const scrollPosition = Math.round(e.target.scrollHeight - 1);        
        if (scrollPosition > scrollContainerBottomPosition)
            return
        
        if(bottomHandler)
            bottomHandler(info)
    }

    useLayoutEffect(() => {

        const BOTTON_MARGIN = bottomMargin? bottomMargin: 0;
        
        const updateWindowDimentions = () => {
            setStyle({height: window.innerHeight - scrollContainer.current.getBoundingClientRect().top - BOTTON_MARGIN})
        }
        
        window.addEventListener('resize', updateWindowDimentions);
        scrollContainer.current.addEventListener('scroll', trackScrolling)

        updateWindowDimentions()
  
        return function clearup() {
            window.removeEventListener('resize', updateWindowDimentions)
            scrollContainer.current.removeEventListener('scroll', trackScrolling)
            
        }
    }, [bottomMargin, info]);
      
    if(!data || !data.rows)
        return <div className="container-fluid auto-container" style={style} ref={scrollContainer}/>

        // 0px 40px 0px 25px 
    return (
        <div className="" ref={scrollContainer} style={{"overflowX": "auto", "overflowY": "auto"}}> 
            <ReportTableViewerBody data={data} />
        </div>
    )
}

export default ReportTableViewer