import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
  
import NewPageV2 from "../../common/layouts/pages/new-page/new-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/transaction-income-service'
import * as saccount from '../../utilities/services/account-income-service'

const IncomeNewPage = () => {
 
    const user = useContext(UserContext)
 
    const dispatch = useDispatch()
 
    const [message, setMessage] = useState({})
    const { accountid } = useParams()
    const [defaultAccount, setDefaultAccount] = useState()
  
 
    useEffect(() => {

        if(accountid) {
            const load = async () => {
                const response = await saccount.get({payload: {id: accountid}, user }, dispatch)
                if(response.success)
                    setDefaultAccount(response.data)
            }
            
            load()
        }
       
    }, [])

    if(!accountid)
        return <NewPageV2 service={s} configs={configs} message={message} defaultItem={{}}/>

    if(accountid && !defaultAccount)
        return <div />

    return <NewPageV2 service={s} configs={configs} message={message} defaultItem={{income_id: defaultAccount.id, income: defaultAccount.name, }} /> 
}

export default IncomeNewPage