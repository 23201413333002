import { Route } from "react-router-dom";
 
import AutomatedPage from "../../automations/asset-automated-email/asset-automated-page";
import AutomatedNewPage from '../../automations/asset-automated-email/asset-automated-new-page';
import AutomatedDetailPage from '../../automations/asset-automated-email/asset-automated-detail-page';
import AutomatedEditPage from '../../automations/asset-automated-email/asset-automated-edit-page';
import AutomatedClonePage from '../../automations/asset-automated-email/asset-automated-clone-page';

const routes =  
    <Route path="/asset-automated-emails">
    
        <Route index element={<AutomatedPage />} />

        <Route path="new" element={<AutomatedNewPage />} />

        <Route path=":id" >
            <Route path="view" element={<AutomatedDetailPage />} />
            <Route path="edit" element={<AutomatedEditPage />} />
            <Route path="clone" element={<AutomatedClonePage />} />
        </Route>

    </Route> 

export default routes
