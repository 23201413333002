import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getSizeCSSBasedOnType, getFormattedLabel } from "../../fields/utility-field"

import './table.css'

const TableListHeader = ({ configs, user }) => {
   
    const { t } = useTranslation();

    const getColumns = () => {
        return configs.list.items.map((e, i)=> {
            return <th className={`text-center text-truncate col text-uppercase ${getSizeCSSBasedOnType(e)}`} key={i}>{getFormattedLabel(t(e.label), user)}</th>
        })
    }
 
    return (
        <table className="table table-bordered " style={{marginBottom: "0px"}}>
            <thead>
                <tr className="table-secondary row ">
                    {
                        configs.object.printable &&
                        <td className="table-cell-preview"></td>
                    }
                    {
                        getColumns()
                    }
                </tr>
                
            </thead>
        </table>
    )
}

TableListHeader.propTypes = {
    configs: PropTypes.object.isRequired
}

export default TableListHeader



