import React, { useState, useEffect } from "react";
import Select, { components } from 'react-select';

import { getSelectorValue } from './helper'

const BasicListSelector = ({list, leaf, onChange}) => {
        
    const [valueOptions, setValueOptions] = useState()
    const [internalValue, setInternalValue] = useState()
 
    // customization of Select component options
    const Option = ({ ...props }) => {                
        return <components.Option {...props}>
            <div>{props.label}</div>
        </components.Option>
    } 
   
    useEffect(() => {
 
        const load = async () => {
              
            const voptions = list.map((e)=> {  
                return { 
                    value: e.id, 
                    label: e.name
                }
            })

            setValueOptions(voptions)
 
            // set value
            setInternalValue(getSelectorValue(leaf?.id, list))
        }

        load()
        
    }, [list, leaf])
  
    return <Select options={valueOptions} components={{ Option }} isSearchable ={true} onChange={(value) => onChange(value)} value={internalValue} isClearable={true}/>
}

export default BasicListSelector