import React from "react"

import FilterPreviewItem from './filter-preview-item'

const FilterPreview = ({filters, deleteItem, changeValueItem}) => {
  
    return (
        <div className="card">
            <ul className="list-group list-group-flush">
                {
                    filters.map((f, i)=> <FilterPreviewItem item={f} index={i} key={i} deleteItem={deleteItem} changeValueItem={changeValueItem} />)
                }
                {
                    filters.length == 0 && 
                    <p className="p-2">Ningun Filtro ha sido creado</p>
                }
            </ul> 
        </div>
        
    )
}

export default FilterPreview