import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import NewPageV2 from "../../common/layouts/pages/new-page/new-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/transaction-expense-service'
import * as saccount from '../../utilities/services/account-expense-service'

const ExpenseNewPage = () => {
 
    const user = useContext(UserContext)

    const { accountid } = useParams()
    const [defaultAccount, setDefaultAccount] = useState()
     
    const dispatch = useDispatch()
 
    const [message, setMessage] = useState({})
  
    useEffect(() => {

        if(accountid) {
            const load = async () => {
                const response = await saccount.get({payload: {id: accountid}, user }, dispatch)
                if(response.success)
                    setDefaultAccount(response.data)
            }
            
            load()
        }
        
    }, [])
  
    if(!accountid)
        return <NewPageV2 service={s} configs={configs} message={message} defaultItem={{}}/>

    if(accountid && !defaultAccount)
        return <div />

    return <NewPageV2 service={s} configs={configs} message={message} defaultItem={{expense_id: defaultAccount.id, expense: defaultAccount.name}} />
  
}

export default ExpenseNewPage