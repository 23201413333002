// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/report-slice';

const SERVICE_PATH = 'report-type-schemas';
   
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { getAll } = fns