import React, { Fragment, useState, useEffect } from "react"
import { Link} from "react-router-dom";
import _ from "lodash"
import Icon from '@mdi/react';
import Tooltip from '@mui/material/Tooltip';
import { mdiLockOutline, mdiFileArrowLeftRightOutline } from '@mdi/js';
 
import OutputField from "../../layouts/fields/output-field"
import { getCSSBasedOnType } from "../../layouts/fields/utility-field"

const BasicTreeItem = ({pitem, searchText, level, index, configs}) => {
       
    const [item, setItem] = useState()
    
    const [itemChildren, setItemChildren] = useState(undefined)
     
    const [lname, setLname] = useState(item && item?.name)
    const [cname, setCname] = useState('')
    const [rname, setRname] = useState('')
  
    /** functions */
    const toogle = (e) => {
        e.stopPropagation()

        // root node
        if(item.id ===0)
            return

        setItem({...item, closed: !item.closed})
    }
     
    const searchVisibility = (item, searchText) => {
        
        let sIndex;
        if(item.name.toLowerCase().indexOf(searchText.toLowerCase()) !==-1) {
            sIndex = item.name.toLowerCase().indexOf(searchText.toLowerCase())
    
            setLname(item.name.substring(0, sIndex))
            setCname(item.name.substring(sIndex, sIndex + searchText.length)) 
            setRname(item.name.substring(sIndex + searchText.length))
        }
        else {
            setLname(item.name)
            setCname('') 
            setRname('')
        } 
    }
  
    /** components  */
    const children = (internal) => {
 
        if(item.id !==0 && (!itemChildren || internal.closed))
            return <tr />
 
        return itemChildren.map((value, index) => {
   
            let cprops = {searchText, level, index, configs}
            cprops.pitem = value
            cprops.index = index
            cprops.parent = item
            cprops.level = level + 1

            return (
                <BasicTreeItem key={value.id} {...cprops} ></BasicTreeItem>
            )
            
        })
    }

    const icon = ()=> {

        // if it doesnt have children it doesnt show any arrow        
        if(!itemChildren || itemChildren.length === 0)
            return (                
                <i className="bi bi-dash" />
            )

        return (
            item.closed? <i className="bi bi-chevron-right" />: <i className="bi bi-chevron-down" />
        )
    }

    const typeIcon = ()=> {
  
        return (
            <span className="badge rounded-pill bg-secondary">
                {
                    item.is_standard &&
                    <Tooltip title={'Categoria de Sistema'} placement='top'>
                        <Icon path={mdiLockOutline} height='15px' width='15px' className='mx-1 text-light'/>
                    </Tooltip>
                }
                {
                    item.has_transactions &&
                    <Tooltip title={'Tiene Transacciones'} placement='top'>
                        <Icon path={mdiFileArrowLeftRightOutline} height='15px' width='15px' className='mx-1 text-light'/>
                    </Tooltip>
                }
            </span>
        )
    }
    
    const display = () => {
          
        return (
            <div className="item hstack gap-2" >
                <span className="account-tree-open-indicator" onClick={toogle}>
                    { icon() }
                </span>
                <span className="tree-index-item-label">
                    <Link to={`/${item.id}`} style={{textDecoration: "none"}}>
                        <span>{lname}</span>
                        <span className="search-color">{cname}</span>{/* searching word */}
                        <span>{rname}</span>
                    </Link>
                </span>
                <span className="ms-auto d-none d-md-block">{ typeIcon() }</span>
            </div>
        )
    }

    const getTreeColumns = (configs) => {

        if(!item)
            return <td />

        return configs.treelist.items.map((c, i) => {
            return (
                <td key={i} className={`tree-list-column ${getCSSBasedOnType({type: c.type})} ${c.isCompactVisible? '': 'd-none  d-md-table-cell'}`}> 
                    <OutputField vConfig={{type: c.type}} value={item[c.name]}/>
                </td>
            )
        })
    }
  
    useEffect(()=> {
         
        if(!pitem)
            return

        setItem(pitem)
        
        searchVisibility(pitem, searchText)

        if(pitem.children)
            setItemChildren(pitem.children.filter((c) => !c.deleted))

    }, [pitem, searchText])

    if(!item)
        return <tr></tr>
   
    return (
        <Fragment>
            <tr key={index}>
                <td> 
                    <div style={{paddingLeft: level * 20}}>
                        { 
                            display()
                        }
                    </div>
                </td> 

                { getTreeColumns(configs) }                 
            </tr>      
            
            {
                item && item.children && !item.closed &&
                children(item)
            }
        </Fragment>
    )
}
export default BasicTreeItem