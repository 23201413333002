
import * as gservice from './_generic-service'
 
import * as actions from '../redux/actions/chatbot-slice';

const SERVICE_PATH = 'help-chats';
 
const sendMessage = async (params, dispatch) => {
 
    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.add(sparams, params, dispatch)
 
}

export { sendMessage }