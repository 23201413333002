import { Route } from "react-router-dom";

import PassivePage from "../../transactions/transaction-liability";
import PassiveNewPage from '../../transactions/transaction-liability/transaction-liability-new-page';
import PassiveDetailPage from '../../transactions/transaction-liability/transaction-liability-detail-page';
import PassiveEditPage from '../../transactions/transaction-liability/transaction-liability-edit-page';
import PassiveClonePage from '../../transactions/transaction-liability/transaction-liability-clone-page';
   
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/liabilities">
 
            <Route index element={<PassivePage />} />

            <Route path="new" element={<PassiveNewPage />} />
            <Route path="new/:accountid" element={<PassiveNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<PassiveDetailPage />} />
                <Route path="edit"  element={<PassiveEditPage />} />
                <Route path="clone"  element={<PassiveClonePage />} />
            </Route>

        </Route> 
 
export default routes
