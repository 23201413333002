import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import OutputField from "../../fields/output-field";
import { getCSSBasedOnType, getSizeCSSBasedOnType } from "../../fields/utility-field";

import './table.css';

const TableListCompactItem = ({ element, configs, print }) => {
    
    const { list, object } = configs;
    const headerConfig = list.items.find(i => i.name === 'name');
    const leftDetailConfig = list.items.find(i => i.compactViewLeftDetail);
    const rightDetailConfig = list.items.find(i => i.compactViewRightDetail);

    const renderLinkOrText = (config) => {
        const classes = `hstack gap-2 text-truncate col ${getCSSBasedOnType(config)} ${getSizeCSSBasedOnType(config)}`;
        const content = (
            <>
                {element.has_files && <i className="bi bi-paperclip"></i>}
                <OutputField value={element[config.name]} vConfig={config}/>
            </>
        );

        if (config.isLinked) {
            return (
                <Link 
                    to={`/${element.id}`} 
                    style={element.is_standard ? {fontWeight: 'bold', color: 'gray'} : {}}
                    className="text-truncate"
                    title={element.name}>
                    {content}
                </Link>
            );
        }
 
        return <span className={classes}>{content}</span>;
    };

    const renderColumn = () => {
        if (!headerConfig) return <td />;

        return (
            <td className={`text-truncate col ${getCSSBasedOnType(headerConfig)} ${getSizeCSSBasedOnType(headerConfig)}`}>
                <div className="vstack gap-1">
                    {renderLinkOrText(headerConfig)}
                    <div className="d-flex justify-content-between">
                        <span className="text-start">
                            {leftDetailConfig && <OutputField value={element[leftDetailConfig.name]} vConfig={leftDetailConfig}/>}
                        </span>
                        <span className="text-end">
                            <div className="fw-bold">
                                {rightDetailConfig && <OutputField value={element[rightDetailConfig.name]} vConfig={rightDetailConfig}/>}
                            </div>
                        </span>
                    </div>
                </div>
            </td>
        );
    };

    return (
        <tr className="row">
            {object.printable && (
                <td className="table-cell-preview">
                    <Link onClick={() => print(element)}>
                        <i className="bi bi-eye list-item-preview" title={`Visualizar ${object.label}`}/>
                    </Link>
                </td>
            )}
            {renderColumn()}
        </tr>
    );
};

export default TableListCompactItem;