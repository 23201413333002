import React, { Fragment, useContext, useEffect }from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import SetupPageHeader from "./setup-page-header";
import SetupPageBody from "./setup-page-body";
import UserContext from '../../../../user/user-context';

import NotAvailableComponent from "../../statuses/not-available-component";
import LoadingComponent from "../../statuses/loading-component";

const SetupPage = ({service, children, configs}) => {
 
    const user = useContext(UserContext)

    const { t } = useTranslation();
    const { id } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const item = useSelector((state)=> state[configs.object.sliceName].item) 
    const loading = useSelector((state)=> state[configs.object.sliceName].loading)
 
    document.title = `${item? item.name: t(configs.object.label)}`
    
    // selectors
    
    // -- reports
    const columns = useSelector(state => state.report.columns)
    const filters = useSelector(state => state.report.filters)
    const filtersLogic = useSelector(state => state.report.filtersLogic)
    const sortColumn = useSelector(state => state.report.sortColumn)
    const sortColumnDirection = useSelector(state => state.report.sortColumnDirection)
    const filtersLogicExpression = useSelector(state => state.report.filtersLogicExpression)

    // -- chart
    const label = useSelector(state => state.chart.label)
    const value = useSelector(state => state.chart.value)
    const value2 = useSelector(state => state.chart.value2)
    const type = useSelector(state => state.chart.type)

    // --dashboard
    const sections = useSelector(state => state.dashboard.sections)

    // --report type
    const rtypeItem = useSelector(state => state.reportType.item)

    configs.actions.setup.handler = async ({element}) => {
        
        let payload = {
            ...element,
            
            columns, 
            filters, 
            filters_logic: filtersLogic, 
            filters_logic_expression: filtersLogicExpression, 
            sort_column_id: sortColumn, 
            sort_column_direction: sortColumnDirection, // report

            label_column_id: label, 
            value_column_id: value, 
            value2_column_id: value2, 
            chart_type_id: type, // chart

            sections, // dashboard

            // report type
            fields: rtypeItem?.fields
        } 

        const response = await service.setup({payload: payload, user: user }, dispatch)
        if(response.success)  
            navigate(`/${configs.object.basePath}/${response.data.id}/view`)
    }
    
    useEffect(() => {
        service.get({payload: {id}, user }, dispatch)
    }, [])

    if(loading)
        return <LoadingComponent configs={configs} isText={true}/>

    if(!item)
        return <NotAvailableComponent configs={configs} />

    return (
        <Fragment>
            <SetupPageHeader user={user} configs={configs} element={item}/>
            <SetupPageBody user={user} body={children} configs={configs} element={item}/>
        </Fragment>
    )
}

export default SetupPage