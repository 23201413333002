import { Route } from "react-router-dom";

import BuyerPage from "../../third-parties/buyer";
import BuyerNewPage from '../../third-parties/buyer/buyer-new-page';
import BuyerDetailPage from '../../third-parties/buyer/buyer-detail-page';
import BuyerEditPage from '../../third-parties/buyer/buyer-edit-page';
import BuyerClonePage from '../../third-parties/buyer/buyer-clone-page';
   
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/buyers">
 
            <Route index element={<BuyerPage />} />

            <Route path="new" element={<BuyerNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<BuyerDetailPage />} />
                <Route path="edit"  element={<BuyerEditPage />} />
                <Route path="clone"  element={<BuyerClonePage />} />
            </Route>

        </Route> 
 
export default routes
