import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';
import DashboardShowPage from './dashboard-show-page'

import * as s from '../../utilities/services/dashboard-standard-service'
import * as tc from '../../common/configurations/text-constants'

const DashboardDetailPage = () => {
  
    configs.c_actions = [
        {
            label: tc.BUTTON_PRINT_INFO_LABEL
        }
    ]
    
    return (
        <DetailPageV2 service={s} configs={configs} >
             
        </DetailPageV2>
    )
}

export default DashboardDetailPage