import React from "react";
 
const ColumnPreviewItem = ({ col, onRemoveColumn, index, total, upColumn, downColumn}) => {
       
    const downHandler = () => {
        downColumn(index)
    }

    const upHandler = () => {
        upColumn(index)
    }

    const removeHandler = ()=> {
        onRemoveColumn(col)
    }

    return (
        <li className="list-group-item">
            <div className="hstack gap-3" >
                <div>
                    {
                        index !== 0?
                        <a href="#" onClick={upHandler}><i className="bi bi-arrow-up" /></a>: 
                        <i className="bi bi-dash"></i>
                    }
                   

                    {
                        index < total - 1?
                        <a href="#"  onClick={downHandler} ><i className="bi bi-arrow-down"/></a>:
                        <i className="bi bi-dash"></i>
                    }
                    
                   
                </div>
                
                <span className="fw-light">{col.label}</span>
                <div className="ms-auto hstack gap-2">
                    <a href="#" onClick={removeHandler} className="link-danger"><i className="bi bi-trash" /></a>
                </div>
                
            </div>
        </li>
    )
}

export default ColumnPreviewItem