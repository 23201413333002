import { createSlice, current } from '@reduxjs/toolkit'
 
const paymentMethodSlice = createSlice({
  name: 'payment-method',
  initialState: { loading: false, target: {}, parent: undefined, item: undefined, list: [], typelist: [], message: undefined, error: undefined },
  reducers: {
    
    getAll(state, action) {
      state.loading = true
      state.list = []
    },

    getAllResponse(state, action) {
        state.loading = false
        state.list = action.payload
    },

    getAllByType(state, action) {
        state.loading = true
        state.list = []
    },

    getAllByTypeResponse(state, action) {
      state.loading = false
      state.list = action.payload
    },

    get(state, action) {
      state.loading = true
      state.item = undefined
    },

    getResponse(state, action) {
      state.loading = false
      state.item = action.payload
    },

    getParent(state, action) {
      state.loading = true
    },

    getParentResponse(state, action) {
      state.loading = false
      state.parent = action.payload
    },
  
    add(state, action) {
      state.loading = true
    },

    addResponse(state, action) {
      state.loading = false
    },

    update(state, action) {
        state.loading = true
    },

    updateResponse(state, action) {
        state.loading = false 
    },
  
    remove(state, action) {
        state.loading = true
    },

    removeResponse(state, action) {
        state.loading = false
    },
      
    error(state, action) {
        state.loading = false
        state.error = action.payload

        console.log(action.payload)
    }
  }
})

export const { 
    getAll, getAllResponse,
    getAllByType, getAllByTypeResponse, 
    get, getResponse,
    getParent, getParentResponse,
    add, addResponse,
    remove, removeResponse,
    update, updateResponse,
    error
} = paymentMethodSlice.actions;

export default paymentMethodSlice.reducer