import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container';

import * as uservice from '../../utilities/services/user-service';
import * as uactions from '../../utilities/redux/actions/user-slice';
import validator from '../user-validator';

const RequestPasswordPage = () => {
   
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [validations, setValidations] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const updateHandler = (e, set) => { 
        set(e.target.value);
    };

    const submitHandler = async (e) => { 
        try {
            // validation
            if (!validator.validation({type: validator.REQUEST_PASSWORD_CHANGE, parameters: {email}, setInputs: setValidations}))
                return;

            uservice.requestPassword({ email }, dispatch);
            navigate(`/login`);
        } catch (e) {
            // Handle errors if needed
        }
    };

    const cancelHandler = () => { 
        // go to login page
        navigate(`/login`);
    };
  
    return (
        <AnonymousFormContainer>
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5"> 
                    <h2 className="fs-5 fw-normal mb-4">{t('requestPasswordPage.title')}</h2>

                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">{t('requestPasswordPage.email')}</label>
                                    <input 
                                        type="email" 
                                        className={`form-control ${validations.email ? "is-invalid" : ""}`} 
                                        name="email" 
                                        id="email" 
                                        placeholder={t('requestPasswordPage.emailPlaceholder')} 
                                        value={email} 
                                        required 
                                        onChange={(e) => updateHandler(e, setEmail)} 
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-custom-secondary" type="button" onClick={cancelHandler}>{t('requestPasswordPage.cancel')}</button>
                                    </div>
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-custom-primary btn-custom-green" type="button" onClick={submitHandler}>{t('requestPasswordPage.submit')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AnonymousFormContainer>
    );
}

export default RequestPasswordPage;