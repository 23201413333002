import React, { useEffect, useState } from "react";
import _ from "lodash"
import { useTranslation } from 'react-i18next';
 
import TreeItemDetailPageChildren from "./tree-item-detail-page-children";
import * as tc from '../../../configurations/text-constants'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RelatedList from "../../../related-lists";

const TreeItemDetailPageRelatedList = ({element, configs, user}) => {
      
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(configs.relatedlist[0].name);
 
    useEffect(()=> {
        setActiveTab(configs.relatedlist[0].name)
    }, [element])

    return (
        <Tabs activeKey={activeTab} className="mb-3"  onSelect={t => setActiveTab(t)} > 
            {
                configs.relatedlist.map(rl => {
                    return (
                        <Tab key={rl.name} eventKey={rl.name} title={t(rl.label)}>
                            <div className="card">
                                <div className="card-body">
                                    <RelatedList user={user} target={element} name={rl.name}/>
                                </div>
                            </div>
                        </Tab>
                    )
                })
            }
            {           
                !element.is_leaf &&
                <Tab eventKey="children" title={t(tc.OBJECT_RELATED_LIST_ACCOUNT_DESCENDANTS)}>
                    <div className="card">
                        <div className="card-body">
                            <TreeItemDetailPageChildren element={element} configs={configs} user={user}/>
                        </div>
                    </div>
                </Tab>
            }
            
           

           
            
            
           
                
        </Tabs> 
    )
}

export default TreeItemDetailPageRelatedList