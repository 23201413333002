import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import expenseTemplateConfigs from '../../templates/expense-template/configs.js'

import TransactionExpenseReceivableModal from "./transaction-expense-receivable-modal";
import TransactionExpensePaymentModal from "./transaction-expense-payment-modal";


import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/transaction-expense-service' 
import * as sTemplate from '../../utilities/services/template-expense-service' 

import * as tc from "../../common/configurations/text-constants";


const ExpenseDetailPage = () => {
 
    const { id } = useParams()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const user = useContext(UserContext)
 
    const [showReceivableModal, setShowReceivableModal] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)

      
    configs.c_actions = [
        {
            label: tc.BUTTON_ADD_TEMPLATE_LABEL,
            handler: async (e, element) => {
                const response = await sTemplate.add({payload: element, user: user }, dispatch)
                if(response.success)
                    navigate(`/${expenseTemplateConfigs.object.basePath}/${response.data.id}/view`)
            }
        },
        {
            label: tc.BUTTON_REAL_ACCOUNT_RECEIVABLE_LABEL,
            handler: async () => setShowReceivableModal(true)
        },
        {
            label: tc.BUTTON_REAL_ACCOUNT_LIABILITY_PAYMENT_LABEL,
            handler: async () => setShowPaymentModal(true)
        }
    ]
 
    
   
    return (
        <DetailPageV2 service={s} configs={configs}>
            {
                showReceivableModal &&
                <TransactionExpenseReceivableModal
                    id={id}
                    service={s}
                    setShowModal={setShowReceivableModal}
                />
            }
            {
                showPaymentModal &&
                <TransactionExpensePaymentModal
                    id={id}
                    service={s}
                    setShowModal={setShowPaymentModal}
                />
            }
        </DetailPageV2>
    )
}

export default ExpenseDetailPage