import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import _ from "lodash"
import { useNavigate } from 'react-router-dom';

import * as s from '../../utilities/services/account-asset-service'
import BasicTree from '../../common/tree-layouts/basic-tree'
import configs from './configs'

const AccountAssetPage = () => {
  
    return (
        <BasicTree service={s} configs={configs}></BasicTree>
    )
}

export default AccountAssetPage