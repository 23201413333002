import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import TemplateEntryModal from "../_entry-modal";
import configs from './configs'
import configTemplates from '../../common/configurations'
import liabilityPaymentConfig from '../../transactions/transaction-liability-payment/configs'
import UserContext from '../../user/user-context';
import * as tc from "../../common/configurations/text-constants"
import * as c from "../../common/configurations/constants"

import * as s from '../../utilities/services/template-liability-payment-service' 
// import * as sTemplate from '../../utilities/services/template-expense-service' 

const elementTemplate = configTemplates.getObjectElement()
const modalConfigs = {

    layout: {

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        name: 'date',
                        type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                        label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                        isRequired: true
                        
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount_original',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT_ORIGINAL,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
        ]
    },
    object: {
        ...liabilityPaymentConfig.object
    }
}

const LiabilityPaymentTemplateDetailPage = () => {

    const { id } = useParams()
       
    configs.c_actions = [
        {
            label: tc.BUTTON_ADD_TEMPLATE_ENTRY_LABEL,
        }
    ]

    return (
        <DetailPageV2 service={s} configs={configs} >
            <TemplateEntryModal configs={configs} id={id} modalConfigs={modalConfigs} service={s}/>
        </DetailPageV2>
    )
}

export default LiabilityPaymentTemplateDetailPage