import React from "react";
import { Modal } from 'react-bootstrap'

import ChartShowPage from "../../../data/chart/chart-show-page";
import ReportShowPage from "../../../data/report/report-show-page";
import DashboardShowPage from "../../../data/dashboard/dashboard-show-page";

import StandardChartShowPage from "../../../data/chart-standard/chart-show-page";
import StandardReportShowPage from "../../../data/report-standard/report-show-page";
import StandardDashboardShowPage from "../../../data/dashboard-standard/dashboard-show-page";
 
const PrinterContainerModal = ({close, element, configs}) => {
     
    return (
        <Modal show={true} onHide={close} fullscreen={true} className="custom-modal">
            <Modal.Header>
                <button
                    type="button"
                    className="btn-close"
                    onClick={close}
                    style={{ marginLeft: 0 }}
                />
            </Modal.Header>
            <Modal.Body>
                {
                    configs.object.name == 'chart' &&
                    <ChartShowPage internalid={element.id}></ChartShowPage>
                }
                {
                    configs.object.name == 'report' &&
                    <ReportShowPage internalid={element.id}></ReportShowPage>
                }
                {
                    configs.object.name == 'dashboard' &&
                    <DashboardShowPage internalid={element.id} element={element}></DashboardShowPage>
                }
                {
                    configs.object.name == 'chart_standard' &&
                    <StandardChartShowPage internalid={element.id}></StandardChartShowPage>
                }
                {
                    configs.object.name == 'report_standard' &&
                    <StandardReportShowPage internalid={element.id}></StandardReportShowPage>
                }
                {
                    configs.object.name == 'dashboard_standard' &&
                    <StandardDashboardShowPage internalid={element.id} element={element}></StandardDashboardShowPage>
                }
                
            </Modal.Body>
        </Modal>
    )
}
 
export default PrinterContainerModal