import React from "react"
 
import ListHeader from "../../layouts/headers/list";

const BasicTreeHeader = ({configs}) => {
     
    return (
        <ListHeader configs={configs} >
 
        </ListHeader>
       
    )
}
export default BasicTreeHeader