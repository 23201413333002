import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
  
import _ from 'lodash'

import * as reportService from '../../../../utilities/services/report-service'
import UserContext from '../../../../user/user-context';
import ReportTypeEditorItem from "./report-type-editor-item";
import { updateResponse } from '../../../../utilities/redux/actions/report-type-slice';

const ReportTypeEditorContainer = ({configs}) => {
 
    const item = useSelector((state) => state.reportType.item)
      
    const user = useContext(UserContext)
    const dispatch = useDispatch()
    
    const fieldChangeHandler = (field, index) => {
 
        let nfields = [...item.fields]
        nfields[index] = field

        let nitem = {...item, fields: nfields}
 
        //  dispatch fields
        dispatch(updateResponse(nitem))
    }
  
    const getFields = () => {
        
        if(!item)
            return <tr><td colSpan="7"></td></tr>

        return item.fields.map((f, i) => {
 
            return (
                <ReportTypeEditorItem pfield={f} key={i} index={i} fieldChangeHandler={(field, index) => fieldChangeHandler(field, index)}/> 
            )
        })
    }
  
    return (
       <table className="table table-bordered">
            <thead>
                <tr>
                    <th>NOMBRE</th>
                    <th>ETIQUETA</th>
                    <th>TIPO</th>
                    <th>ACTIVADO</th>
                    <th>DEFECTO</th>
                    <th>D ORDEN</th>
                    <th>TOTAL</th>
                    <th>ULTIMA MODIFICACION</th>
                </tr>
            </thead>
            <tbody>
                {
                    getFields()
                }
            </tbody>
       </table>
    )
}
 
export default ReportTypeEditorContainer