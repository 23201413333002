import React from "react";
import ChartElement from "../../chart-element";

const ChartViewer = ({data, typeid}) => {
 
    return (
        <div style={{height:"60vh", position:"relative", marginBottom:"1%", padding:"1%"}}>
            <ChartElement typeid={typeid} options={data.options} formats={data.formats} data={data.data} />
        </div>
        
    )
}

export default ChartViewer