import React from "react";
import { useTranslation } from 'react-i18next';

import FilterPreviewItem from './filter-preview-item';

const FilterPreview = ({ filters, deleteItem, changeValueItem }) => {
  
    const { t } = useTranslation();
    
    return (
        <div className="card">
            <ul className="list-group list-group-flush">
                {
                    filters.map((f, i) => 
                        <FilterPreviewItem 
                            item={f} 
                            index={i} 
                            key={i} 
                            deleteItem={deleteItem} 
                            changeValueItem={changeValueItem} 
                        />
                    )
                }
                {
                    filters.length === 0 && 
                    <p className="p-2">{t('NoFiltersCreated')}</p> 
                    // 'NoFiltersCreated' sería la clave que uses en los archivos de traducción
                }
            </ul> 
        </div>
    );
}

export default FilterPreview;