import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from 'react-redux'
import Chart, { plugins } from 'chart.js/auto';
import { Line, Pie, Bar, Doughnut, PolarArea } from "react-chartjs-2";
import * as s from '../../../utilities/services/chart-type-service'
import format from './datatypes'

import UserContext from '../../../user/user-context';
 
const ChartElement = ({typeid, options, formats, data}) => {
   
    const [name, setName] = useState()
   
    const dispatch = useDispatch()
    const user = useContext(UserContext)

    const adjustChartOptions = () => {

        return {
            ...options,
            plugins: {
                ...options.plugins,
                tooltip: {
                    ...options.plugins.tooltip,
                    callbacks: {
                        label: function (item, data) {
                            const formattedValue =  format(item.raw, item.datasetIndex === 0? formats.v1: formats.v2, {})
                            return `${item.dataset.label}: ${formattedValue}`
                        },
                    }
                }
            }
        }
    }
 
    useEffect(()=> {

        const load = async () => {
            const response = await s.get({payload: {id: typeid}, user: user }, dispatch)
            if(response.success)
                setName(response.data.name)
        }

        load() 
    }, [typeid])
 
    if(name == 'Bar') // bar
        return <Bar options={adjustChartOptions()} data={data} />

    if(name == 'Pie') // pie
        return <Pie options={options} data={data} />

    if(name == 'Line') // line
        return <Line options={options} data={data} />

    if(name == 'Donut') // line
        return <Doughnut options={adjustChartOptions()} data={data} />

    if(name == 'Polar Area') // line
        return <PolarArea options={options} data={data} />

    return <div />
}

export default ChartElement