import React, { useEffect, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
  
import NewPageV2 from "../../common/layouts/pages/new-page/new-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/transaction-liability-payment-service'
import * as saccount from '../../utilities/services/account-liability-service'

const PassivePaymentNewPage = () => {
 
    const user = useContext(UserContext)

    const { accountid } = useParams()
    const [defaultAccount, setDefaultAccount] = useState()
     
    const dispatch = useDispatch()
 
    const [message, setMessage] = useState({})
 
    useEffect(() => {

        if(accountid) {
            const load = async () => {
                const response = await saccount.get({payload: {id: accountid}, user }, dispatch)
                if(response.success)
                    setDefaultAccount(response.data)
            }
            
            load()
        }
        
    }, [])
 
    return <NewPageV2 service={s} configs={configs} message={message} defaultItem={{}}/>
}

export default PassivePaymentNewPage