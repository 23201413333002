import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container';
import { useTranslation } from 'react-i18next';

import * as uservice from '../../utilities/services/user-service';
import * as uactions from '../../utilities/redux/actions/user-slice';
import validator from '../user-validator';
import './login-page.css';

const LoginForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const error = useSelector((state) => state.user.error);
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validations, setValidations] = useState({});
    const [rememberMe, setRememberMe] = useState(false);
    
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        // Validation
        if (!validator.validation({
            type: validator.LOGIN,
            parameters: { email, password },
            setInputs: setValidations,
        })) return;

        const response = await uservice.login(
            {
                username: email,
                password: password,
                rememberMe: rememberMe,
            },
            dispatch
        );

        if (response.success) navigate(`/activation`);
    };

    const updateHandler = async (e, set) => {
        // Clean error
        if (error) dispatch(uactions.error());
        set(e.target.value);
    };

    useEffect(() => {
        dispatch(uactions.error());
    }, [dispatch]);

    return (
        <AnonymousFormContainer>
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5">
                    <h2 className="fs-5 fw-normal mb-4">{t('loginComponent.title')}</h2> {/* Usar traducción */}
                    <div className="text-center mb-3"></div>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">{t('loginComponent.email')}</label> {/* Usar traducción */}
                                    <input
                                        type="email"
                                        className={`form-control ${validations.email ? 'is-invalid' : ''}`}
                                        name="email"
                                        id="email"
                                        placeholder={t('loginComponent.emailPlaceholder')}
                                        value={email}
                                        required
                                        onChange={(e) => updateHandler(e, setEmail)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">{t('loginComponent.password')}</label> {/* Usar traducción */}
                                    <input
                                        type="password"
                                        className={`form-control ${validations.password ? 'is-invalid' : ''}`}
                                        name="password"
                                        id="password"
                                        placeholder={t('loginComponent.passwordPlaceholder')}
                                        value={password}
                                        required
                                        onChange={(e) => updateHandler(e, setPassword)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex gap-2 justify-content-between">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={rememberMe}
                                            name="rememberMe"
                                            id="rememberMe"
                                            onChange={() => setRememberMe(!rememberMe)}
                                        />
                                        <label className="form-check-label text-secondary" htmlFor="rememberMe">
                                            {t('loginComponent.rememberMe')} {/* Usar traducción */}
                                        </label>
                                    </div>
                                    <Link to='/password/request' className="text-decoration-none">
                                        {t('loginComponent.forgotPassword')} {/* Usar traducción */}
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-grid my-3">
                                    <button
                                        className="btn btn-custom-primary"
                                        type="button"
                                        onClick={handleSubmit}
                                    >
                                        {t('loginComponent.submit')} {/* Usar traducción */}
                                    </button>
                                </div>
                            </div>
                            <div className="col-12">
                                <p className="m-0 text-secondary text-center">
                                    {t('loginComponent.noAccount')} <Link to='/signup'>{t('loginComponent.signup')}</Link> {/* Usar traducción */}
                                </p>
                            </div>
                        </div>
                    </form>

                    {error && (
                        <p className="m-0 text-danger">{error}</p>
                    )}
                </div>
            </div>
        </AnonymousFormContainer>
    )
}

export default LoginForm;