// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/report-type-slice';
 
const SERVICE_PATH = 'report-types';
 
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { getAll, get, add, update, remove, clone } = fns

const getNoLimitAll = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getNoLimitAll(sparams, params, dispatch)
    
}

const getMore = async (params, dispatch) => {

    const sparams = {
        path: SERVICE_PATH,
        actions: actions
    }

    return gservice.getMore(sparams, params, dispatch)
    
}

const getAllFields = async (params, dispatch) => {
 
    const sparams = {
        path: `${SERVICE_PATH}/metadata/${params.payload.id}`,
        action: actions.update,
        actionResponse: actions.updateResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getAllActivedFields = async (params, dispatch) => {
 
    const sparams = {
        path: `${SERVICE_PATH}/metadata/${params.payload.id}/fields/actived`,
        action: actions.update,
        actionResponse: actions.updateResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
 
const setup = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}`,
        action: actions.update,
        actionResponse: actions.updateResponse,
        actionError: actions.error
    }

    return sservice.patch(sparams, params, dispatch)
}


  
export { getNoLimitAll, getMore, getAllFields, getAllActivedFields, setup } 