import React from "react";
import { useTranslation } from 'react-i18next';

import SimpleHeader from "../../headers/simple/simple-header";

const NewPageHeader = ({configs}) => {

    const { t } = useTranslation();

    return (
        <SimpleHeader configs={configs} text={`${t("new-page-header.new_text")} ${t(configs.object.label)}`} >
            
        </SimpleHeader>
    )
}

export default NewPageHeader