import React from "react";

const FloatSuccessMessage = ({text, closeFn}) => {
  
    return (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
            {text}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={closeFn}></button>
        </div>
    )

    // A simple primary alert with <a href="#" className="alert-link">an example link</a>. Give it a click if you like.
}

export default FloatSuccessMessage;