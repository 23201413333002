// system imports
import * as sservice from './_standard-service'

const SERVICE_PATH = 'transactions';

const getAccountMonth = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/account/${params.payload.id}/month`
    }

    return sservice.get(sparams, params, dispatch)
}

const getVendorMonth = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/vendor/${params.payload.id}/month`
    }

    return sservice.get(sparams, params, dispatch)
 
}

const getAccountLimited = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/account/${params.payload.id}/limited/${params.limit}`
    }

    return sservice.get(sparams, params, dispatch)
}

const getVendorLimited = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/vendor/${params.payload.id}/limited/${params.limit}`
    }

    return sservice.get(sparams, params, dispatch)
}

const getBuyerLimited = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/buyer/${params.payload.id}/limited/${params.limit}`
    }

    return sservice.get(sparams, params, dispatch)
} 
  
export { getAccountLimited, getVendorLimited, getBuyerLimited, getAccountMonth, getVendorMonth }