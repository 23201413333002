import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux' 

import EditPage from "../../common/layouts/pages/edit-page/edit-page";
import EditPageV2 from "../../common/layouts/pages/edit-page/edit-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/template-income-service'

const TemplateEditPage = () => {

    return (
        <EditPageV2 service={s} configs={configs}>
        </EditPageV2>
    )
}

export default TemplateEditPage