import { Route } from "react-router-dom";

import AccountIncomePage from "../../accounts/account-income";
import AccountIncomeDetailPage from "../../accounts/account-income/account-income-detail-page";
import AccountIncomeEditPage from "../../accounts/account-income/account-income-edit-page";
import AccountIncomeNewChildPage from "../../accounts/account-income/account-income-new-child-page";

// maybe change write "setup" instead "config"
const routes =  
        <Route path="/account-incomes">
 
            <Route index element={<AccountIncomePage />} />
 
            <Route path=":id" >
                <Route path="newc"  element={<AccountIncomeNewChildPage />} />
                <Route path="view" element={<AccountIncomeDetailPage />} />
                <Route path="edit"  element={<AccountIncomeEditPage />} />
            </Route> 

        </Route> 
 
export default routes
