import React, { useEffect, useState, useContext, Fragment } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import EditPage from "../../common/layouts/pages/edit-page/edit-page";
import EditPageV2 from "../../common/layouts/pages/edit-page/edit-page-v2";

import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/transaction-asset-service'

const AssetEditPage = () => {

    return (
        <EditPageV2 service={s} configs={configs}>
        </EditPageV2>
    )
}

export default AssetEditPage