import React, { useEffect, useState, useContext, Fragment } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux' 
 
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/report-data-standard-service' 
import ReportTableViewer from "../../common/viewers/report-table-viewer";
import LoadingComponent from "../../common/layouts/statuses/loading-component";
import ReportTableViewerSize from "../../common/viewers/report-table-viewer-size";
// import EmptyData from "../common/empty-data/empty-data";

import * as c from '../../common/configurations/constants'
import TitleComponent from "../common/title/title";

const ReportShowPage = ({internalid, size}) => {
   
    const user = useContext(UserContext)

    const {id} = useParams()

    const [loading, setLoading] = useState(true)
    const [reportdata, setReportdata] = useState()

    const dispatch = useDispatch()
    
    useEffect(() => {

        const load = async () => {
            const response = await s.get({payload: {id: internalid? internalid: id}, user}, dispatch)
            if(response.success) setReportdata(response.data)

            setLoading(false)
        }

        load()

    }, [])

    const getReportViewer = () => {
   
        if(size)
            return <ReportTableViewerSize data={reportdata.data} size={size}/> 
            
        return ( 
            <ReportTableViewer data={reportdata.data} bottomMargin={50}/> 
        )
    }
  
    if(loading)
        return <LoadingComponent isText={true}/>

    if(!reportdata)
        return <div/>

    return (
        <div>
            <TitleComponent title={reportdata.name} />
            
            {
                getReportViewer()
            }
        </div>
    )
}

export default ReportShowPage