import React, { useState } from "react";
import {useSelector } from 'react-redux'

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs' 

import * as tc from '../../common/configurations/text-constants'
import * as s from '../../utilities/services/report-service'

import PrinterContainerModal from "../../common/layouts/modals/printer-container-modal";

const ReportDetailPage = () => {
  
    const [showModal, setShowModal] = useState(false)
    const item = useSelector((state)=> state[configs.object.sliceName].item)

    configs.c_actions = [
        {
            label: tc.BUTTON_PRINT_INFO_LABEL,
            handler: () => setShowModal(true)
        }
    ]

    return (
        <DetailPageV2 service={s} configs={configs} >
            {
                showModal &&
                <PrinterContainerModal close={()=>setShowModal(false)} element={item} configs={configs}/>
            }
        </DetailPageV2>
    )
}

export default ReportDetailPage