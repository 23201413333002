import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import _ from "lodash"
 
import * as s from '../../utilities/services/account-income-service'
import BasicTree from '../../common/tree-layouts/basic-tree'
import configs from './configs'

const AccountIncomePage = () => {
 
    return (
        <BasicTree service={s} configs={configs}></BasicTree>
    )
}

export default AccountIncomePage