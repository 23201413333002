import { Route } from "react-router-dom";
 
import TransactionAutomatedPage from "../../automations/transaction-automated-email/transaction-automated-page";
import TransactionAutomatedDetailPage from '../../automations/transaction-automated-email/transaction-automated-detail-page'; 

const routes =  
    <Route path="/transaction-automated-emails">
    
        <Route index element={<TransactionAutomatedPage />} />
 
        <Route path=":id" >
            <Route path="view" element={<TransactionAutomatedDetailPage />} />
        </Route>

    </Route> 

export default routes
