import React, { useState } from "react";
import { Modal, Table, Form, Button, Alert } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';
import LoadingComponent from "../../../common/layouts/statuses/loading-component";
import * as s from '../../../utilities/services/statement-service';

const UploadStatementModal = ({ item, close, user, configs }) => {
    const [transactions, setTransactions] = useState({ transactions: {}, totals: {}, dateRange: {} });
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState("");
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [isProcessingTransactions, setIsProcessingTransactions] = useState(false);
    const [selectedTransactions, setSelectedTransactions] = useState([]);
    const [createdTransactions, setCreatedTransactions] = useState([]);
    const [step, setStep] = useState('upload');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const MAX_FILE_SIZE = 50 * 1024;
        
        setFileError("");
        
        if (selectedFile) {
            if (selectedFile.size > MAX_FILE_SIZE) {
                setFileError("El archivo excede el tamaño máximo permitido de 50KB");
                setFile(null);
                e.target.value = null;
            } else {
                setFile(selectedFile);
            }
        }
    };

    const addUniqueIds = (transactions) => {
        return transactions?.map((transaction, index) => ({
            ...transaction,
            uniqueId: `${transaction.date}-${transaction.amount}-${transaction.location}-${index}`
        })) || [];
    };

    const handleTransactionSelect = (transaction, isChecked) => {
        if (isChecked) {
            setSelectedTransactions([...selectedTransactions, transaction]);
        } else {
            setSelectedTransactions(selectedTransactions.filter(t => t.uniqueId !== transaction.uniqueId));
        }
    };

    const handleUpload = async () => {
        if (!file) return;
        
        setIsLoadingFile(true);
        
        const params = {
            payload: file,
            item: item,
            user: user
        };

        try {
            const response = await s.recoveryCreditCard(params);
            if(response.success) {
                const processedData = {
                    ...response.data,
                    transactions: {
                        expenses: addUniqueIds(response.data.transactions.expenses),
                        interests: addUniqueIds(response.data.transactions.interests),
                        bankCharges: addUniqueIds(response.data.transactions.bankCharges)
                    }
                };
                setTransactions(processedData);
                setStep('select');
            } else {
                setFileError("Error al procesar el archivo. Por favor, revise que el archivo e intente nuevamente.");
            }
        } catch(e) {
            console.error(e);
            setFileError("Error al procesar el archivo. Por favor, intente nuevamente.");
        } finally {
            setIsLoadingFile(false);
        }
    };

    const handleProcessTransactions = async () => {
        if (selectedTransactions.length === 0) return;

        setIsProcessingTransactions(true);

        try {
            const transactionsToProcess = selectedTransactions.map(({ uniqueId, ...transaction }) => transaction);
            const response = await s.createTransacctionsCreditCard({
                payload: transactionsToProcess,
                item: item,
                user: user
            });

            if (response.success) {
                setCreatedTransactions(response.data);
                setStep('complete');
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsProcessingTransactions(false);
        }
    };

    const renderTransactionTable = (transactions, title) => {
        if (!transactions || transactions.length === 0) return null;

        return (
            <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="fw-bold m-0">{title}</h5>
                </div>
                <Table bordered hover>
                    <thead>
                        <tr className="table-secondary">
                            <th width="10%">Seleccionar</th>
                            <th width="25%">Fecha</th>
                            <th width="25%">Monto</th>
                            <th width="40%">Ubicación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction) => (
                            <tr key={transaction.uniqueId}>
                                <td className="text-center">
                                    {transaction.exists ? (
                                        <Icon path={mdiCheckCircleOutline} color="green" size={1}/>
                                    ) : (
                                        <Form.Check 
                                            type="checkbox"
                                            onChange={(e) => handleTransactionSelect(transaction, e.target.checked)}
                                            checked={selectedTransactions.some(t => t.uniqueId === transaction.uniqueId)}
                                        />
                                    )}
                                </td>
                                <td>{transaction.date}</td>
                                <td className="text-end">${transaction.amount.toFixed(2)}</td>
                                <td>{transaction.location || "N/A"}</td>
                            </tr>
                        ))}
                        <tr className="table-">
                            <td colSpan="2" className="text-end fw-bold">Total:</td>
                            <td className="text-end fw-bold">
                                ${transactions.reduce((sum, t) => sum + t.amount, 0).toFixed(2)}
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    };

    const renderCreatedTransactionsTable = () => {
        if (!createdTransactions || createdTransactions.length === 0) return null;

        const total = createdTransactions.reduce((sum, t) => sum + t.amount, 0);

        return (
            <div className="mb-4">
                <Table bordered hover>
                    <thead>
                        <tr className="table-secondary">
                            <th width="30%">Nombre</th>
                            <th width="20%">Fecha</th>
                            <th width="25%">Monto</th>
                            <th width="25%">Categoría</th>
                        </tr>
                    </thead>
                    <tbody>
                        {createdTransactions.map((transaction, index) => (
                            <tr key={index}>
                                <td>{transaction.name}</td>
                                <td>{transaction.date}</td>
                                <td className="text-end">${transaction.amount.toFixed(2)}</td>
                                <td>{transaction.expense || "N/A"}</td>
                            </tr>
                        ))}
                        <tr className="table-">
                            <td colSpan="2" className="text-end fw-bold">Total:</td>
                            <td className="text-end fw-bold">${total.toFixed(2)}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    };

    const renderContent = () => {
        if (isLoadingFile || isProcessingTransactions) {
            return (
                <div className="p-4">
                    <LoadingComponent configs={configs} isText={true} />
                </div>
            );
        }

        switch (step) {
            case 'upload':
                return (
                    <div className="upload-section p-4">
                        <Form.Group controlId="statement" className="mb-4">
                            <Form.Label className="fw-bold">Subir estado de cuenta (TXT)</Form.Label>
                            <Form.Control type="file" accept=".txt" onChange={handleFileChange} />
                            <Form.Text className="text-muted">
                                Tamaño máximo permitido: 50KB
                            </Form.Text>
                        </Form.Group>
                        {fileError && (
                            <Alert variant="danger" className="mb-3">
                                {fileError}
                            </Alert>
                        )}
                        <div className="d-flex justify-content-start">
                            <Button 
                                className="btn-success" 
                                onClick={handleUpload} 
                                disabled={!file}
                            >
                                Cargar y Obtener Transacciones
                            </Button>
                        </div>
                    </div>
                );

            case 'select':
                const { transactions: { expenses, interests, bankCharges }, totals } = transactions;
                return (
                    <div className="p-4">
                        <h5 className="mb-4 fw-bold">Seleccione las transacciones a procesar</h5>
                        
                        {renderTransactionTable(expenses, 'Gastos')}
                        {renderTransactionTable(interests, 'Intereses')}
                        {renderTransactionTable(bankCharges, 'Cargos Bancarios')}

                        {(expenses?.length > 0 || interests?.length > 0 || bankCharges?.length > 0) && totals?.total && (
                            <div className="alert alert-secondary">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span className="fw-bold">Total General:</span>
                                    <span className="fw-bold">${totals.total.toFixed(2)}</span>
                                </div>
                            </div>
                        )}

                        <div className="d-flex justify-content-start mt-4">
                            <Button 
                                className="btn-success" 
                                onClick={handleProcessTransactions}
                                disabled={selectedTransactions.length === 0}
                            >
                                Procesar Transacciones Seleccionadas
                            </Button>
                        </div>
                    </div>
                );

            case 'complete':
                return (
                    <div className="p-4">
                        <div className="alert alert-success mb-4">
                            <i className="bi bi-check-circle me-2"></i>
                            Las transacciones han sido procesadas exitosamente
                        </div>
                        <h5 className="mb-4 fw-bold">Transacciones creadas:</h5>
                        {renderCreatedTransactionsTable()}
                        <div className="d-flex justify-content-start">
                            <Button 
                                className="btn-secondary" 
                                onClick={close}
                            >
                                Cerrar
                            </Button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal show={true} onHide={close} fullscreen={true} className="custom-modal">
            <Modal.Header className="border-bottom">
                <button
                    type="button"
                    className="btn-close"
                    onClick={close}
                    style={{ marginLeft: 0 }}
                />
            </Modal.Header>
            <Modal.Body className="bg-light">
                {renderContent()}
            </Modal.Body>
        </Modal>
    );
};

export default UploadStatementModal;