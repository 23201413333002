const getConfigurations = () => {

    return {

        actions: {
            edit: {
                enable: false,
                handler: function() { console.log('edit')}
            },
            clone: {
                enable: false,
                handler: function() { console.log('clone')}
            },
            add: {
                enable: false,
                handler: function() { console.log('add')}
            },
            addNew: {
                enable: false,
                handler: function() { console.log('add')}
            },
            setup: {
                enable: false,
                handler: function() { console.log('setup')}
            },
            remove: {
                enable: false,
                handler: function() { console.log('remove')}
            },
            search: {
                enable: false,
                handler: function() { console.log('search')}
            },
            
            listviews: {
                enable: true,
                handler: function() { console.log('listviews')}
            }
        },

        c_actions: [],
    
        layout: {
    
            sections: [
    
            ],
    
            boostrapIcon: 'house',
            color: '#555555',
            enableQuickActions: true
        },
    
        object: {
            name: 'object',
            label: 'object',
            pluralLabel: 'objects',
            basePath: 'objects',
            menuLabel: 'objects',
            transactionPath: 'transaction-path'      
        },
    
        list: {
            items: []
        } 
    }
}

const getObjectElement = () => {

    return {
        name: '-',
        type: '-',
        label: '-',
        values: [],
        isRequired: false,
        isVisible: true,
        isCreatable: true,
        isEditable: true
    }
}

const getObjectSection = () => {

    return {
        type: 'data',
        ncols: 2,
        isVisibleByField: 'is_leaf',
        elements: []
    }
}
 

const configs = {getConfigurations, getObjectElement, getObjectSection}
export default configs