import React, { useEffect } from "react"
import PropTypes from 'prop-types';
import _ from 'underscore'

import TableListBody from "../table/table-list-body";
import TableListCompactBody from "../table/table-list-compact-body";

import TableListHeader from "./table-list-header";

import TableListSearch from "../table/table-list-search";

import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link } from "react-router-dom";

const FixedTableList = ({ items, configs, loadingMore, user }) => {
        
    useEffect(() => {
 
    }, []);
       
    return (
        <div className="vstack gap-2">
 
            <div className="" >
                <div className="container-fluid d-none d-md-block">
                    <TableListHeader configs={configs} removeScroll={true} user={user}/>
                </div>
               
                 {/* normal layout */}
                 <div className="container-fluid overflow-scroll table-scroll-removed d-none d-md-block" style={{maxHeight: '400px'}}>
                    <TableListBody items={items} configs={configs} loadingMore={loadingMore}/>
                </div>
                {/* compact layout */}
                <div className="container-fluid overflow-scroll table-scroll-removed d-block d-md-none" style={{maxHeight: '400px'}}>
                    <TableListCompactBody items={items} configs={configs} loadingMore={loadingMore}/>
                </div>

            </div>
        </div>
    )
}

FixedTableList.propTypes = {
    items: PropTypes.array.isRequired,
    configs: PropTypes.shape({
        list: PropTypes.shape({
            items: PropTypes.array.isRequired
        })
    })
}

export default FixedTableList