import React, { Fragment } from "react";
import PropTypes from 'prop-types';
 
import TableListItem from "./table-list-item";
import TableListCompactItem from "./table-list-compact-item";

import EmptyList from "../../statuses/empty-list";
import LoadingList from "../../statuses/loading-list";

const TableListBody = ({ items, configs, loadingMore, printElement }) => {
     
    if(loadingMore || items && items.length > 0)
        return (
            <Fragment>
                 
                {/* compact list  */}
                <table className="table"> 
                    <tbody>
                        {   
                            items.map((e, i) => { 
                                return (
                                    <TableListCompactItem element={e} configs={configs} key={i} print={printElement} />
                                )
                            })
                        }
                        {
                            loadingMore && <LoadingList configs={configs} isText={false}/>
                        }
                        
                    </tbody>
                </table>
                <div style={{paddingBottom: '25px'}}></div>
            </Fragment>
        )
         
    // empty status 
    if(!items || items.length === 0)
        return <EmptyList configs={configs}/>
}

TableListBody.propTypes = {
    configs: PropTypes.object.isRequired
}

export default TableListBody



