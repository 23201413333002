import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/transaction-automated-email-service' 
import * as tc from '../../common/configurations/text-constants'

const TransactionAutomatedDetailPage = () => {
 
    const user = useContext(UserContext)
 
    const navigate = useNavigate()
    const dispatch = useDispatch()
  
    const refurbishHandler = async (e, element) => {
        await s.refurbish({payload: {id: element.id}, user: user }, dispatch)
        navigate(0)
    }

    const noappliedHandler = async (e, element) => {
        await s.noapplied({payload: {id: element.id}, user: user }, dispatch)
        navigate(0)
    }

    configs.c_actions = [
        {
            label: tc.BUTTON_REFURBISH_LABEL,
            handler: refurbishHandler
        },
        {
            label: tc.BUTTON_NO_APPLIED_LABEL,
            handler: noappliedHandler
        }
    ]
    
    return (
        <DetailPageV2 service={s} configs={configs}/>
    )
}

export default TransactionAutomatedDetailPage