import { Route } from "react-router-dom";
   
import HomePage from '../../user/home-page' 

const routes =  
    <Route path="/" >
       
        <Route index element={<HomePage />} />

        <Route path="home" element={<HomePage />} />

    </Route> 

export default routes
