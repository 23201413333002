import React, { useEffect, useState }from "react";
import { useTranslation } from 'react-i18next'; // Import i18n hook
 
import './simple-header.css'
import MaterialIcon, { typePath } from "../../../icons/material-icon";

  
const SimpleHeader = ({configs, text, children}) => {

    const { t } = useTranslation();

    const [fixed, setFixed] = useState(false);

    const scrollChanged = () => {
        if((window.scrollY > 0) !== fixed)
            setFixed(!fixed)
    }

    useEffect(()=> {
        window.addEventListener("scroll", scrollChanged)
    })
     
    const typeInfo = typePath(configs.object.name)

    return (
        <div className={`shadow simple-header bg-body rounded ${fixed?"fixed-simple-header": "before-fixed-simple-header"}`}>
            <div className="card">
                <div className="card-body hstack gap-2" style={{borderBottom: `5px solid ${typeInfo.tcolor}`, borderRadius: '5px'}}>
                    <div className="hstack gap-2">
                        <MaterialIcon obj={configs.object.name} isheader={true}/>
                        <span className="fw-bold text-uppercase d-none d-md-block">{t(configs.object.label)}</span>
                        <span className="fw-light">{text}</span>
                    </div>

                    <div className="ms-auto">
                        {children}
                    </div>
                </div> 
                
            </div>
        </div>
    )
}

export default SimpleHeader