import { Route } from "react-router-dom";
 
import PaymentContainer from "../../user/payments/payment-container";
import PayUResponseComponent from "../../user/payments/payment-response";

// maybe change write "setup" instead "config"
const routes =  
        <Route path="/payment">
 
            <Route index element={<PaymentContainer />} />

            <Route path="payment-response" element={<PayUResponseComponent />} />
            

        </Route> 
 
export default routes
