import React, { Fragment } from "react";
import SimplePageHeader from "./simple-page-header";
import SimplePageBody from "./simple-page-body";
 
const SimplePage = ({children, configs}) => {
    return (
        <Fragment>
            <SimplePageHeader configs={configs} />
            <SimplePageBody body={children} configs={configs} />
        </Fragment>
    )
}

export default SimplePage