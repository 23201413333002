import React, { useEffect, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
 
import ReportTableEditor from "./report-table-editor";
import * as reportService from '../../../../utilities/services/report-service'
import UserContext from '../../../../user/user-context';
 

const ReportEditorContainer = ({setupHandler, configs}) => {
 
    const item = useSelector((state) => state.report.item)
     
    const user = useContext(UserContext)
     
    if(!item)
        return <div />
 
    return (
        <ReportTableEditor report={item} user={user}/>
    )
}
 
export default ReportEditorContainer