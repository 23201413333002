import React from "react"

import * as tc from '../../configurations/text-constants'

const LoadingList = ({configs, isText}) => {

    return (
        <tr className="row">
            <td className="text-center" colSpan={configs.list.items.length}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="spinner-grow" role="status">
                    </div>
                    {
                        isText &&
                        <strong>Cargando...</strong>
                    }
                </div>
            </td>
        </tr>
    )
}

export default LoadingList