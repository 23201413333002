import React, { useEffect, useState } from "react"
import { useDispatch } from 'react-redux'

import DashboardSectionItem from "./dashboard-section-item"
import DashboardSectionItemSelector from "./dashboard-section-item-selector"
 
import { refreshSections } from '../../../utilities/redux/actions/dashboard-slice'

const TemplateOne = ({user, section, readonly}) => {
    
    const [list, setList] = useState([])
  
    const dispatch = useDispatch()

    const remove = (index) => { 

        const nlist = [...list]
        nlist.splice(index, 1)
        setList(nlist)

        // refresh sections
        refresh(nlist)
    }

    const addNew = (id) => {
        const nlist = [...list, id]
        setList(nlist)
        
        // refresh sections
        refresh(nlist)
    }

    const refresh = (newlist) => {
        dispatch(refreshSections([...newlist]))
    }

    useEffect(() => {
        setList(section? section: [])
    }, [section])

    return (
        <div className="row">
            <div className="col-12">
                {
                    list && list.map((c, index) => {
                        return (
                            <DashboardSectionItem user={user} readonly={readonly} id={c} index={index} remove={() => remove(index)} key={index}/>
                        )
                    })
                }
 
                {
                    !readonly &&
                    <DashboardSectionItemSelector user={user} add={addNew} />
                }
            </div>
        </div>
    )
}

export default TemplateOne