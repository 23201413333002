// system imports
import axios from "axios";
import * as uactions from '../redux/actions/user-slice';
import * as shelper from './helper'

import * as gservice from './_generic-service'
import * as sservice from './_standard-service'
 
const SERVICE_PATH = 'user-tools';
 
const add = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/payment`
    }

    return sservice.post(sparams, params, dispatch) 
}

const info = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/info`
    }

    return sservice.get(sparams, params, dispatch)
}
  
export { add, info }