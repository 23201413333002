
// email, password, repassword
const NEW_USER = 'nu'
const REQUEST_PASSWORD_CHANGE = 'rpc'
const PASSWORD_CHANGE = 'pc'
const LOGIN = 'l'

const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

const validateType = (type, list) => {
    return list.includes(type)
}

const validation = ({ type, parameters, setInputs}) => {
    
    if(validateType(type, [NEW_USER, LOGIN, REQUEST_PASSWORD_CHANGE]) && (!parameters.email || !validateEmail(parameters.email))) {
        setInputs({ email: true })
        return false
    }

    if(validateType(type, [NEW_USER, LOGIN, PASSWORD_CHANGE]) && !parameters.password) {
        setInputs({ password: true })
        return false
    }

    if(validateType(type, [NEW_USER, PASSWORD_CHANGE]) && !parameters.repassword) {
        setInputs({ repassword: true })
        return false
    }

    if(validateType(type, [NEW_USER, PASSWORD_CHANGE]) && parameters.password != parameters.repassword) {
        setInputs({ password: true, repassword: true })
        return false
    }
    
    return true
}
 


export default {
    NEW_USER,
    REQUEST_PASSWORD_CHANGE,
    PASSWORD_CHANGE,
    LOGIN,
    validation
}

