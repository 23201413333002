// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/ticket-message-slice';
 
const SERVICE_PATH = 'support-ticket-messages';
  
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
 
export const { get, add, remove } = fns

const getAll = async (params, dispatch) => {
 
    const sparams = {
        path: `${SERVICE_PATH}/ticket/${params.payload.id}`,
        action: actions.getAll,
        actionResponse: actions.getAllResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
  
export { getAll }