import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"

import incomeAccountConfig from "../../accounts/account-income/configs"
import tIncomeTemplateConfig from "../../templates/income-template/configs"


const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,

        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'code',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_BUYER_CODE,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'income_id',
                        label: tc.OBJECT_FIELD_BUYER_INCOME,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_INCOME_LEAF_ACCOUNT,
                        isSelectorLinked: true, //incomeAccountConfig.object.basePath,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        name: 'transaction_income_template_id',
                        label: tc.OBJECT_FIELD_BUYER_TRANSACTION_INCOME_TEMPLATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_SELECTOR,
                        context: c.SELECTOR_CONTEXT_INCOME_TEMPLATE,
                        isSelectorLinked: true, //tIncomeTemplateConfig.object.basePath,
                        isRequired: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    { 
                        ...elementTemplate,
                        name: 'balance_day',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_BUYER_BALANCE_TODAY,
                        isEditable: false,
                        isCreatable: false,
                    },
                    { 
                        ...elementTemplate,
                        name: 'balance_month',
                        type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                        label: tc.OBJECT_FIELD_BUYER_BALANCE_MONTH,
                        isEditable: false,
                        isCreatable: false,
                    }
                ]
            },
            {
                type: 'separator_space'
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            }
        ],

        boostrapIcon: 'bi-people'
    },

    object: {
        name: 'buyer',
        label: tc.OBJECT_BUYER_LABEL,
        pluralLabel: tc.OBJECT_BUYER_PLURAL_LABEL,
        basePath: 'buyers',
        sliceName: 'buyer',
        menuLabel: tc.NAV_MENU_THIRD_PARTY_BUYER_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'code',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_BUYER_CODE,
                isLinked: false
            },
            {
                name: 'income',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_BUYER_INCOME,
                compactViewRightDetail: true,
                isLinked: true
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    },
    relatedlist: [
        {
            label: tc.OBJECT_RELATED_LIST_BUYER_LAST_TRANSACTIONS,
            name: 'transaction-buyer'
        }
    ]
}
 
export default configs