import React, { useEffect, useState, useContext } from "react";
 
import NewPage from "../../common/layouts/pages/new-page";
import NewPageV2 from "../../common/layouts/pages/new-page/new-page-v2";

import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/template-income-service' 

const TemplateNewPage = () => {
  
    const [message, setMessage] = useState({})
   
    return <NewPageV2 service={s} configs={configs} message={message} />
  
}

export default TemplateNewPage