import React, { Fragment, useEffect, useContext } from "react"; 
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import _ from "lodash"
import { useTranslation } from 'react-i18next';

import TreeItemDetailPageHeader from "./tree-item-detail-page-header";
import TreeItemDetailPageBody from "./tree-item-detail-page-body";
 
import UserContext from '../../../../user/user-context';

import NotAvailableComponent from "../../../layouts/statuses/not-available-component";
import LoadingComponent from "../../../layouts/statuses/loading-component";


import './tree-item-detail-page'
import QuickLinksSection from "../../../layouts/quick-actions/quick-links-section/quick-links-section";
 

const TreeItemDetailPage = ({service, configs}) => {
  
    const { id } = useParams()
    const { t } = useTranslation();

    const user = useContext(UserContext)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const item = useSelector((state)=> state[configs.object.sliceName].item) 
    const loading = useSelector((state)=> state[configs.object.sliceName].loading)

    document.title = `${item? item.name: t(configs.object.label)}`
    
    configs.actions.remove.handler = async (id) => {
        await service.remove({payload: {id}, user: user }, dispatch)
        navigate(`/${configs.object.basePath}`)
    }

    configs.actions.move.handler = async (targetid) => {
        await service.update({payload: {...item, parent_id: targetid}, user: user }, dispatch)
        navigate(`/${configs.object.basePath}`)
    }
 
    useEffect(()=> {
        service.get({payload: {id}, user}, dispatch)
    }, [id])


    if(loading)
        return <LoadingComponent configs={configs} isText={true}/>

    if(!item)
        return <NotAvailableComponent configs={configs} />

    const links = []
    if(item.parent_id)
        links.push({url: `${item.parent_id}`, text: item.parent_name})
     
    return (
        <Fragment>

            <TreeItemDetailPageHeader element={item} configs={configs} user={user}/>
             
            <QuickLinksSection configs={configs} links={links} />
 
            <TreeItemDetailPageBody element={item} configs={configs} user={user}/>
            
        </Fragment>
        
    )
}

export default TreeItemDetailPage