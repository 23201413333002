import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

//import { ColumnSelector, FilterOperatorSelector } from "../../report-selectors";
import FilterOperatorSelector from "../../../../../common/selectors/filter-operator-selector";
import ReportTypeFieldSelector from "../../../../../common/selectors/report-type-field-selector";
import { refreshFilters, refreshFiltersLogic, refreshFiltersLogicExpression } from '../../../../../utilities/redux/actions/report-slice';
import FilterPreview from "./filter-preview";

import * as tc from '../../../../../common/configurations/text-constants'


const ReportTableEditorPropertiesFilters = ({report, user, sourceColumns}) => {
     
    const { t } = useTranslation();

    const [filters, setFilters] = useState([...report.filters])
    const [filterStates, setFilterStates] = useState([])
    const [filtersLogic, setFiltersLogic] = useState(report.filters_logic)
    const [filtersLogicExpression, setFiltersLogicExpression] = useState(report.filters_logic_expression? report.filters_logic_expression: '')
    
    const dispatch = useDispatch()

    const onAddFilter = (filter) => {
         
        const tfilters = [...filters, filter]
        
        updateFilters(tfilters)
    }

    const deleteFilter = (index) => {
    
        let tfilters = filters.slice()
        tfilters.splice(index, 1)

        updateFilters(tfilters)
    }
  
    const changeValueFilter = (index, item) => {
         
        let nfilters = [...filters]
 
        nfilters[index] = item
 
        setFilterStates([...filterStates])
        updateFilters([...nfilters])
    }
 

    const updateFilters = (filters) => {
        setFilters(filters)
        dispatch(refreshFilters(filters))
    }
 
    const updateFiltersLogic = (e) => {
        setFiltersLogic(e.target.value)
        dispatch(refreshFiltersLogic(e.target.value))
    }

    const updateFiltersLogicExpression = (e) => {
        setFiltersLogicExpression(e.target.value)
        dispatch(refreshFiltersLogicExpression(e.target.value))
    }

    const FilterForm = ({onAddFilter, user}) => {
 
        const [column, setColumn] = useState({})
        const [operator, setOperator] = useState('')
        const [value, setValue] = useState('')
        const [addNewFilter, setAddNewFilter] = useState(false)
   
        const add = () => {
            
            onAddFilter({column, operator, value})

            setColumn(undefined)
            setOperator(undefined)
            setValue(undefined)
        }

        const disableButton = () => {
            return ! (column.id && operator != '')
        }
 
        return (
            <Fragment>
                {
                    <p>
                        <a href="#" className={`${addNewFilter?"link-secondary": "link-primary"}`} onClick={() => setAddNewFilter(true)}>{t("report-table-editor.add-new-filter")}</a>
                    </p>
                }
                <Modal show={addNewFilter} onHide={()=> setAddNewFilter(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title as="div">{t('report-table-editor.add-filter')}</Modal.Title>
                    </Modal.Header>        
                    <Modal.Body>
                        <div className="form-group vstack gap-3">
                            <div>
                                <label>{t('report-table-editor.column')}Columna</label>
                                <ReportTypeFieldSelector onChange={(v)=> { setColumn({id: v.value, label: v.label, type: v.type})}} typeId={report.source_id} user={user} value={column}/>
                            </div>
                            <div>
                                <label>{t('report-table-editor.operator')}Operador</label>
                                <FilterOperatorSelector onChange={(v)=> setOperator({id: v.value, label: v.label})}  value={operator} type={column && column.type}/>

                            </div>
                            <div>
                                <label>{t('report-table-editor.value')}Valor</label>
                                <input type="text" className="form-control" onChange={(e)=>setValue(e.target.value)} />
                                
                            </div>
                        </div>
                    </Modal.Body>
                
                    <Modal.Footer>
                        <button className="btn btn-custom-secondary btn-sm" onClick={()=> setAddNewFilter(false)}>{t(tc.BUTTON_CANCEL_LABEL)}</button>
                        <button type="button" className="btn btn-custom-delete btn-sm" variant="primary" onClick={add} disabled={disableButton()}>{t('report-table-editor.add-filter')}</button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
 
    const FilterLogic = ({}) => {
        
        return (
            <div>
                {t('report-table-editor.filter-logic')}
                <select className="form-control" value={filtersLogic} onChange={updateFiltersLogic}>
                    <option value="and">AND</option>
                    <option value="or">OR</option>
                    <option value="c">{t('report-table-editor.filter-logic-custom')}</option>
                </select>
                {
                    filtersLogic === 'c' &&
                    <div>
                        {t('report-table-editor.filter-logic-expression')}
                        <input type="text" className="form-control" autoFocus="autoFocus" value={filtersLogicExpression} onChange={updateFiltersLogicExpression} />
                    </div>
                }
                
                
            </div>
        )  
    }

    return (
        <div className="vstack gap-3">
            
            <FilterPreview filters={filters} deleteItem={deleteFilter} changeValueItem={changeValueFilter}/>
 
            <FilterLogic />

            <hr />

            <FilterForm onAddFilter={onAddFilter} user={user}/>
        </div>
    )
}

export default ReportTableEditorPropertiesFilters