import { Route } from "react-router-dom";

import ExpenseTemplatePage from "../../templates/expense-template";
import ExpenseTemplateDetailPage from '../../templates/expense-template/expense-template-detail-page';
import ExpenseTemplateEditPage from '../../templates/expense-template/expense-template-edit-page';
import ExpenseTemplateClonePage from '../../templates/expense-template/expense-template-clone-page';
import ExpenseTemplateNewPage from '../../templates/expense-template/expense-template-new-page';
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/expense-templates">
 
            <Route index element={<ExpenseTemplatePage />} />
 
            <Route path="new" element={<ExpenseTemplateNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<ExpenseTemplateDetailPage />} />
                <Route path="edit"  element={<ExpenseTemplateEditPage />} />
                <Route path="clone"  element={<ExpenseTemplateClonePage />} />
            </Route>

        </Route> 
 
export default routes
