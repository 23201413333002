import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import SetupPage from "../../../common/layouts/pages/setup-page";
import configs from '../configs'
import UserContext from '../../../user/user-context';
import ReportEditorContainer from './report-editor'
 
import * as s from '../../../utilities/services/report-service'

const ReportSetupPage = () => {
  
    return (
        <SetupPage service={s} configs={configs} >
            <ReportEditorContainer />
        </SetupPage>
    )
}

export default ReportSetupPage