// system imports
import axios from "axios";
import * as shelper from './helper'
import * as gservice from './_generic-service'

const API_URL = shelper.serverDomain();

const get = async (sparams, params, dispatch) => {

    try {
         
        if(sparams.action)
            dispatch(sparams.action(params));
  
        const url = `${API_URL}/${sparams.path}${shelper.querystring(params.payload)}`
        const options = shelper.axiosBearerConfig(params)
       
        const response = await axios.get(url, options);
         
        return gservice.getResponse(response, sparams.actionResponse, dispatch) 
 
    }
    catch(e) {
        return gservice.getError(e, sparams.actionError, dispatch)
    }
    
}
 
const post = async (sparams, params, dispatch) => {

    try {
        
        if(sparams.action)
            dispatch(sparams.action(params));

        const url = `${API_URL}/${sparams.path}`
        const options = shelper.axiosBearerConfig(params)
        
        const xparams = params.payload
 
        const response = await axios.post(url, xparams, options);
   
        return gservice.getResponse(response, sparams.actionResponse, dispatch) 
 
    }
    catch(e) {
        return gservice.getError(e, sparams.actionError, dispatch)
    }
    
}

const put = async (sparams, params, dispatch) => {

    try {
        
        if(sparams.action)
            dispatch(sparams.action(params));

        const url = `${API_URL}/${sparams.path}`
        const options = shelper.axiosBearerConfig(params)
        
        const xparams = params.payload
 
        const response = await axios.put(url, xparams, options);

        return gservice.getResponse(response, sparams.actionResponse, dispatch) 
 
    }
    catch(e) {
        return gservice.getError(e, sparams.actionError, dispatch)
    }
    
}

const patch = async (sparams, params, dispatch) => {

    try {
        
        if(sparams.action)
            dispatch(sparams.action(params));

        const url = `${API_URL}/${sparams.path}`
        const options = shelper.axiosBearerConfig(params)
         
        const xparams = params.payload
 
        const response = await axios.patch(url, xparams, options);
 
       return gservice.getResponse(response, sparams.actionResponse, dispatch) 
 
    }
    catch(e) {
        return gservice.getError(e, sparams.actionError, dispatch)
    }
    
}

const del = async (sparams, params, dispatch) => {

    try {
        
        if(sparams.action)
            dispatch(sparams.action(params));

        const url = `${API_URL}/${sparams.path}${params.payload.id}`
        const options = shelper.axiosBearerConfig(params)
         
        const response = await axios.delete(url, options);
 
        return gservice.getResponse(response, sparams.actionResponse, dispatch) 
 
    }
    catch(e) {
        return gservice.getError(e, sparams.actionError, dispatch)
    }
    
}

export { get, post, put, patch, del }