import React from "react";
import TransactionAccountRelatedList from "./transaction-account-related-list";
import TransactionVendorRelatedList from "./transaction-vendor-related-list";
import TransactionBuyerRelatedList from "./transaction-buyer-related-list";
import CalendarTransactionAccountRelatedList from "./calendar-transaction-account-related-list"
import ReceiptRelatedList from "./transaction-receipt-related-list";

const RelatedList = ({user, target, parameters, name}) => {
   
    if(name === 'transaction-account')
        return <TransactionAccountRelatedList account={target} user={user} />

    if(name === 'transaction-vendor')
        return <TransactionVendorRelatedList vendor={target} user={user} />

    if(name === 'transaction-buyer')
        return <TransactionBuyerRelatedList buyer={target} user={user} />

    if(name === 'calendar-transaction-account')
        return <CalendarTransactionAccountRelatedList account={target} user={user} />

    if(name === 'transaction-receipt')
        return <ReceiptRelatedList transaction={target} parameters={parameters} user={user} />

    if(name === 'support-ticket-message')
        return <ReceiptRelatedList ticket={target} parameters={parameters} user={user} />

    return <div />
}

export default RelatedList