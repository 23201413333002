import React from "react";

import SimpleHeader from "../../headers/simple/simple-header";

const SetupPageHeader = ({element, configs}) => {
 
    return (
        <SimpleHeader configs={configs} text={element.name} >
            
        </SimpleHeader>
        
    )
}

export default SetupPageHeader