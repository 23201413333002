import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Icon } from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { mdiRobotExcited, mdiClose } from '@mdi/js';
import Chat from '../chat';

import * as s from '../../utilities/services/chatbot-help-service'

const HelpAssistanceChat = ({ show, close, user, dispatch }) => {
    const { t } = useTranslation();
    
    const [messages, setMessages] = useState([
        { type: 'bot', content: t('helpAssistantWelcome') }
    ]);
    const [isLoading, setIsLoading] = useState(false);

    const handleSendMessage = async (message, conversationHistory) => {
        // Add user message
        setMessages(prev => [...prev, { type: 'user', content: message }]);
        setIsLoading(true);

        try {
            const response = await s.sendMessage({ 
                payload: { message, conversationHistory }, 
                user: user
            }, dispatch);
            
            if (response.success) {
                // Format the bot's response to preserve line breaks
                const formattedResponse = response.data.response.split('\n').map((line, i) => (
                    <React.Fragment key={i}>
                        {line}
                        {i !== response.data.response.split('\n').length - 1 && <br />}
                    </React.Fragment>
                ));
                
                setMessages(prev => [...prev, { type: 'bot', content: formattedResponse }]);
            }
        } catch (error) {
            console.error('Chat error:', error);
            setMessages(prev => [...prev, { 
                type: 'bot', 
                content: t('helpAssistantError'),
                isError: true 
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const onHide = () => {
        setMessages([{ type: 'bot', content: t('helpAssistantWelcome') }]);
        close();
    };

    return (
        <Modal 
            show={show} 
            onHide={onHide}
            className="help-assistance-modal"
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title as="div">
                    <div className="d-flex align-items-center gap-2">
                        <Icon path={mdiRobotExcited} size={1} />
                        <span>{t('helpAssistantTitle')}</span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            
            <Modal.Body className="p-0">
                <Chat
                    messages={messages}
                    onSendMessage={handleSendMessage}
                    isLoading={isLoading}
                    inputPlaceholder={t('helpAssistantPlaceholder')}
                />
            </Modal.Body> 
        </Modal>
    );
};

export default HelpAssistanceChat;