import { createSlice } from '@reduxjs/toolkit'

const accountSlice = createSlice({
  name: 'account-expense',
  initialState: { loading: false, item: undefined, tree: undefined, leaves: [], message: undefined, error: undefined },
  reducers: {
     
    getTree(state, action) {
      state.loading = true
    },
    
    getTreeResponse(state, action) {
      state.loading = false
      state.tree = action.payload
    },
      
    get(state, action) {
      state.loading = true
    },

    getResponse(state, action) {
      state.loading = false
      state.item = action.payload
    },
 
    move(state, action) {
      state.loading = true
    },

    moveResponse(state, action) {
      state.loading = false      
    },
      
    error(state, action) {
        state.loading = false
        state.error = action.payload

        console.log(action.payload)
    }
  }
})

export const { 
    getTree, getTreeResponse,
    get, getResponse,
    move, moveResponse,
    error
} = accountSlice.actions;

export default accountSlice.reducer