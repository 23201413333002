import React, { useEffect, useState, useContext } from "react";
import { Route, Navigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'

import UserContext from '../../user/user-context';
// maybe change write "setup" instead "config"
import pathSolver from "../../utilities/path-solver";

const RedirectComponent = () => {

    const { id } = useParams();
    const user = useContext(UserContext)

    const dispatch = useDispatch()

    const [path, setPath] = useState('')
    
    useEffect(()=> {
        const load = async () => {
            const path = await pathSolver.getById(id, user, dispatch)
            setPath(path)
        }

        load()
    })
 
    if(!path)
        return <div />

    return (
        <Navigate to={`/${path}/${id}/view`} />
    );
};

const routes =  
        <Route path="/:id" element={<RedirectComponent />}>
  
        </Route> 
 
export default routes