import React from "react"
import { useTranslation } from 'react-i18next';

import * as tc from '../../configurations/text-constants'

const NotAvailableComponent = ({configs}) => {

    const { t } = useTranslation();

    return (
        <div>
            {t(tc.COMPONENT_NOT_AVAILABLE_LABEL)}
        </div>
    )
}

export default NotAvailableComponent