import React, { useEffect, useState } from "react";
import Select, { components } from 'react-select';
import { useDispatch } from 'react-redux'

import * as s from '../../utilities/services/report-type-service'

const ReportTypeFieldSelector = ({user, typeId, value, selectedFields, onChange}) => {

    const [list, setList] = useState([])
    const [internalValue, setInternalValue] = useState()
  
    const dispatch = useDispatch()

    // customization of Select component options
    const Option = ({ ...props }) => {                
        return <components.Option {...props}>
            <div>{props.label}</div>
        </components.Option>
    } 

    const valueOptions = () => {
  
        if(!list)
            return []

        return list.filter(x => !selectedFields || !selectedFields.find(e => x.id === e.id)).map((e)=> { 
            
            return { 
                value: e.id, 
                label: e.name,
                type: e.type
            }
        })
    }

    useEffect(() => {
 
        if(list.length === 0) {
            const load = async () => {
                const response = await s.getAllActivedFields({payload: {id: typeId}, user}, dispatch)
                if(!response.success) 
                    return
 
                const ilist = response.data
                setList(ilist) 
 
                const item = ilist.find(t => t.id === value) 
                if(item) {
                    setInternalValue({value: item.id, label: item.name, type: item.type})
                } 
            } 
           
            load()
        }
 
   
        if(!value) 
            setInternalValue(undefined)
     
        
    }, [value])
 
    return <Select options={valueOptions()} components={{ Option }} isSearchable ={true} value={internalValue? internalValue: null} onChange={(v) => {onChange(v); setInternalValue(v)}} /> 
    
}

export default ReportTypeFieldSelector