import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import * as s from '../../utilities/services/user-profile-service' 
 
import * as tc from '../../common/configurations/text-constants'
 
const EnableMultiCurrency = ({showModal, setShowModal, user}) => {
   
    const { t } = useTranslation();

    const item = useSelector((state) => state.userProfile.item)

    const [elementCopy, setElementCopy] = useState({...item})
    const [invalidFields, setInvalidFields] = useState([])

    const dispatch = useDispatch()
     
    const saveHandler = async () => { 
        const response = await s.update({payload: {...elementCopy, is_multicurrency: true}, item, user: user }, dispatch)
        if(response.success) {
            setShowModal(false) 
        }
    }
 
    
    return (
        <Modal show={showModal} onHide={()=> setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title as="div">{t('enable-currency.title')}</Modal.Title>
            </Modal.Header>        
            <Modal.Body>
                <p className="text-danger fw-bold">{t('enable-currency.warning-title')}</p>
                <p className="text-danger">{t('enable-currency.warning-content1')}</p>
                <p className="text-danger">{t('enable-currency.warning-content2')}</p>
                <p className="text-danger">{t('enable-currency.warning-content3')}</p>
            </Modal.Body>
        
            <Modal.Footer>
                <button className="btn btn-custom-secondary btn-sm" onClick={()=> setShowModal(false)}>{t(tc.BUTTON_CANCEL_LABEL)}</button>
                <button type="button" className="btn btn-custom-primary btn-sm" variant="primary" onClick={saveHandler} >{t(tc.BUTTON_ENABLE_LABEL)}</button>
            </Modal.Footer>
        </Modal>

        
    )
}

export default EnableMultiCurrency