import configTemplates from '../../common/configurations'

const configurations = configTemplates.getConfigurations()

const configs = {

    ...configurations,
 
    object: {
        basePath: 'transaction-receipt-files',
    },
}
 
export default configs