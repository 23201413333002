import React from "react";

import '../page.css'
const SimplePageBody = ({body}) => {
    
    return (
        <div className="card"> 
            <div className="card-body">
                {body}
            </div>
        </div>
    )
}

export default SimplePageBody