import React from 'react';
import { useTranslation } from 'react-i18next';

const PayUComponent = ({ user, info, dispatch }) => {

    const { t } = useTranslation();
    
    if (!info || !info.payu) {
        return <div>{t('payment.error')}</div>;
    }

    const {
        merchantId,
        accountId,
        description,
        referenceCode,
        amount,
        tax,
        taxReturnBase,
        currency,
        signature,
        test,
        buyerEmail,
        responseUrl,
        confirmationUrl,
        payuFormUrl,
        extra1
    } = info.payu;
 
    return (
        <div>
            <div className="col-12 text-center">
                <h2 className="payment-title">{t('payment.title')}</h2>
                <hr className="divider"/>
            </div>
            
            <div className="col-12 text-center">
                <h3 className="complete-purchase-title">{t('payment.completePurchaseTitle')}</h3>
                <div className="payment-details">
                    <p><strong>{t('payment.annualPrice')}:</strong> ${info.service && info.service.amount} USD</p>
                </div>
            </div>
            <div className="col-12 text-center">
                <form method="post" action={payuFormUrl}>
                    <input name="merchantId"    type="hidden"  value={merchantId}   />
                    <input name="accountId"     type="hidden"  value={accountId}    />
                    <input name="description"   type="hidden"  value={description}  />
                    <input name="referenceCode" type="hidden"  value={referenceCode}/>
                    <input name="amount"        type="hidden"  value={amount}       />
                    <input name="tax"           type="hidden"  value={tax}          />
                    <input name="taxReturnBase" type="hidden"  value={taxReturnBase}/>
                    <input name="currency"      type="hidden"  value={currency}     />
                    <input name="signature"     type="hidden"  value={signature}    />
                    <input name="test"          type="hidden"  value={test}         />
                    <input name="buyerEmail"    type="hidden"  value={buyerEmail}   />
                    <input name="responseUrl"   type="hidden"  value={responseUrl}  />
                    <input name="confirmationUrl" type="hidden" value={confirmationUrl} />
                    <input name="extra1" type="hidden" value={extra1} />
                    <button 
                        type="submit"
                        className="payu-button"
                        style={{
                            backgroundColor: '#a6c307',
                            color: 'white',
                            padding: '10px 20px',
                            fontSize: '18px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    >
                        {t('payment.submitButton')}
                    </button>
                </form>
            </div>
        </div> 
    );
}

export default PayUComponent;