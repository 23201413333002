import React, { Fragment, useEffect, useState } from "react"
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { useDispatch } from 'react-redux'

import { getSelectorValue } from './helper'
import CompactNewPage from '../layouts/compact-pages/compact-new-page'

const GenericNewSelector = ({service, serviceadd, vconfigs, user, value, onChange}) => {
  
    const [internalValue, setInternalValue] = useState() 
    const [showModal, setShowModal] = useState(false)
    const [newName, setNewName] = useState()
    const [valueOptions, setValueOptions] = useState()

    const dispatch = useDispatch()

    // customization of Select component options
    const Option = ({ ...props }) => {                
        return <components.Option {...props}>
            <div>{props.label}</div>
        </components.Option>
    }
   
    vconfigs.actions.add.handler = async ({element}) => { 
        const response = await serviceadd({payload: element, user: user }, dispatch)
        if(!response.success)
            return

        const convertedItem = {value: response.data.id, label: response.data.name}
        setValueOptions(convertedItem, ...valueOptions)
        setInternalValue(convertedItem)
        onChange(convertedItem)

        setShowModal(false)
    }
  
    const createHandler = (e) => {
        setNewName(e)
        setShowModal(true)
    }
  
    useEffect(() => {

        const load = async () => {
            
            const response = await service({user}, dispatch)
            if(!response.success)
                return
            
            const list = response.data
            const voptions = list.map((e)=> {  
                return { 
                    value: e.id, 
                    label: e.name
                }
            })

            setValueOptions(voptions) 

            // set value
            setInternalValue(getSelectorValue(value, list))
        }

        load()
        
    }, [])
 
    return (
        <Fragment>
            <CreatableSelect 
                onCreateOption={createHandler}  
                isClearable={true} 
                options={valueOptions} 
                components={{ 
                    Option
                }}
                isSearchable={true} 
                value={internalValue} 
                onChange={(v) => {onChange(v); setInternalValue(v)}}
            /> 
            {
                showModal && 
                <CompactNewPage configs={vconfigs} defaultItem={{name: newName}} setShowModal={setShowModal}  maxOptions={5}/>
            }
        </Fragment>
    )
}

export default GenericNewSelector