import { Route } from "react-router-dom";

import AccountAssetPage from "../../accounts/account-asset";
import AccountAssetDetailPage from "../../accounts/account-asset/account-asset-detail-page";
import AccountAssetEditPage from "../../accounts/account-asset/account-asset-edit-page";
import AccountAssetNewChildPage from "../../accounts/account-asset/account-asset-new-child-page";

// maybe change write "setup" instead "config"
const routes =  
        <Route path="/account-assets">
 
            <Route index element={<AccountAssetPage />} />
 
            <Route path=":id" >
                <Route path="newc" element={<AccountAssetNewChildPage />} />
                <Route path="view" element={<AccountAssetDetailPage />} />
                <Route path="edit"  element={<AccountAssetEditPage />} />
            </Route> 

        </Route> 
 
export default routes
