import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import NewPage from "../../../common/layouts/pages/new-page";
import NewPageV2 from "../../../common/layouts/pages/new-page/new-page-v2";

import LoadingComponent from "../../../common/layouts/statuses/loading-component";

import configs from './configs'
import UserContext from '../../../user/user-context';

import * as s from '../../../utilities/services/payment-method-service'

const PaymentMethodCardNewPage = () => {
   
    const user = useContext(UserContext)

    const parent = useSelector(state => state.paymentMethod.parent)
  
    const dispatch = useDispatch()
  
    useEffect(() => {
        s.getParentByType({payload: {type: 'Cards'}, user }, dispatch)
    }, [])

    if(!parent)
        return <LoadingComponent configs={configs} isText={true}/>
  
    return <NewPageV2 service={s} configs={configs} additionalField={{parent_id: parent.id}}/>
}

export default PaymentMethodCardNewPage