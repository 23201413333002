import React from "react";
import { useSelector } from 'react-redux'
 
import SimplePage from "../../common/layouts/pages/simple-page";
import TableContainer  from "../../common/layouts/lists/table/table-container";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/transaction-asset-service' 

const ActivePage = () => {
     
    return (
        <SimplePage configs={configs}>
            <TableContainer service={s} configs={configs} />
        </SimplePage>
    )
}
export default ActivePage