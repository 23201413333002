import { Route } from "react-router-dom";
  
import ProfileDetailPage from '../../user/profile';
import ProfileEditPage from '../../user/profile/profile-edit-page';

const routes =  
    <Route path="/user-profiles/:id">
    
        <Route index element={<ProfileDetailPage />} />
 
        <Route path="view" element={<ProfileDetailPage />} />
        <Route path="edit" element={<ProfileEditPage />} />
       
    </Route> 

export default routes
