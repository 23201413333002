// system imports
import * as actions from '../redux/actions/transaction-expense-slice';

import * as gservice from './_generic-service'
import * as sservice from './_standard-service'
 
const SERVICE_PATH = 'expenses';

const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})

const convertReceivable = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/${params.item.id}/transform/receivable`,
        action: actions.convertReceivable,
        actionResponse: actions.convertReceivableResponse,
        actionError: actions.error
    }

    return sservice.post(sparams, params, dispatch)
}

const convertLiability = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/${params.item.id}/transform/liability`,
        action: actions.convertLiability,
        actionResponse: actions.convertLiabilityResponse,
        actionError: actions.error
    }

    return sservice.post(sparams, params, dispatch)
}
 
export const { get, getAll, getMore, add, update, remove, clone } = fns

export { convertReceivable, convertLiability }