// expense
export const EXPENSE_ACCOUNT = '#dc3545'
export const EXPENSE_TRANSACTION = '#dc3545'
export const EXPENSE_TEMPLATE = '#e35d6a'
export const EXPENSE_GENERATOR = '#e35d6a'

// income
export const INCOME_ACCOUNT = '#198754'
export const INCOME_TRANSACTION = '#198754'
export const INCOME_TEMPLATE = '#479f76'
export const INCOME_GENERATOR = '#479f76'

// asset
export const ASSET_ACCOUNT = '#0d6efd'
export const ASSET_TRANSACTION = '#0d6efd'
export const ASSET_TEMPLATE = '#3d8bfd'
export const ASSET_GENERATOR = '#3d8bfd'

// liability
export const LIABILITY_ACCOUNT = '#dc3545'
export const LIABILITY_TRANSACTION = '#dc3545'
export const LIABILITY_TEMPLATE = '#e35d6a'

// liability payment
export const LIABILITY_PAYMENT_TRANSACTION = '#198754'
export const LIABILITY_PAYMENT_TEMPLATE = '#479f76'
export const LIABILITY_PAYMENT_GENERATOR = '#479f76'

// transaction generator email
export const TRANSACTION_GENERATOR = '#6c757d'




