import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Import i18n hook

import MaterialIcon from "../../../common/icons/material-icon";

import './authenticated-container.css'
   
const AuthenticatedContainerSideHeader = ({user, configs}) => {
    
    const { t } = useTranslation();

    return (
        <div className="custom-side-header side-header">
            {
                configs.map((c, index) => {
                        
                    return (
                        <Link className="list-group-item list-group-item-action custom-list-group-item-action" to={c.object.basePath} key={index}>
                            <MaterialIcon obj={c.object.name} tooltip={t(c.object.pluralLabel)}/>
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default AuthenticatedContainerSideHeader