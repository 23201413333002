import { Route } from "react-router-dom";

import ActivePage from "../../transactions/transaction-asset";
import ActiveNewPage from '../../transactions/transaction-asset/transaction-asset-new-page';
import ActiveDetailPage from '../../transactions/transaction-asset/transaction-asset-detail-page';
import ActiveEditPage from '../../transactions/transaction-asset/transaction-asset-edit-page';
import ActiveClonePage from '../../transactions/transaction-asset/transaction-asset-clone-page';
   
// maybe change write "setup" instead "config"
const routes =  
        <Route path="/assets">
 
            <Route index element={<ActivePage />} />

            <Route path="new" element={<ActiveNewPage />} />
            <Route path="new/:accountid" element={<ActiveNewPage />} />

            <Route path=":id" >
                <Route path="view"  element={<ActiveDetailPage />} />
                <Route path="edit"  element={<ActiveEditPage />} />
                <Route path="clone"  element={<ActiveClonePage />} />
            </Route>

        </Route> 
 
export default routes
