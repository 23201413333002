import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'

import configTemplates from '../../common/configurations'
import FixedTableList from "../layouts/lists/fixed-table";
import * as c from "../configurations/constants"
import * as tc from "../configurations/text-constants"

import LoadingComponent from "../layouts/statuses/loading-component";

import * as s from '../../utilities/services/transaction-service'

const getCustomConfigs = () => {

    const configurations = configTemplates.getConfigurations()

    const configs = {
        
        ...configurations,

        actions: {
            ...configurations.actions
        },

        list: {
            items: [
                {
                    name: 'name',
                    type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                    label: tc.OBJECT_FIELD_GENERIC_NAME,
                    isLinked: true,
                },
                {
                    name: 'account',
                    type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                    label: tc.OBJECT_RELATED_LIST_ACCOUNT_NAME,
                    isLinked: false,
                },
                {
                    name: 'amount',
                    type: c.OBJECT_FIELD_DATA_TYPE_CURRENCY,
                    label: tc.OBJECT_FIELD_TRANSACTION_AMOUNT,
                    isLinked: false,
                    compactViewRightDetail: true,
                },
                {
                    name: 'date',
                    type: c.OBJECT_FIELD_DATA_TYPE_DATE,
                    label: tc.OBJECT_FIELD_TRANSACTION_DATE,
                    isLinked: false,
                    compactViewLeftDetail: true,
                }
                
            ]
        } 
    }

    return configs

}
const TransactionAccountRelatedList = ({econfig, account, user}) => {
  
    const [list, setList] = useState([]) 
    const [loading, setLoading] = useState(true) 

    const dispatch = useDispatch()

    useEffect(() => {

        const load = async () => {
            const response = await s.getAccountLimited({payload: account, limit: 10, user}, dispatch)
            if(response.success)
                setList(response.data)
            else
                setList([])

            setLoading(false)
        }

        load()
        
    }, [account])

    if(loading)
        return <LoadingComponent configs={getCustomConfigs()} />

    return <FixedTableList items={list} user={user} configs={getCustomConfigs()} />
}

export default TransactionAccountRelatedList