import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import FilterOperatorSelector from "../../../../../common/selectors/filter-operator-selector";
import ReportTypeFieldSelector from "../../../../../common/selectors/report-type-field-selector";
import { refreshFilters, refreshFiltersLogic, refreshFiltersLogicExpression } from '../../../../../utilities/redux/actions/report-slice';
import FilterPreview from "./filter-preview";

import * as tc from '../../../../../common/configurations/text-constants';
import * as c from '../../../../../common/configurations/constants';

const FilterForm = ({ report, onAddFilter, user }) => {
    
    const { t } = useTranslation();

    const [column, setColumn] = useState({});
    const [operator, setOperator] = useState('');
    const [value, setValue] = useState('');
    const [addNewFilter, setAddNewFilter] = useState(false);

    const add = () => {
        onAddFilter({ column, operator, value });
        setColumn(undefined);
        setOperator(undefined);
        setValue(undefined);
    };

    const disableButton = () => {
        return !(column && column.id && operator !== '');
    };

    return (
        <Fragment>
            <p>
                <a href="#" className={`${addNewFilter ? "link-secondary" : "link-primary"}`} onClick={() => setAddNewFilter(true)}>
                    {t(c.REPORT_FILTERS_LINK_ADD_NEW_FILTER)}
                </a>
            </p>
            <Modal show={addNewFilter} onHide={() => setAddNewFilter(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="div">{t(c.REPORT_FILTERS_MODAL_ADD_FILTER_TITLE)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group vstack gap-3">
                        <div>
                            <label>{t(c.REPORT_FILTERS_LABEL_COLUMN)}</label>
                            <ReportTypeFieldSelector
                                onChange={(v) => { setColumn({ id: v.value, label: v.label, type: v.type }); }}
                                typeId={report.source_id}
                                user={user}
                                value={column}
                            />
                        </div>
                        <div>
                            <label>{t(c.REPORT_FILTERS_LABEL_OPERATOR)}</label>
                            <FilterOperatorSelector
                                onChange={(v) => setOperator({ id: v.value, label: v.label })}
                                value={operator}
                                type={column && column.type}
                            />
                        </div>
                        <div>
                            <label>{t(c.REPORT_FILTERS_LABEL_VALUE)}</label>
                            <input type="text" className="form-control" onChange={(e) => setValue(e.target.value? e.target.value.trim(): '')} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-custom-secondary btn-sm" onClick={() => setAddNewFilter(false)}>
                        {t(c.REPORT_FILTERS_BUTTON_CANCEL_LABEL)}
                    </button>
                    <button type="button" className="btn btn-custom-delete btn-sm" onClick={add} disabled={disableButton()}>
                        {t(c.REPORT_FILTERS_BUTTON_ADD_FILTER_LABEL)}
                    </button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

const FilterLogic = ({filtersLogic, updateFiltersLogic, filtersLogicExpression}) => {

    const { t } = useTranslation();
    
    return (
        <div>
            {t(c.REPORT_FILTERS_LABEL_FILTER_LOGIC)}
            <select className="form-control" value={filtersLogic} onChange={updateFiltersLogic}>
                <option value="and">{t(c.REPORT_FILTERS_OPTION_AND)}</option>
                <option value="or">{t(c.REPORT_FILTERS_OPTION_OR)}</option>
                <option value="c">{t(c.REPORT_FILTERS_OPTION_CUSTOMIZED)}</option>
            </select>
            {filtersLogic === 'c' && (
                <div>
                    {t(c.REPORT_FILTERS_LABEL_FILTER_LOGIC_EXPRESSION)}
                    <input type="text" className="form-control" autoFocus="autoFocus" value={filtersLogicExpression} onChange={updateFiltersLogicExpression} />
                </div>
            )}
        </div>
    );
};

const ReportTableEditorPropertiesFilters = ({ report, user, sourceColumns }) => {
    
    const [filters, setFilters] = useState([...report.filters]);
    const [filterStates, setFilterStates] = useState([]);
    const [filtersLogic, setFiltersLogic] = useState(report.filters_logic);
    const [filtersLogicExpression, setFiltersLogicExpression] = useState(report.filters_logic_expression ? report.filters_logic_expression : '');
    
    const dispatch = useDispatch();

    const onAddFilter = (filter) => {
        const tfilters = [...filters, filter];
        updateFilters(tfilters);
    };

    const deleteFilter = (index) => {
        let tfilters = filters.slice();
        tfilters.splice(index, 1);
        updateFilters(tfilters);
    };

    const changeValueFilter = (index, item) => {
        let nfilters = [...filters];
        nfilters[index] = item;
        setFilterStates([...filterStates]);
        updateFilters([...nfilters]);
    };

    const updateFilters = (filters) => {
        setFilters(filters);
        dispatch(refreshFilters(filters));
    };

    const updateFiltersLogic = (e) => {
        setFiltersLogic(e.target.value);
        dispatch(refreshFiltersLogic(e.target.value));
    };

    const updateFiltersLogicExpression = (e) => {
        setFiltersLogicExpression(e.target.value);
        dispatch(refreshFiltersLogicExpression(e.target.value));
    };

    

    return (
        <div className="vstack gap-3">
            <FilterPreview filters={filters} deleteItem={deleteFilter} changeValueItem={changeValueFilter} />
            <FilterLogic filtersLogic={filtersLogic} updateFiltersLogic={updateFiltersLogic} filtersLogicExpression={filtersLogicExpression}/>
            <hr />
            <FilterForm report={report} onAddFilter={onAddFilter} user={user} />
        </div>
    );
};

export default ReportTableEditorPropertiesFilters;