// system imports
import axios from "axios";
import * as shelper from './helper' 
import * as gservice from './_generic-service'

const API_URL = shelper.serverDomain() + '/transaction-receipts';
 
const getAll = async (params, dispatch) => {

    try {
        const url = `${API_URL}/${params.type}/${params.payload.id}`
        const options = shelper.axiosBearerConfig(params)
       
        const response = await axios.get(url, options);
          
        return gservice.getResponse(response)
       
    }
    catch(e) {
      
        return gservice.getError(e)
    }
    
}

const get = async (params, dispatch) => {

    try { 
        const url = `${API_URL}/${params.payload.id}`
        const options = shelper.axiosBearerConfig(params)
       
        const response = await axios.get(url, options);
         
        return gservice.getResponse(response)
       
    }
    catch(e) {
        return gservice.getError(e)
    }
    
}

const add = async (params, dispatch) => {

    try {
        const url = `${API_URL}/${params.transaction.type}/${params.transaction.id}`
        const options = shelper.axiosBearerConfig(params)
        options.headers['Content-Type']= 'multipart/form-data'
       
        const formData = new FormData();
        formData.append('receipt', params.payload);
      
        const xparams = formData
  
        const response = await axios.post(url, xparams, options);
  
        return gservice.getResponse(response)
 
    }
    catch(e) {
        return gservice.getError(e)
    }
    
}
 
const remove = async (params, dispatch) => {

    try {
       
        const url = `${API_URL}/${params.payload.id}`
        const options = shelper.axiosBearerConfig(params)
         
        const response = await axios.delete(url, options);
 
        return gservice.getResponse(response)
 
    }
    catch(e) {
        return gservice.getError(e)
    }
    
}
  
export { getAll, get, add, remove }