import React from "react";
 
import SimplePage from "../../common/layouts/pages/simple-page";
import TableContainer  from "../../common/layouts/lists/table/table-container";

import configs from './configs' 

import * as s from '../../utilities/services/automated-liability-payment-email-service'

import './liability-payment-automated.css'

const AutomatedPage = () => {
   
    return (
        <SimplePage configs={configs}>
            <TableContainer service={s} configs={configs} />
        </SimplePage>
    )
}
export default AutomatedPage