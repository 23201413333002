// system imports
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/report-data-slice';

 const SERVICE_PATH = 'report-data';
 
const get = async (params, dispatch) => {
 
    const sparams = {
        path: `${SERVICE_PATH}/${params.payload.id}`,
        action: actions.get,
        actionResponse: actions.getResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
const getLive = async (params, dispatch) => {
 
    const sparams = {
        path: `${SERVICE_PATH}/${params.payload.id}/live?c=${params.payload.columns.join(',')}&f=${params.payload.filters.join(',')}&fl=${params.payload.filtersLogic}&fle=${params.payload.filtersLogicExpression}&st=${params.payload.sortColumn?params.payload.sortColumn: ''}&std=${params.payload.sortColumnDirection?params.payload.sortColumnDirection:''}`,
        action: actions.get,
        actionResponse: actions.getResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
  
export { get, getLive }