import { createSlice } from '@reduxjs/toolkit'

const reportDataSlice = createSlice({
    name: 'report-data',
    initialState: { loading: false, item: undefined, itemId: undefined, message: undefined, error: undefined },
    reducers: {
       
      get(state, action) {
        state.loading = true
      },

      getResponse(state, action) {
 
        state.loading = false
        state.item = action.payload 
      },
  
      error(state, action) {
          state.loading = false
          state.error = action.payload
  
          console.log(action.payload)
      }
    }
})

export const { 
    get, getResponse,
    error
} = reportDataSlice.actions;

export default reportDataSlice.reducer