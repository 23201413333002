import React from 'react';
import { Link } from "react-router-dom";
import { Container, Grid, Card, CardContent } from '@mui/material';
import { CreditCard as CreditCardIcon, Email as EmailIcon, Person as PersonIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import './home-page.css';

const HomeDefaultPage = ({ user }) => {
    const { t } = useTranslation();

    return (
        <Container className="home-default-container py-5">
            <h3>{t('defaultPage.welcome_message')}</h3>
            <p>{t('defaultPage.welcome_description')}</p>
            
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <h5>{t('defaultPage.step_1_title')}</h5>
                            <CreditCardIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                            <p>{t('defaultPage.step_1_content')}</p>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <h5>{t('defaultPage.step_2_title')}</h5>
                            <EmailIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                            <p>{t('defaultPage.step_2_content')}</p>
                            <a href={process.env.REACT_APP_DOCUMENT_URL} target="_blank" rel="noopener noreferrer" className="btn btn-custom-primary btn-sm">
                                {t('defaultPage.documentation_link')}
                            </a>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <h5>{t('defaultPage.step_3_title')}</h5>
                            <PersonIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
                            <p>{t('defaultPage.step_3_content')}</p>
                            <Link to={`/user-profiles/${user.profile_id}`} className="btn btn-custom-primary btn-sm">
                                {t('defaultPage.profile_link')}
                            </Link>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <footer className="mt-5 text-center">
                {t('defaultPage.help_footer')} <a target="_blank" href="https://docs.google.com/document/d/188nFikulO4-pw7ycDO_JCb6DN3hDCcBU1H46QwN-8FA">{t('defaultPage.help_page')}</a>.
            </footer>

            <div className="watermark"></div>
        </Container>
    );
}

export default HomeDefaultPage;