import React, { useState, useEffect, Fragment } from "react";

import TemplateOne from "./dashboard-template-one"; 
import TemplateTwo from "./dashboard-template-two";


const DashboardTemplate = ({element, user, readonly}) => {
 
    const [section1, setSection1] = useState([])
    const [section2, setSection2] = useState([])
     
    useEffect(()=>{ 
          
        if(!element || !element.sections)
            return
  
        const load = async () => {
   
            if(element.number_columns === 1) 
                setSection1(element.sections)

            else if(element.number_columns === 2) {
                setSection1(element.sections[0])
                setSection2(element.sections[1])
            }
        }
         
        load()
 
    }, [element])
 
    if(!element || !element.sections)
        return <div></div>
 
    return (
        <Fragment>
            {
                element.number_columns === 1 &&
                <TemplateOne user={user} section={section1} readonly={readonly}/>
            }
            {
                element.number_columns === 2 &&
                <TemplateTwo user={user} section1={section1} section2={section2} readonly={readonly}/>
            }
            
        </Fragment>
    )  
}

export default DashboardTemplate