import { Route } from "react-router-dom";

import ReportTypePage from "../../data/report-type/report-type-page";
import ReportTypeNewPage from '../../data/report-type/report-type-new-page';
import ReportTypeDetailPage from '../../data/report-type/report-type-detail-page';
import ReportTypeEditPage from '../../data/report-type/report-type-edit-page';
import ReportTypeClonePage from '../../data/report-type/report-type-clone-page';
import ReportTypeSetupPage from '../../data/report-type/report-type-setup-page';

 
const routes =  
    <Route path="/report-types">
    
        <Route index element={<ReportTypePage />} />

        <Route path="new" element={<ReportTypeNewPage />} />

        <Route path=":id" >
            <Route path="view"  element={<ReportTypeDetailPage />} />
            <Route path="edit"  element={<ReportTypeEditPage />} />
            <Route path="clone"  element={<ReportTypeClonePage />} />
            <Route path="setup"  element={<ReportTypeSetupPage />} />
        </Route>

    </Route> 
 
export default routes