import React from "react";
  
import EditPageV2 from "../../common/layouts/pages/edit-page/edit-page-v2";
import configs from './configs' 

import * as s from '../../utilities/services/automated-liability-payment-email-service'

const AutomatedEditPage = () => {

    return (
        <EditPageV2 service={s} configs={configs}>
        </EditPageV2>
    )
}

export default AutomatedEditPage