import React, { Fragment } from "react";
import _ from "lodash"
  
import OutputField from "../../../layouts/fields/output-field";
import { getFormOutputField, getFieldLayout } from "../../../layouts/fields/utility-field";
 
import TreeItemDetailPageRelatedList from "./tree-item-detail-page-related-list";

const TreeItemDetailPageBody = ({element, configs, user}) => {
  
    const getColumn = (e, ncols, index) => {
        return (
            getFormOutputField({element, user, configs: e, ncolumns: ncols, index, fieldRecordMetadata: {configs, element}, Field: OutputField})
        )
    }

    return (
        <Fragment >

            <div className="vstack gap-2 page-not-edit-container">

                <div className="card">
                    <div className="card-body">
                        {getFieldLayout(configs, getColumn, element)}
                    </div>
                </div>

                {
                    element &&
                    <TreeItemDetailPageRelatedList element={element} configs={configs} user={user} />
                } 
            </div>

        </Fragment>
    )
}

export default TreeItemDetailPageBody