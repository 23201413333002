import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
 
import * as s from '../../utilities/services/account-liability-service'
import * as saccount from '../../utilities/services/account-service'

import TreeItemDetailPage from '../../common/tree-layouts/pages/tree-item-detail-page'
import configs from './configs'

const AccountLiabilityDetailPage = () => {
  
    return (
        <TreeItemDetailPage service={s} configs={configs}/>
    )
}

export default AccountLiabilityDetailPage