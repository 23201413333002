// system imports
import * as gservice from './_generic-service'
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/transaction-income-slice';
 
const SERVICE_PATH = 'incomes';

const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
 
const convertPayable = async (params, dispatch) => {

    const sparams = {
        path: `${SERVICE_PATH}/${params.item.id}/transform/payable`,
        action: actions.convertPayable,
        actionResponse: actions.convertPayableResponse,
        actionError: actions.error
    }

    return sservice.post(sparams, params, dispatch)
}

const getTargets = async (params, dispatch) => {

    const sparams = {
        path: `targets`,
        action: actions.getTargets,
        actionResponse: actions.getTargetsResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}
  
export const { get, getAll, getMore, add, update, remove, clone } = fns
export { getTargets, convertPayable }