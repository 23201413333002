// system imports
import * as sservice from './_standard-service'

import * as actions from '../redux/actions/chart-data-slice';
 
const SERVICE_PATH = 'chart-data';

const get = async (params, dispatch) => {
 
    const sparams = {
        path: `${SERVICE_PATH}/${params.payload.id}`,
        action: actions.get,
        actionResponse: actions.getResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)
}

const getLive = async (params, dispatch) => {
  
    const sparams = {
        path: `${SERVICE_PATH}/${params.payload.id}/live?l=${params.payload.label}&v=${params.payload.value? params.payload.value:''}&v2=${params.payload.value2? params.payload.value2:''}`,
        action: actions.get,
        actionResponse: actions.getResponse,
        actionError: actions.error
    }

    return sservice.get(sparams, params, dispatch)

}
 

export { get, getLive }