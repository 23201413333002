import React from "react";

import OutputField from "../../layouts/fields/output-field";
import { getCSSBasedOnType, getSizeCSSBasedOnType } from "../../layouts/fields/utility-field"

const ReportTableViewerRow = ({data, rdata, user}) => {
    return (
        <tr >
            {
                rdata.map((rc, i) => {
                    return <td key={i} className={`text-truncate ${getCSSBasedOnType({type: data.headers[i].data_type})} ${getSizeCSSBasedOnType({type: data.headers[i].data_type})}`}><OutputField value={rc} vConfig={{type: data.headers[i].data_type}}/></td>
                })
            }
        </tr>
    )
}

export default ReportTableViewerRow