import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, OverlayTrigger, Popover, Nav, Modal, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { 
    mdiFaceAgent,
    mdiEmail,
    mdiPhone,
    mdiMenu,
    mdiFacebook,
    mdiYoutube,
    mdiInstagram
} from '@mdi/js';
import logo from '../../logo.png';
import screenshot001 from './images/Portrait.001.png';
import visionImage from './images/vision.jpg';

import 'bootstrap/dist/css/bootstrap.min.css';
import './landing-page.css';
import LanguageToggleComponent from '../language';
import { DOCUMENT_WEB_DOCUMENTATION, DOCUMENT_TERMS_CONDITIONS } from "../../common/configurations/constants";
import * as docservice from '../../utilities/services/document-service'
import * as phraseservice from '../../utilities/services/phrase-service'
import HeroSection from './landing-header-section';
import AdsComponent from '../../common/ads/ads-component';
import PricingPlans from './landing-plans';

function LandingPage() {

    const navigate = useNavigate();
    const [termCondDoc, setTermCondDoc] = useState('')
    const [phrase, setPhrase] = useState('')
    const [loadingPhrase, setLoadingPhrase] = useState(true);
 
    const { t, i18n } = useTranslation();
     
    useEffect(()=> {

        // get content
        const load = async () => {
            try { 
                const response2 = await docservice.get({ language: i18n.language, name: DOCUMENT_TERMS_CONDITIONS });
                if (response2.success) setTermCondDoc(response2.data);

                const response3 = await phraseservice.get({ language: i18n.language });
                if (response3.success) setPhrase(response3.data);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoadingPhrase(false); // Detener loading al cargar la frase
            }
        }

        load()
 

    }, [i18n.language])
     
    return (
        <div className="App">
            
            <div className="watermark"></div>

            <nav className="navbar navbar-expand-lg custom-landing-nav">
                <div className="container-fluid">
                    <div className="navbar-brand hstack gap-2">
                        <img src={logo} alt="Logo" />
                        <span className="">FGo360</span>
                    </div>
                    <div className="ms-auto">
                        <LanguageToggleComponent t={t} i18n={i18n} />
                    </div>
                </div>
            </nav>

            <div className="buttons-menus">
                <div className="row">
                    <div className="col-12 col-md-6">
                        
                    </div>
                    <div className="col-12 col-md-6 mt-3 mt-sm-0 d-flex justify-content-end align-items-center">
                        <div className="d-flex gap-2 me-3 ">
                            <a href="https://apps.apple.com/co/app/fgo360/id6737204398?l=en-GB" target="_blank" rel="noopener noreferrer">
                                <img 
                                    src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png" 
                                    alt="Descargar en App Store" 
                                    className='image-application'
                                />
                            </a>
                        </div>
                        <button className="btn btn-custom-outline me-3" onClick={() => navigate("/login")}>{t('login')}</button>
                        <button className="btn btn-custom-primary" onClick={() => navigate("/signup")}>{t('signup')}</button>
                    </div>
                </div>
            </div>
  
            <Container fluid className="">
    <HeroSection t={t} loadingPhrase={loadingPhrase} phrase={phrase} />

    <Row>
        <Col xs={12} lg={12}>
            {/* Features Section */}
            <div className="features-container">
                <div className="features-grid">
                    {[3, 1, 2, 4].map((num) => (
                        <div
                            key={`feature-${num}`}
                            className="feature-item"
                        >
                            <h4 className="feature-title">
                                {t(`landing-page.feature${num}`)}
                            </h4>
                            <p className="feature-subtitle">
                                {t(`landing-page.feature${num}Description`)}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Pricing Plans */}
            <PricingPlans t={t} navigate={navigate}/>

            {/* Contact Section */}
            <div className="features-container">
                <div className="features-grid">
                    <div className="text-center mb-5">
                        <h3 className="section-title">{t('landing-page.contactUsTitle')}</h3>
                        <p className="text-muted">{t('landing-page.contactUsSubtitle')}</p>
                    </div>
                    <div className="card shadow-sm">
                        <div className="card-body p-4">
                            <h3 className="mb-4 text-primary">{t('landing-page.contactUsHeader')}</h3>
                            <p>{t('landing-page.contactUsText')}</p>
                            <ul className="list-unstyled">
                                <li className="mb-3">
                                    <strong>
                                        <Icon path={mdiEmail} size={1} className="me-2 text-primary" />
                                    </strong>
                                    {t('landing-page.contactUsEmail')}
                                </li>
                                <li className="mb-3">
                                    <strong>
                                        <Icon path={mdiPhone} size={1} className="me-2 text-primary" />
                                    </strong>
                                    {t('landing-page.contactUsPhone')}
                                </li>
                            </ul>
                            <p>{t('landing-page.contactUsClosing')}</p>
                        </div>
                    </div>
                </div>
               
            </div> 
           
        </Col>
 
    </Row>
</Container>
 
            <footer className="footer">
                <div className="footer-content">
                    <span>{t('footerRights')}</span>
                    <span className="separator">|</span>
                    <a href={termCondDoc.url} target="_blank" rel="noopener noreferrer">
                        {t('footerPolicy')}
                    </a>
                    <span className="separator">|</span>
                    <div className="social-media-links">
                        <a href="https://www.facebook.com/profile.php?id=61567121362684" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiFacebook} size={1} />
                        </a>
                        <a href="https://www.youtube.com/@FinanceGo360" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiYoutube} size={1} />
                        </a>
                        <a href="https://www.instagram.com/financego360/" target="_blank" rel="noopener noreferrer">
                            <Icon path={mdiInstagram} size={1} />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default LandingPage;