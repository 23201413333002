import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from 'react-redux'

import * as dataService from "../../../../../utilities/services/chart-data-standard-service"
import ChartViewer from "./chart-viewer";
import UserContext from '../../../../../user/user-context';

const ChartLiveViewerContainer = ({chartid, islive, label, value, value2, type}) => {
      
    const user = useContext(UserContext)

    const [data, setData] = useState()
    const dispatch = useDispatch()
  
    useEffect(() => {
 
        const load = async () => {
   
            setData()

            if(!chartid)
                return

            let response
  
            if(islive) {
  
                if(!(label && value))
                    return

                
                response = await dataService.getLive({payload: {id: chartid, label: label, value: value, value2: value2 }, user}, dispatch)
            }
            else {
                response = await dataService.get({payload: {id: chartid}, user}, dispatch)
            }
  
            if(response.success)
                setData(response.data)
        }

        load()
    }, [label, value, value2])

    if(!data)
        return (
            <div>
                <p>
                    El grafico no es valido
                </p>
            </div>
        )

    return (
        <ChartViewer data={data.data} typeid={type? type: data.chart_type_id}/>
    )
}

export default ChartLiveViewerContainer