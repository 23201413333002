import React from "react"
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

import formats from "./formats"
import * as c from "../../configurations/constants"
import { Link } from "react-router-dom"

import pathSolver from "../../../utilities/path-solver";
 
const OutputField = ({value, vConfig, user}) => {
  
    switch (vConfig.type) {

        case c.OBJECT_FIELD_DATA_TYPE_NUMBER:
        case c.OBJECT_FIELD_DATA_TYPE_INTEGER:
            return NumberFormatted({value, user})

        case c.OBJECT_FIELD_DATA_TYPE_PERCENT:
            return PercentFormatted({value, user})
            
        case c.OBJECT_FIELD_DATA_TYPE_BOOLEAN:
            return BooleanFormatted({value, user})

        case c.OBJECT_FIELD_DATA_TYPE_CURRENCY:
            return CurrencyFormatted({value, user})

        case c.OBJECT_FIELD_DATA_TYPE_AGGREGATE_DATE:
        case c.OBJECT_FIELD_DATA_TYPE_TEXT:
            return TextFormatted({value, user})

        case c.OBJECT_FIELD_DATA_TYPE_TEXTAREA:
            return TextAreaFormatted({value, user})
        
        case c.OBJECT_FIELD_DATA_TYPE_TEXTAREA_HTML:
            return TextAreaHtmlFormatted({value, user})
 
        case c.OBJECT_FIELD_DATA_TYPE_DATE:
            return DateFormatted({value, user})

        case c.OBJECT_FIELD_DATA_TYPE_DATETIME:
            return DatetimeFormatted({value, user})

        case c.OBJECT_FIELD_DATA_TYPE_SELECTOR:
            return SelectorFormatted({value, vConfig, user})

        case c.OBJECT_FIELD_DATA_TYPE_PICKLIST:
            return PicklistFormatted({value, vConfig, user})
    
        default:
            break;
    }
}

const BooleanFormatted = ({value, user}) => {
    
    if(value)
        return (
            <span>
                <i className="bi bi-check-square"  style={{fontSize: '1.0rem', color: '#adb5bd'}}></i>
            </span>
        )

    return (
        <span>
            <i className="bi bi-square"  style={{fontSize: '1.0rem', color: '#adb5bd'}}></i>
        </span>
    )
}

const TextAreaFormatted = ({value, user}) => {

    return (
        <div style={{whiteSpace:"pre-wrap"}}>{value}&nbsp;</div>
    )
}

const TextAreaHtmlFormatted = ({value, user}) => {

    const safeHtmlContent = DOMPurify.sanitize(value);

    return (
        <div dangerouslySetInnerHTML={{__html: safeHtmlContent}}></div>
    )
}

const TextFormatted = ({value, user}) => {

    return (
        <span style={{whiteSpace:"pre-wrap"}}>{value}&nbsp;</span>
    )
}

const CurrencyFormatted = ({value, user}) => {
 
    return (
        <span className={`fw-bold currency-format ${value<0? "text-danger": ""}`}>{formats.fcurrency(value, user)}</span>
    )
}

const NumberFormatted = ({value, user}) => {
 
    if(!value)
        return '-'

    return (
        <span>{value}</span>
    )
}

const PercentFormatted = ({value, user}) => {
 
    if(!value)
        return '-'
    
    return (
        <span>{`${Number.parseFloat((value).toFixed(1))} %`}</span>
    )
}
 
const DateFormatted = ({value, user}) => {
   
    let _date = new Date(value) 
     
    let _resultDateFormatter = new Intl.DateTimeFormat(
        ['ban', 'id'], 
        {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour12: false,
            timeZone: 'UTC'
        }
    )
 
    return (
        <span>{_resultDateFormatter.format(_date)}</span>
    )
}

const DatetimeFormatted = ({value, user}) => {
   
    try {
        let _date = new Date(value)
        var options = { dateStyle: 'short', timeStyle: 'short' };
     
        var _resultDate = new Intl.DateTimeFormat('en-GB', options).format(_date);
    
        return (
            <span>{_resultDate}</span>
        )
    }
    catch(ex) {
        return (
            <span>-</span>
        )
    }
    
}

const SelectorFormatted = ({value, vConfig, user}) => {

    if(!value || !value.id)
        return <span>&nbsp;</span>
  
    // only one path
    if(vConfig.isSelectorLinked) {
        return ( 
            <Link to={`/${value.id}`} style={{textDecoration: "none"}}>
                {value.name}
            </Link>
        )
    }
    
    // no path available
    return <span>{value.name}</span>
}

const PicklistFormatted = ({value, vConfig, user}) => {
 
    const { t } = useTranslation();
    
    if(!value || !value.id)
        return <span>&nbsp;</span>

    return <span>{t(value.name)}</span>
    
}

export default OutputField