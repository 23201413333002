import React from "react";
import { useSelector } from 'react-redux'

import ChartLiveViewerContainer from '../chart-view/chart-viewer/chart-live-viewer-container'

const ChartEditorViewer = ({chart, user}) => {
 
    const label = useSelector(state => state.chart.label)
    const value = useSelector(state => state.chart.value)
    const value2 = useSelector(state => state.chart.value2)
    const type = useSelector(state => state.chart.type)
 
    return (
        <ChartLiveViewerContainer islive={true} chartid={chart.id} label={label} value={value} value2={value2} type={type}/>
    )
}

export default ChartEditorViewer