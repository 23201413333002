// system imports
import * as gservice from './_generic-service'

import * as actions from '../redux/actions/transaction-liability-payment-slice';
 
const SERVICE_PATH = 'liability-payments';
 
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})

export const { get, getAll, getMore, add, update, remove, clone } = fns