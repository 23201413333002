import React, { useState, useLayoutEffect, useRef } from 'react';

import './auto-height-container.css'

const AutoHeightContainer = ({ info, bottomHandler, children, bottomMargin, disableTracking }) => {
 
    const [style, setStyle] = useState({height: 0});
    const scrollContainer = useRef(null);
    
    let lastPosition;
    const trackScrollMovingDown = (e) => {
        if(!lastPosition) {
            lastPosition = e.target.scrollTop
            return true
        }            
        let isMovingDown = lastPosition < e.target.scrollTop
        lastPosition = e.target.scrollTop
        return isMovingDown
    }

    const trackScrolling = (e) => {
        if(disableTracking) return
        if(!bottomHandler) return
        
        if(!trackScrollMovingDown(e)) return
        
        const scrollContainerBottomPosition = Math.round(e.target.scrollTop + e.target.clientHeight);
        const scrollPosition = Math.round(e.target.scrollHeight - 1);        
        if (scrollPosition > scrollContainerBottomPosition) return
        
        if(bottomHandler) bottomHandler(info)
    }

    useLayoutEffect(() => {
        const updateWindowDimensions = () => {
            const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
            const BOTTOM_MARGIN = isSmallScreen ? 120 : (bottomMargin || 80);
            
            setStyle({height: window.innerHeight - scrollContainer.current.getBoundingClientRect().top - BOTTOM_MARGIN})
        }
        
        window.addEventListener('resize', updateWindowDimensions);
        scrollContainer.current.addEventListener('scroll', trackScrolling)

        updateWindowDimensions()
  
        return function cleanup() {
            window.removeEventListener('resize', updateWindowDimensions)
            scrollContainer.current.removeEventListener('scroll', trackScrolling)
        }
    }, [bottomMargin, info, children]);
      

    return (
        <div className="auto-container" style={style} ref={scrollContainer}>
            {children}
        </div>
    )
}

export default AutoHeightContainer;