import React, { useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InputField from "../../../layouts/fields/input-field";
import FloatMessage from "../../../float-messages"; 
import { getField, getFieldLayout, validateInputs } from "../../../layouts/fields/utility-field";
import AutoHeightContainer from "../../../auto-height-container/auto-height-container";

import * as tc from '../../../configurations/text-constants'

const TreeItemEditPageBody = ({user, element, configs, message}) => {
  
    const { t } = useTranslation();
    
    const [elementCopy, setElementCopy] = useState({...element})
    const [invalidFields, setInvalidFields] = useState([])

    const navigate = useNavigate()
 
    const changeHandler = (value, econfig) => {  
        elementCopy[econfig.name] = value
        setElementCopy({...elementCopy})  
    }

    const cancelHandler = (e) => {
        navigate(`/${configs.object.basePath}/${element.id}/view`)
    } 

    const saveHandler = async (e) => {

        const noValidInputs = validateInputs(elementCopy, configs)
        if(noValidInputs.length > 0) {
            setInvalidFields(noValidInputs)
            return
        }

        // save changes and go to the list
        configs.actions.edit.handler({element: elementCopy})
    }

    const getColumn = (e, ncols, index) => {
        return getField({ isValid: invalidFields.indexOf(e.name) ===-1, element: elementCopy, user, configs: e, ncolumns: ncols, index, changeHandler, Field: InputField, action:'edit'})
    }
  
    return (
        <div className="card">
                 
            <div className="card-body">
                <AutoHeightContainer bottomMargin={80}>
                    <form className="needsValidation" noValidate>
                        <FloatMessage message={message}/>

                        { getFieldLayout(configs, getColumn, element) }
                    </form>
                </AutoHeightContainer >
            </div>
            
            <div className="card-footer text-center page-action-bottoms"> 
                <button type="button" className="btn btn-custom-secondary btn-sm me-2" onClick={cancelHandler}>
                    {t(tc.BUTTON_CANCEL_LABEL)}
                </button>
                <button type="button" className="btn btn-custom-primary btn-sm" onClick={saveHandler}>
                    {t(tc.BUTTON_SAVE_LABEL)}
                </button> 
            </div>
        </div>
        
        
    )
}

export default TreeItemEditPageBody