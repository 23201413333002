import React, { Fragment, useContext, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import EditPageHeader from "./edit-page-header";
import EditPageBody from "./edit-page-body";
import NotAvailableComponent from "../../statuses/not-available-component";
import LoadingComponent from "../../statuses/loading-component";

import UserContext from '../../../../user/user-context';


const EditPage = ({service, children, configs, message}) => {

    const user = useContext(UserContext)

    const { t } = useTranslation();
    const { id } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const item = useSelector((state)=> state[configs.object.sliceName].item) 
    const loading = useSelector((state)=> state[configs.object.sliceName].loading)
    
    document.title = `${item? item.name: t(configs.object.label)}`
    
    configs.actions.edit.handler = async ({element}) => {
       
        const response = await service.update({payload: element, user: user }, dispatch)
        
        if(response.success)
            navigate(`/${configs.object.basePath}/${response.data.id}/view`)
    }

    useEffect(() => {
        service.get({payload: {id}, user }, dispatch)
    }, [])
 
    if(loading)
        return <LoadingComponent configs={configs} isText={true}/>

    if(!item)
        return <NotAvailableComponent configs={configs} />
        
    return (
        <Fragment>
            <EditPageHeader user={user} configs={configs} element={item}/>
            <EditPageBody user={user} element={item} body={children} configs={configs} message={message}/>
        </Fragment>
    )
}

export default EditPage