import React from 'react';
import { Spinner } from 'react-bootstrap';
import './landing-header-section.css'
import screenshot001 from './images/Portrait.001.png';

const HeroSection = ({ t, loadingPhrase, phrase }) => {

  return (
    <div className="hero-section">
    {/* Título Principal */}
    <div className="hero-main">
        <div className="hero-title-group">
            <span className="hero-app-name">
                FGo360
            </span>
            <h2 className="hero-title">{t('mainTitle')}</h2>
        </div>
    </div>

    {/* Contenido Secundario */}
    <div className="hero-secondary">
        <div className="hero-content">
            <h5 className="hero-subtitle">{t('subTitle')}</h5>
            
            {loadingPhrase ? (
                <div className="spinner-container">
                    <Spinner animation="border" variant="light" />
                </div>
            ) : (
                <div className="phrase-card">
                    <p className="phrase-text">{phrase.phrase}</p>
                </div>
            )}
        </div>

        <div className="hero-image-container">
            <img 
                src={screenshot001} 
                alt="FGo360 App" 
                className="hero-image"
            />
        </div>
    </div>
</div>
  );
};

export default HeroSection;