import { Route } from "react-router-dom";
 
import ChartPage from "../../data/chart/chart-page";
import ChartNewPage from '../../data/chart/chart-new-page';
import ChartDetailPage from '../../data/chart/chart-detail-page';
import ChartEditPage from '../../data/chart/chart-edit-page';
import ChartClonePage from '../../data/chart/chart-clone-page';
import ChartSetupPage from '../../data/chart/chart-setup-page';
import ChartShowPage from '../../data/chart/chart-show-page';

const routes =  
    <Route path="/charts">
    
        <Route index element={<ChartPage />} />

        <Route path="new" element={<ChartNewPage />} />

        <Route path=":id" >
            <Route path="view" element={<ChartDetailPage />} />
            <Route path="edit" element={<ChartEditPage />} />
            <Route path="clone" element={<ChartClonePage />} />
            <Route path="setup" element={<ChartSetupPage />} />
            <Route path="print" element={<ChartShowPage />} />
        </Route>

    </Route> 

export default routes
