import { Route } from "react-router-dom";

import AccountLiabilityPage from "../../accounts/account-liability";
import AccountLiabilityDetailPage from "../../accounts/account-liability/account-liability-detail-page";
import AccountLiabilityEditPage from "../../accounts/account-liability/account-liability-edit-page";
import AccountLiabilityNewChildPage from "../../accounts/account-liability/account-liability-new-child-page";



// maybe change write "setup" instead "config"
const routes =  
        <Route path="/account-liabilities">
 
            <Route index element={<AccountLiabilityPage />} />
 
            <Route path=":id" >
                <Route path="newc" element={<AccountLiabilityNewChildPage />} />
                <Route path="view" element={<AccountLiabilityDetailPage />} />
                <Route path="edit"  element={<AccountLiabilityEditPage />} />
            </Route> 

        </Route> 
 
export default routes
