import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as cc from "../../common/configurations/color-constants"
import * as pickc from "../../common/configurations/picklist-constants"



const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,
 
        edit: {
            enable: true,
            handler: function() { console.log('edit')}
        },
        clone: {
            enable: true,
            handler: function() { console.log('clone')}
        },
        add: {
            enable: true,
            handler: function() { console.log('add')}
        },
        addNew: {
            enable: true,
            handler: function() { console.log('add')}
        },
        remove: {
            enable: true,
            handler: function() { console.log('remove')}
        },
        search: {
            enable: true,
            handler: function() { console.log('search')}
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'description',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_DESCRIPTION
                    }
                ]
            },
            
            {
                type: 'data',
                ncols: 2,
                elements: [
                   
                    {
                        ...elementTemplate,
                        name: 'payment_method_template',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_PAYMENT_METHOD,
                        isRequired: false
                    },
                    {
                        ...elementTemplate,
                        name: 'payment_method_type',
                        type: c.OBJECT_FIELD_DATA_TYPE_PICKLIST,
                        values: pickc.PAYMENT_METHODS,
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_PAYMENT_METHOD_TYPE,
                        isRequired: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'amount_template',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_AMOUNT,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        name: 'asset_template',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_ASSET_AUTOMATED_ASSET,
                        isRequired: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'movement_class_template',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_MOVEMENT_CLASS,
                        isRequired: true
                    },
                    {
                        ...elementTemplate,
                        name: 'movement_class_options',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXTAREA,
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_MOVEMENT_CLASS_OPTIONS,
                        isRequired: true
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'date_template',
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        isRequired: true
                    },{
                        ...elementTemplate,
                        name: 'date_format_template',
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_DATE_FORMAT,
                        type: c.OBJECT_FIELD_DATA_TYPE_PICKLIST,
                        values: pickc.DATE_FORMATS,
                        isRequired: true
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'time_template',
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_TIME,
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        isRequired: false
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'email_template',
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_EMAIL,
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXTAREA,
                        isRequired: true
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    
                    {
                        ...elementTemplate,
                        name: 'email_template_validation',
                        label: tc.OBJECT_FIELD_AUTOMATED_EMAIL_EMAIL_VALIDATION,
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXTAREA,
                        isRequired: true
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    }, 
                    {
                        ...elementTemplate,
                        name: 'modified_date',
                        label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-envelope-arrow-up',
        color: cc.ASSET_GENERATOR
    },

    object: {
        name: 'transaction_asset_automated_email',
        label: tc.OBJECT_ASSET_AUTOMATED_EMAIL_LABEL,
        pluralLabel: tc.OBJECT_ASSET_AUTOMATED_EMAIL_PLURAL_LABEL,
        basePath: 'asset-automated-emails',
        sliceName: 'assetAutomatedEmail',
        menuLabel: tc.NAV_MENU_AUTOMATION_TRANSACTION_GENERATOR_ASSET_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'asset_template',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_ASSET_AUTOMATED_ASSET,
                isLinked: false  
            },         
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            },         
            {
                name: 'modified_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_LASTUPDATE_DATE,
                isLinked: false
            }
            
        ]
    } 
}
 
export default configs