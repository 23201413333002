import React from "react"
import { useTranslation } from 'react-i18next';

import * as tc from '../../configurations/text-constants'

const LoadingComponent = ({configs, isText}) => {

    const { t } = useTranslation();

    return (
        <div className="row d-flex justify-content-center align-items-center" style={{paddingTop:'50px', height: '100%'}}>
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="spinner-border mb-2" role="status"></div>
                <strong>{t(tc.GENERAL_COMPONENT_MESSAGE__SELECT_LOADING)}</strong>
            </div>
        </div>
    )
}

export default LoadingComponent