import React from "react";
import { useTranslation } from 'react-i18next';

import SimpleHeader from "../../../layouts/headers/simple/simple-header";

const NewChildPageHeader = ({configs}) => {

    const { t } = useTranslation();
    
    return (
        <SimpleHeader configs={configs} text={`${"new-page.new"} ${t(configs.object.label)}`} >
        
        </SimpleHeader>
    )
}

export default NewChildPageHeader