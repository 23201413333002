import { createSlice, current } from '@reduxjs/toolkit'

const dashboardSectionSlice = createSlice({
    name: 'dashboardSection',
    initialState: { loading: false, list: [], sections: [], item: undefined, itemId: undefined, message: undefined, error: undefined },
    reducers: {
       
      
      setup(state, action) {
        state.loading = true
      },

      setupResponse(state, action) {

          state.loading = false 
      },
    
  
      error(state, action) {
          state.loading = false
          state.error = action.payload
  
          console.log(action.payload)
      }
    }
})

export const { 
    setup, setupResponse,
    error
} = dashboardSectionSlice.actions;

export default dashboardSectionSlice.reducer