import React, { useState } from "react";
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SimpleHeader from '../../headers/simple/simple-header' 
 
import * as tc from "../../../configurations/text-constants";

const DetailPageHeader = ({element, configs, user}) => {

    const navigate = useNavigate()
    const { t } = useTranslation();

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showDropdown, setShowDropdown] = useState(false);

    /** standard actions  */
    const editHandler = () => {
        navigate(`/${configs.object.basePath}/${element.id}/edit`)
    }

    const cloneHandler = () => {
        navigate(`/${configs.object.basePath}/${element.id}/clone`)
    }

    const setupHandler = () => {
        navigate(`/${configs.object.basePath}/${element.id}/setup`)
    }

    const deleteHandler = () => {
        // show confirmation modal
        setShowDeleteModal(true)
    }

    const deleteConfirmationHandler = () => {
        // show confirmation modal
        configs.actions.remove.handler(element.id)
        setShowDeleteModal(false)
    }
   
    return (
        <SimpleHeader configs={configs} text={element.name} >
            <div className="hstack gap-2 d-none d-md-flex">
                <div className="btn-group " role="group">
                    {
                        /**  custom action generator **/
                        configs.c_actions.filter(a => {return !a.visibilityFn || a.visibilityFn(element, user)}).map((ca, i) => {
                            return (
                                <button type="button" key={i} className="btn btn-custom-outline btn-sm" onClick={(e)=> ca.handler(e, element)}>
                                    {  t(ca.label)  }
                                </button>
                            )
                        })
                    }
                </div>
                
                <div className="btn-group" role="group">
                    {
                        configs.actions.setup.enable && (!configs.actions.setup.visibilityFn || configs.actions.setup.visibilityFn(element, user)) &&
                        <button type="button" className="btn btn-custom-primary btn-sm" onClick={setupHandler}>
                            { t(tc.BUTTON_SETUP_LABEL) }
                        </button>
                    }
                    {
                        configs.actions.clone.enable && (!configs.actions.clone.visibilityFn || configs.actions.clone.visibilityFn(element, user)) &&
                        <button type="button" className="btn btn-custom-primary btn-sm" onClick={cloneHandler}>
                            { t(tc.BUTTON_CLONE_LABEL) }
                        </button>
                    }
                    {
                        configs.actions.edit.enable && (!configs.actions.edit.visibilityFn || configs.actions.edit.visibilityFn(element, user)) &&
                        <button type="button" className="btn btn-custom-primary btn-sm" onClick={editHandler}>
                            { t(tc.BUTTON_EDIT_LABEL) }
                        </button>
                    }
                </div>

                <div className="btn-group" role="group">
                    {
                        configs.actions.remove.enable && (!configs.actions.remove.visibilityFn || configs.actions.remove.visibilityFn(element, user)) &&
                        <button type="button" className="btn btn-custom-delete btn-sm" onClick={deleteHandler} disabled={element.has_files || element.has_transactions}>
                            { t(tc.BUTTON_REMOVE_LABEL) }
                        </button>
                    }
                </div> 
            </div>
            

            {/* Dropdown button for small screens */}
            <div className="d-block d-md-none">
                <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                    <Dropdown.Toggle id="dropdown-basic" className="" variant="secondary">
                        {t(tc.SMALL_DEVICE_OPTION_LABEL)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {configs.c_actions.filter(a => !a.visibilityFn || a.visibilityFn(element, user)).map((ca, i) => (
                        <Dropdown.Item key={i} className="custom-dropdown-link" onClick={(e) => ca.handler(e, element)}>
                            { t(ca.label) }
                        </Dropdown.Item>
                        ))}

                        {
                            configs.c_actions.filter(a => !a.visibilityFn || a.visibilityFn(element, user)).length > 0 &&
                            <Dropdown.Divider />
                        }
                        

                        {
                            /** if you change this condition you have to change the above condition too */
                            configs.actions.setup.enable && (!configs.actions.setup.visibilityFn || configs.actions.setup.visibilityFn(element, user)) &&
                            <Dropdown.Item className="custom-dropdown-link" onClick={setupHandler}>
                                {t(tc.BUTTON_SETUP_LABEL)}
                            </Dropdown.Item>
                        }
                        {configs.actions.clone.enable && (!configs.actions.clone.visibilityFn || configs.actions.clone.visibilityFn(element, user)) &&
                        <Dropdown.Item className="custom-dropdown-link" onClick={cloneHandler}>
                            {t(tc.BUTTON_CLONE_LABEL)}
                        </Dropdown.Item>
                        }
                        {configs.actions.edit.enable && (!configs.actions.edit.visibilityFn || configs.actions.edit.visibilityFn(element, user)) &&
                        <Dropdown.Item className="custom-dropdown-link" onClick={editHandler}>
                            {t(tc.BUTTON_EDIT_LABEL)}
                        </Dropdown.Item>
                        }

                        {
                            configs.actions.remove.enable && (!configs.actions.remove.visibilityFn || configs.actions.remove.visibilityFn(element)) &&
                            <Dropdown.Divider />
                        }
                         
                        {
                            /** if you change this condition you have to change the above condition too */
                            configs.actions.remove.enable && (!configs.actions.remove.visibilityFn || configs.actions.remove.visibilityFn(element, user)) &&
                            <Dropdown.Item className="text-danger" onClick={deleteHandler} disabled={element.has_files || element.has_transactions}>
                                {t(tc.BUTTON_REMOVE_LABEL)}
                            </Dropdown.Item>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Modal show={showDeleteModal} onHide={()=> setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title as="div">{t(tc.MODAL_DELETE_HEADER) }</Modal.Title>
                </Modal.Header>        
                <Modal.Body>
                    <p>
                        { t(tc.MODAL_DELETE_BODY_MESSAGE).replace('[RECORD_NAME]', element.name) }
                    </p>             
                </Modal.Body>
            
                <Modal.Footer>
                    <button className="btn btn-custom-secondary btn-sm" onClick={()=> setShowDeleteModal(false)}>{t(tc.BUTTON_CANCEL_LABEL)}</button>
                    <button type="button" className="btn btn-custom-delete btn-sm" variant="primary" onClick={deleteConfirmationHandler} >{t(tc.BUTTON_REMOVE_LABEL)}</button>
                </Modal.Footer>
            </Modal>
        </SimpleHeader>
       
    )
}

export default DetailPageHeader