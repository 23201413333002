import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import ReportTableEditorPropertiesColumns from './report-table-editor-properties-columns'
import ReportTableEditorPropertiesFilters from './report-table-editor-properties-filters'
import ReportTableEditorPropertiesOrder from "./report-table-editor-properties-order";
import * as reportTypeService from "../../../../../utilities/services/report-type-service"
import { refresh } from "../../../../../utilities/redux/actions/report-slice";
 
const ReportTableEditorProperties = ({report, user}) => {
    
    const [sourceColumns, setSourceColumns] = useState(undefined)

    const { t } = useTranslation();

    const dispatch = useDispatch()

    useEffect(()=> {
  
        const getSetSourceColumns = async () => {
            // go get all columns related with this source
            let response = await reportTypeService.getAllActivedFields({payload: {id: report.source_id}, user: user}, dispatch)
            if(response.success)
                setSourceColumns(response.data)
        }

        getSetSourceColumns()
        dispatch(refresh({columns: report.columns, filters: report.filters, filtersLogic: report.filters_logic}))
       
    }, [])

    return (
         
        <Tabs defaultActiveKey="columns" className="mb-3" transition={false} >
            <Tab eventKey="columns" title={t('report-table-editor.title-colums')}>
                <ReportTableEditorPropertiesColumns report={report} user={user} />
            </Tab>
            <Tab eventKey="filters" title={t('report-table-editor.title-filters')}>
                {
                    sourceColumns && <ReportTableEditorPropertiesFilters report={report} user={user} sourceColumns={sourceColumns}/>
                }
            </Tab>
            <Tab eventKey="sort" title={t('report-table-editor.title-order')}>
                <ReportTableEditorPropertiesOrder report={report} user={user} />
            </Tab>
        </Tabs>
    )
}

export default ReportTableEditorProperties