import * as c from "../../../common/configurations/constants";
const numeral = require( 'numeral') 
 
const format = (value, type, user) => {
   
    switch (type) {

        case c.OBJECT_FIELD_DATA_TYPE_NUMBER:
        case c.OBJECT_FIELD_DATA_TYPE_INTEGER:
            return NumberFormatted(value, user)

        case c.OBJECT_FIELD_DATA_TYPE_PERCENT:
            return PercentFormatted(value, user)
            
        case c.OBJECT_FIELD_DATA_TYPE_BOOLEAN:
            return BooleanFormatted(value, user)

        case c.OBJECT_FIELD_DATA_TYPE_CURRENCY:
            return CurrencyFormatted(value, user)

        case c.OBJECT_FIELD_DATA_TYPE_TEXT:
            return TextFormatted(value, user)

        case c.OBJECT_FIELD_DATA_TYPE_TEXTAREA:
            return TextAreaFormatted(value, user)

        case c.OBJECT_FIELD_DATA_TYPE_DATE:
            return DateFormatted(value, user)

        case c.OBJECT_FIELD_DATA_TYPE_DATETIME:
            return DatetimeFormatted(value, user)
 
        default:
            break;
    }
}

const BooleanFormatted = (value, user) => {
    
    return value
}

const TextAreaFormatted = (value, user) => {

    return value
}

const TextFormatted = (value, user) => {

    return value
}

const CurrencyFormatted = (value, user) => {
 
    if(!value)
        return '-';

    return numeral(value).format('$0,0')
}

const NumberFormatted = (value, user) => {
 
    return value
}

const PercentFormatted = (value, user) => {
 
    return `${value}%`
}
 
const DateFormatted = (value, user) => {
  
    let _date = new Date(value) 
    let _resultDate = new Intl.DateTimeFormat(
        ['ban', 'id'], 
        {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour12: false
        }
    ).format(_date)
    
    return _resultDate
}

const DatetimeFormatted = (value, user) => {
   
    let _date = new Date(value)
    var options = { dateStyle: 'short', timeStyle: 'short' };
    var _resultDate = new Intl.DateTimeFormat('en-GB', options).format(_date);

    return _resultDate
}

export default format