// system imports
import * as gservice from './_generic-service'

import * as actions from '../redux/actions/dashboard-template-slice';
 
const SERVICE_PATH = 'dashboard-templates';
 
const fns = gservice.fns({
    path: SERVICE_PATH,
    actions: actions
})
  
export const { getAll } = fns