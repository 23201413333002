import React, { useEffect, useState, useContext, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
 
import UserContext from '../../user/user-context';
import * as s from '../../utilities/services/account-income-service'
import * as saccount from '../../utilities/services/account-service'

import TreeItemTreeItemEditPage from '../../common/tree-layouts/pages/tree-item-edit-page'
import configs from './configs'

const AccountIncomeEditPage = () => {
  
    return (
        <div> 
            <TreeItemTreeItemEditPage service={s} configs={configs}>
            </TreeItemTreeItemEditPage>
        </div>
    )
}

export default AccountIncomeEditPage