import React, { useEffect, useState, useContext, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
 
import UserContext from '../../user/user-context';
import * as s from '../../utilities/services/account-income-service'
import * as saccount from '../../utilities/services/account-service'

import TreeItemTreeItemNewChildPage from '../../common/tree-layouts/pages/tree-item-new-child-page'
import configs from './configs'

const AccountIncomeNewChildPage = () => {
  
    return (
        <div>             
            <TreeItemTreeItemNewChildPage service={s} configs={configs}>
            </TreeItemTreeItemNewChildPage>
        </div>
    )
}

export default AccountIncomeNewChildPage