import React, { useState, useEffect } from "react";

const FilterPreviewItem = ({item, deleteItem, changeValueItem, index}) => {
 
    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState(item.value)

    const valueChangeHandler = (e) => {
        setValue(e.target.value)
    }
     
    const editModeHandler = () => {
        setEditing(true)
    }

    const deleteHandler = () => {
        deleteItem(index)
    }

    const editModeCancelHandler = () => {
        setEditing(false)
    }

    const editModeSaveHandler = () => { 
        let ufilter = {...item}
        ufilter.value = value 

        setEditing(false)
 
        changeValueItem(index, ufilter)
    }

    useEffect(()=> {

    }, [item, index])

    return (
        <li className="list-group-item vstack gap-1" >
            <div className="hstack gap-1" >
                <span className="fw-normal">{item.column.label}</span>
                <span className="fst-italic fw-light">{item.operator.label}</span> 
                
                {
                    !editing? 
                    <div className="ms-auto hstack gap-2"> 
                        <a href="#" onClick={editModeHandler} className="link-secondary"><i className="bi bi-pencil" /></a>
                        <a href="#" onClick={deleteHandler}  className="link-danger"><i className="bi bi-trash"  /></a>
                    </div>:
                    <div className="ms-auto hstack gap-2"> 
                        <a href="#" onClick={editModeCancelHandler} className="link-secondary"><i className="bi bi-x-lg" /></a>
                        <a href="#" onClick={editModeSaveHandler} className="link-primary"><i className="bi bi-check-lg" /></a>
                    </div>
                }
                
            </div>
            
            <div>
                {
                    !editing? 
                    <span>{item.value}</span>:
                    <input type="text" className="form-control" value={value} onChange={valueChangeHandler}></input>
                }
            </div> 
        </li>
    )
}

export default FilterPreviewItem