import React, { useState } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';


function Search(props) {

    const [ timeId, setTimeId ] = useState(null)
    const [ text, setText ] = useState('')
    
    const { handler, placeholder } = props
    
    const internalSearch = (e)=> {

        if(timeId)
            clearTimeout(timeId)
        
        const value = e.target.value;
        setText(value)

        const temporalTimeId = setTimeout(() => {
            
            handler(value)            
            },100)

        setTimeId(temporalTimeId)
    }
    
    return (
        <div className="container-search">
            <input type="text" value={text} className="form-control" onChange={internalSearch} placeholder={placeholder}/>
        </div>
        
    );
}

export default Search;

