export const getSelectorValue = (value, list) => {
    // set value
    if(!value)
        return undefined

    const item = list.find(t => t.id === value)
    if(item) 
        return {value: item.id, label: item.name}

    return undefined
}