import { Route } from "react-router-dom";

import AccountExpensePage from "../../accounts/account-expense";
import AccountExpenseDetailPage from "../../accounts/account-expense/account-expense-detail-page";
import AccountExpenseEditPage from "../../accounts/account-expense/account-expense-edit-page";
import AccountExpenseNewChildPage from "../../accounts/account-expense/account-expense-new-child-page";



// maybe change write "setup" instead "config"
const routes =  
        <Route path="/account-expenses">
 
            <Route index element={<AccountExpensePage />} />
 
            <Route path=":id" >
                <Route path="newc" element={<AccountExpenseNewChildPage />} />
                <Route path="view" element={<AccountExpenseDetailPage />} />
                <Route path="edit"  element={<AccountExpenseEditPage />} />
            </Route> 

        </Route> 
 
export default routes
