import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container';
import * as useractions from '../../utilities/redux/actions/user-slice';
import * as uservice from '../../utilities/services/user-service';

const VerificationPage = () => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const tokenid = useSelector((state) => state.user.tokenid);
    const [time, setTime] = useState(60);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const updateHandler = async (e) => {
        if (error) setError('');
        setCode(e.target.value);
    };

    const activateHandler = async () => {
        const response = await uservice.activate({ id: tokenid, code }, dispatch);
        if (response.success) {
            navigate('/');
        } else {
            setError(response.message);
        }
    };

    const cancelHandler = () => {
        setError('');
        navigate(`/login`);
    };

    useEffect(() => {
        setError('');

        const intervalId = setInterval(() => {
            setTime((currentTime) => {
                if (currentTime <= 0) {
                    clearInterval(intervalId); 
                    cancelHandler();
                    return 0;
                }
                return currentTime - 1;
            });
        }, 1000); 

        return () => clearInterval(intervalId);
    }, []);

    return (
        <AnonymousFormContainer>
            <div className="card border border-light-subtle rounded-3 shadow-sm">
                <div className="card-body p-3 p-md-4 p-xl-5">
                    <h2 className="fs-5 fw-normal mb-4">{t('verificationPage.title')}</h2>
                    <div className="text-center mb-3"></div>
                    <p className="m-0 mb-2 text-secondary">{t('verificationPage.instructions')}</p>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row gy-2 overflow-hidden">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label htmlFor="code" className="form-label">{t('verificationPage.codeLabel')}</label>
                                    <input
                                        type="text"
                                        name="code"
                                        id="code"
                                        className="form-control"
                                        placeholder={t('verificationPage.codePlaceholder')}
                                        value={code}
                                        required
                                        onChange={updateHandler}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-custom-secondary btn" type="button" onClick={cancelHandler}>
                                            {t('verificationPage.cancelButton')}
                                        </button>
                                    </div>
                                    <div className="col-6 d-grid my-3">
                                        <button className="btn btn-custom-primary btn" type="button" onClick={activateHandler}>
                                            {t('verificationPage.validateButton')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {error && <p className="m-0 text-danger">{error}</p>}
                    <div className="mb-3 text-center">
                        <h1>{time}</h1>
                    </div>
                </div>
            </div>
        </AnonymousFormContainer>
    );
};

export default VerificationPage;