import React from 'react';
 
import './title.css'

const TitleComponent = ({title}) => {
    
    return (
        <div className="container-fluid pb-2 text-end">
            <span className="text-truncate text-uppercase">{title}</span>
        </div>
        
    )
}

export default TitleComponent