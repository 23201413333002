import React from "react";

import ColumnPreviewItem from "./column-preview-item";

const ReportTableEditorPropertiesColumns = ({ columns, onRemoveColumn, upColumn, downColumn }) => {
      
    
    return (
        <div className="card">
            <ul className="list-group list-group-flush">
                {
                    columns.map((c, i)=> <ColumnPreviewItem col={c} key={i} index={i} total={columns.length} onRemoveColumn={onRemoveColumn} upColumn={upColumn} downColumn={downColumn}/>)
                }
            </ul> 
        </div>
    )
}

export default ReportTableEditorPropertiesColumns