import { createSlice } from '@reduxjs/toolkit'

const listviewSlice = createSlice({
    name: 'listview',
    initialState: { loading: false, list: [], item: undefined, itemId: undefined, defaultItem: undefined, message: undefined, error: undefined },
    reducers: {
       
      get(state, action) {
        state.loading = true
      },

      getResponse(state, action) {
 
        state.loading = false
        state.item = action.payload
      },

      getAll(state, action) {
        state.loading = true
      },

      getAllResponse(state, action) {
        state.loading = false
        state.list = action.payload

        //  set default
        state.defaultItem = action.payload.find(lv => lv.is_default)
      },

      add(state, action) {
        state.loading = true
      },
  
      addResponse(state, action) {
        state.loading = false
        state.list = [action.payload, ...state.list]
      },
  
      update(state, action) {
          state.loading = true
      },
  
      updateResponse(state, action) {
   
          state.loading = false
          state.item = action.payload
      },
   
      remove(state, action) {
          state.loading = true
      },
  
      removeResponse(state, action) {
  
          state.loading = false
          state.itemId = action.payload
 
          const index = state.list.findIndex(x=> x.id == action.payload)
          
          if(index === -1)
            return 

          state.list.splice(index, 1)
      }, 
      
      error(state, action) {
          state.loading = false
          state.error = action.payload
  
          console.log(action.payload)
      }
    }
})

export const { 
    get, getResponse,
    getAll, getAllResponse,
    add, addResponse,
    remove, removeResponse,
    update, updateResponse,
    error
} = listviewSlice.actions;

export default listviewSlice.reducer