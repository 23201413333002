// system imports
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AnonymousFormContainer from '../containers/anonymous/anonymous-form-container';

// css imports
import './signup-page.css';

const SignupSuccessPage = () => {
    const { t } = useTranslation();

    return (
        <AnonymousFormContainer>
            <div>
                <h1>{t('signupSuccessPage.title')}</h1>
                <p>{t('signupSuccessPage.message')}</p>
                
                <Link to="/login" className="m-1">{t('signupSuccessPage.linkText')}</Link>
            </div>
        </AnonymousFormContainer>
    );
}

export default SignupSuccessPage;