import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import ClonePage from "../../common/layouts/pages/clone-page/clone-page";
import ClonePageV2 from "../../common/layouts/pages/clone-page/clone-page-v2";
import configs from './configs'
import UserContext from '../../user/user-context';

import * as s from '../../utilities/services/template-liability-payment-service'

const LiabilityPaymentTemplateClonePage = () => {

    return (
        <ClonePageV2 service={s} configs={configs}>

        </ClonePageV2>
    )
}

export default LiabilityPaymentTemplateClonePage