import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from "react-bootstrap";

import DetailPageV2 from "../../common/layouts/pages/detail-page/detail-page-v2";

import configs from './configs'
import UserContext from '../../user/user-context';

import TicketDetailMessagesPage from "./ticket-detail-messages-page";

import * as s from '../../utilities/services/support-ticket-service' 

const TicketDetailPage = () => {
 
    return (
        <DetailPageV2 service={s} configs={configs} >
            <TicketDetailMessagesPage />
        </DetailPageV2>
    )
}

export default TicketDetailPage