import configTemplates from '../../common/configurations'
import * as c from "../../common/configurations/constants"
import * as tc from "../../common/configurations/text-constants"
import * as cc from "../../common/configurations/color-constants"


const configurations = configTemplates.getConfigurations()
const elementTemplate = configTemplates.getObjectElement()

const configs = {

    ...configurations,

    actions: {

        ...configurations.actions,
  
        search: {
            enable: true,
            handler: function() { console.log('search')}
        },
        refurbish: {
            enable: true,
            handler: function() { console.log('refurbish')}
        }
    },

    layout: {

        ...configurations.layout,

        sections: [
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'name',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_GENERIC_NAME,
                        isRequired: true,
                        isEditable: false,
                    }, 
                    {
                        ...elementTemplate,
                        name: 'status',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_STATUS,
                        isEditable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'message',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXTAREA,
                        label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_MESSAGE,
                        isEditable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'efrom',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_FROM,
                        isEditable: false,
                    }, 
                    {
                        ...elementTemplate,
                        name: 'eto',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                        label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_TO,
                        isEditable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'body',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXTAREA_HTML,
                        label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_BODY_HTML,
                        isEditable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 1,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'body',
                        type: c.OBJECT_FIELD_DATA_TYPE_TEXTAREA,
                        label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_BODY,
                        isEditable: false,
                    }
                ]
            },
            {
                type: 'data',
                ncols: 2,
                elements: [
                    {
                        ...elementTemplate,
                        name: 'created_date',
                        label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                        type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                        isEditable: false,
                        isCreatable: false,
                    }, 
                    {
                        ...elementTemplate,
                        isVisible: false
                    },
                ]
            },
            
        ],

        boostrapIcon: 'bi-envelope-at',
        color: cc.TRANSACTION_GENERATOR
    },

    object: {
        name: 'transaction_gmail_automated_email',
        label: tc.OBJECT_TRANSACTION_AUTOMATED_EMAIL_LABEL,
        pluralLabel: tc.OBJECT_TRANSACTION_AUTOMATED_EMAIL_PLURAL_LABEL,
        basePath: 'transaction-automated-emails',
        sliceName: 'transactionAutomatedEmail',
        menuLabel: tc.NAV_MENU_AUTOMATION_TRANSACTION_EMAIL_LABEL
    },

    list: {
        items: [
            {
                name: 'name',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_GENERIC_NAME,
                isLinked: true
            },
            {
                name: 'efrom',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_FROM,
                isLinked: false
            },
            {
                name: 'eto',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_TO,
                isLinked: false,
                compactViewRightDetail: true,
            },
            {
                name: 'status',
                type: c.OBJECT_FIELD_DATA_TYPE_TEXT,
                label: tc.OBJECT_FIELD_TRANSACTION_AUTOMATED_EMAIL_STATUS,
                isLinked: false,
                compactViewLeftDetail: true,
            },
            {
                name: 'created_date',
                type: c.OBJECT_FIELD_DATA_TYPE_DATETIME,
                label: tc.OBJECT_FIELD_GENERIC_CREATED_DATE,
                isLinked: false
            }
            
        ]
    } 
}
 
export default configs